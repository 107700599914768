<template>

    <v-layout class="rounded rounded-md" style="height: 100%;">
        <v-main class="align-center justify-center">

            <v-row class="fill-height">
                <v-col cols="12" md="5" class="">

                    <v-main style="height: 100%;">
                        <router-view />
                    </v-main>

                    <!-- <div class="d-flex pt-3">
                        <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
                        <v-spacer></v-spacer>
                    </div>

                    <div class="d-flex justify-center align-center mb-16 pb-6" style="height: 100%;">

                        <v-form ref="formlogin">
                            <v-card elevation="0" max-width="410" class="mx-auto">
                                <v-card-title class="">
                                    <h4 class="text-left text-h4 font-weight-bold"
                                        style="white-space: normal;word-break: auto-phrase;">
                                        Administrador encuestas de turismo</h4>
                                </v-card-title>
                                <v-card-subtitle class="text-wrap pb-9">
                                    Ingresa tu correo electrónico y contraseña para continuar.
                                </v-card-subtitle>
                                <v-card-text>

                                    <v-responsive class="mx-auto">
                                        <span class="font-weight-medium">
                                            Correo electrónico
                                        </span>
                                        <v-text-field rounded="lg" color="secondary" @keydown.enter="login" required
                                            name="email" autocomplete="new-password" variant="outlined"
                                            density="compact" type="input" v-model="email"></v-text-field>
                                    </v-responsive>
                                    <v-responsive class="mx-auto">
                                        <span class="font-weight-medium">
                                            Contraseña
                                        </span>
                                        <v-text-field rounded="lg" color="secondary" @keydown.enter="login" required
                                            autocomplete="new-password" v-model="password" variant="outlined"
                                            density="compact" type="password"></v-text-field>
                                    </v-responsive>
                                    <div class="d-flex">
                                      
                                        <v-spacer></v-spacer>
                                        <a class="text-decoration-none text-primary font-weight-medium d-flex align-self-center"
                                            href="/password/reset">¿Olvidó su
                                            contraseña?</a>
                                    </div>
                                    <v-responsive class="mx-auto mt-5 pt-5">

                                        <v-hover>
                                            <template v-slot:default="{ isHovering, props }">
                                                <v-btn v-bind="props" :disabled="loadingForm" rounded="lg"
                                                    :loading="loadingForm" @click="login" color="secondary" block
                                                    :variant="isHovering ? 'outlined' : 'flat'" class="text-capitalize">
                                                    <span v-if="isHovering"
                                                        :class="{ 'animate__animated  animate__slideInLeft': isHovering }">
                                                        <v-icon> mdi-arrow-right </v-icon> Ingresar Ahora </span>
                                                    <span class="animate__animated animate__slideInRight"
                                                        :class="{ 'd-none': isHovering }"> Ingresar Ahora </span>
                                                    <div class="area">
                                                        <ul class="circles">
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                            <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                        </ul>
                                                    </div>
                                                </v-btn>
                                            </template>
                                        </v-hover>

                                    </v-responsive>
                                   
                                    <v-responsive class="mx-auto py-5 mt-4 text-center">
                                        <span> ¿No tienes una cuenta? <a href="#"
                                                class="text-decoration-none text-primary font-weight-medium">Registrate</a>
                                        </span>
                                    </v-responsive>


                                </v-card-text>
                            </v-card>
                        </v-form>

                    </div> -->



                </v-col>

                <v-col cols="12" md="7" class="hidden-sm-and-down slider-login right-0 position-fixed">
                    <v-carousel hide-delimiters cycle :show-arrows="false" height="100%" class="app-carousel">
                        <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" cover></v-carousel-item>
                    </v-carousel>

                </v-col>

            </v-row>
        </v-main>
    </v-layout>


</template>

<script>

export default {
    name: 'LoginLayout',

    data: () => ({
        items: [
            {
                    src: require('@/assets/img/forest-animals-nature-branch-snake-green-14903-wallhere.com.jpg'),
                },
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                },
                
                {
                    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                }
            ],
    }),
}
</script>
