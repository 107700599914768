<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation2 v-if="isMdOrLower" color="primary" title="Percepción del Destino" subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es 
                muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de 
                identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos 
                unas preguntas adicionales sobre su llegada al departamento.">
        </NavBarAnimation2>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{
                                item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-primary pa-0"
                style="position: fixed; height: 100%;">

                <LeftPanelAnimation2 title="Percepción del" subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es 
                muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de 
                identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos 
                unas preguntas adicionales sobre su llegada al departamento." :wordsAnimate="['Destino']">
                </LeftPanelAnimation2>

            </v-col>
            <v-col lg="7" xl="8" cols="12" style=" background: #fff; z-index: 9;" class="ml-auto">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div>
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>



                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">

                                <v-window-item :value="1" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Tipo de documento de
                                                    identificación</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.tipo_identificacion">
                                                    <v-radio v-for="(item, index) in itemsTipoIdentificación"
                                                        :key="index" :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.tipo_identificacion == item }"></v-radio>

                                                </v-radio-group>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Número de documento de
                                                    identificación</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(50)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="2" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál fue su sexo al nacer?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.sexo_nacer">
                                                    <v-radio v-for="(item, index) in itemsSexoNacer" :key="index"
                                                        :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.sexo_nacer == item }"></v-radio>

                                                </v-radio-group>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuántos años cumplidos tiene?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(3)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>

                                            <v-col md="4" cols="12" class="py-1">
                                                <span class="font-weight-medium">País</span>
                                                <v-select v-model="form.pais" :rules="[validations.required]"
                                                    color="primary" variant="underlined" :items="['Ejempo 1',
                                                        'Ejemplo 2',
                                                        'Ejemplo 3',
                                                        'Ejemplo 4',
                                                        'Ejemplo 5']"></v-select>
                                            </v-col>
                                            <v-col md="4" cols="12" class="py-1">
                                                <span class="font-weight-medium">Departamento/Estado</span>
                                                <v-select v-model="form.departamento"
                                                    :rules="[validations.required]" color="primary" variant="underlined"
                                                    :items="['Ejempo 1',
                                                        'Ejemplo 2',
                                                        'Ejemplo 3',
                                                        'Ejemplo 4',
                                                        'Ejemplo 5']"></v-select>
                                            </v-col>
                                            <v-col md="4" cols="12" class="py-1">
                                                <span class="font-weight-medium">Ciudad</span>
                                                <v-select v-model="form.ciudad" :rules="[validations.required]"
                                                    color="primary" variant="underlined" :items="['Ejempo 1',
                                                        'Ejemplo 2',
                                                        'Ejemplo 3',
                                                        'Ejemplo 4',
                                                        'Ejemplo 5']"></v-select>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="3" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es el nivel educativo más alto
                                                    alcanzado?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-select v-model="form.pais" :rules="[validations.required]"
                                                    color="primary" variant="underlined"
                                                    :items="itemsNivelEducatico"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Correo electrónico de contacto</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255), validations.emailFormat]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Teléfono de contacto</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="4" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Tipo de Alojamiento Utilizado?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.tipo_alojamiento_utilizado"
                                                    :items="itemsTipoAlojamientoUtilizado"  variant="underlined">
                                                    

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-radio color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.tipo_alojamiento_utilizado"></v-radio>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsTipoAlojamientoUtilizado.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.tipo_alojamiento_utilizado">
                                                    <v-radio v-for="(item, index) in itemsTipoAlojamientoUtilizado"
                                                        :key="index" :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.tipo_alojamiento_utilizado == item }"></v-radio>

                                                </v-radio-group> -->
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="5" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Indique el tipo de viaje de acuerdo con
                                                    el grupo
                                                    de personas que lo acompañan?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.tipo_viaje" :items="ItemTipoViaje"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.tipo_viaje.length - 2 }} {{
                                                                form.tipo_viaje.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.tipo_viaje"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < ItemTipoViaje.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleTipoViaje(item)"
                                                            v-for="(item, index) in ItemTipoViaje" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': form.tipo_viaje.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="form.tipo_viaje"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿El grupo de viaje incluye niños?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.incluye_ninos">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.incluye_ninos == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.incluye_ninos == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="6" >
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿De qué forma se enteró usted del
                                                    departamento
                                                    del Guaviare como destino turístico?</span> <span
                                                    class="font-weight-bold">*</span>

                                                    <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.forma_entero" :items="itemsFormaEntero"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.forma_entero.length - 2 }} {{
                                                                form.forma_entero.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.forma_entero"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsFormaEntero.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <v-list lines="one" select-strategy="classic"
                                                    class="overflow-visible list-container-documents">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleFormaEntero(item)"
                                                        v-for="(item, index) in itemsFormaEntero" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': form.forma_entero.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :model-value="isActive"
                                                                    v-model="form.forma_entero"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list> -->
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="7" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál fue el motivo principal para viajar
                                                    al
                                                    departamento del Guaviare?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.motivo_viaje"
                                                    :items="itemsMotivoViaje"  variant="underlined">
                                                    

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-radio color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.motivo_viaje"></v-radio>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsMotivoViaje.length"></v-divider>
                                                    </template>

                                                </v-select>
                                                <!-- <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.motivo_viaje">
                                                    <v-radio hi v-for="(item, index) in itemsMotivoViaje" :key="index"
                                                        :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.motivo_viaje == item }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item }}
                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group> -->

                                                <v-text-field v-if="form.motivo_viaje == 'Otro motivo'" color="primary"
                                                    variant="underlined"
                                                    placeholder="¿Cuál es el motivo?"></v-text-field>

                                            </v-col>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Por qué medio de transporte
                                                    llegó/llegaron al
                                                    departamento?</span> <span class="font-weight-bold">*</span>

                                                    <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.medio_transporte" :items="itemsMedioTransporte"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.medio_transporte.length - 2 }} {{
                                                                form.medio_transporte.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.medio_transporte"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsMedioTransporte.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <v-list lines="one" select-strategy="classic"
                                                    class="overflow-visible list-container-documents">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleMedioTransporte(item)"
                                                        v-for="(item, index) in itemsMedioTransporte" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': form.medio_transporte.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :model-value="isActive"
                                                                    v-model="form.medio_transporte"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list> -->
                                                <v-list-item class="py-0 px-0"
                                                    v-if="form.medio_transporte.includes('Otro medio')">
                                                    <v-text-field color="primary" variant="underlined"
                                                        placeholder="¿Cuál?"></v-text-field>
                                                </v-list-item>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="8" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuáles de los municipios visitó desde el
                                                    momento
                                                    que llegó al Guaviare?</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-list lines="one" select-strategy="classic" class="overflow-visible">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleMunicipiosVisitar(item)"
                                                        v-for="(item, index) in itemsMunicipiosVisitar" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': form.municipios_visitar.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :model-value="isActive"
                                                                    v-model="form.municipios_visitar"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item>



                                                </v-list>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="9" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Qué actividades realizó en el
                                                    Guaviare?</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.actividades_realizo" :items="itemsActividadesRealizo"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.actividades_realizo.length - 2 }} {{
                                                                form.actividades_realizo.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.actividades_realizo"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsActividadesRealizo.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <v-list lines="one" select-strategy="classic"
                                                    class="overflow-visible list-container-documents">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleActividadesRealizo(item)"
                                                        v-for="(item, index) in itemsActividadesRealizo" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': form.actividades_realizo.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :model-value="isActive"
                                                                    v-model="form.actividades_realizo"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title class="text-wrap">{{ item
                                                            }}</v-list-item-title>
                                                    </v-list-item>


                                                </v-list> -->
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="10" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Cuál es su nivel de percepción de los
                                                    establecimientos de alimentación, teniendo en cuenta que 5 <v-icon
                                                        color="orange-lighten-1">mdi-star</v-icon> es "Muy
                                                    satisfecho" y 1 "Muy insatisfecho"?</span>
                                            </v-col>

                                            <v-row>
                                                <template v-for="(item, index) in ratingsPerception" :key="index">
                                                <v-col md="6" sm="12" cols="12" class="py-1 d-flex align-center ">
                                                    <span class="">{{ item.label }}</span>
                                                </v-col>
                                                <v-col md="6" sm="12" cols="12" class="py-1 d-flex justify-center align-center">
                                                    <v-rating v-model="item.value" active-color="orange-lighten-1"
                                                        color="orange-lighten-1"></v-rating>
                                                </v-col>
                                            </template>
                                            </v-row>


                                            

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="11" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">



                                                <span class="font-weight-bold">¿Cuál fue el valor de los gastos del
                                                    viaje por
                                                    persona y por día?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>

                                                <ul class="my-3 text-caption">
                                                    <li><span class="text-secondary font-weight-medium">Ayuda 1:</span>
                                                        Para
                                                        calcular el gasto del viaje no debes incluir el
                                                        valor de
                                                        los tiquetes aéreos/buses de terminal ni los valores pagados por
                                                        el
                                                        hospedaje, incluye los valores adicionales que hayas pagado en
                                                        el
                                                        destino por recreación, alimentación, souvenirs, entre otros.
                                                    </li>
                                                    <li><span class="text-secondary font-weight-medium">Ayuda 2:</span>
                                                        Si
                                                        viajas solo, divide el total de tus gastos en el
                                                        total de
                                                        días que te quedaste en el departamento.</li>
                                                    <li><span class="text-secondary font-weight-medium">Ayuda 3:</span>
                                                        Si
                                                        viajas con acompañantes y tu fuiste quién pagó todo
                                                        el viaje
                                                        (para las personas que viajan contigo, por ejemplo, familias),
                                                        debes
                                                        dividir este valor en el número de días y en el número de
                                                        personas.
                                                    </li>
                                                </ul>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">



                                                <span class="font-weight-bold">Del 100% de lo que gastó en este viaje,
                                                    indique
                                                    qué porcentaje gastó en cada uno de los siguientes ítems</span>
                                                <span class="font-weight-bold">*</span>

                                                <div :class="{ 'two-columns-div': !isMdOrLower }">
                                                    <v-row v-for="(item, index) in itemsPorcentajeGastos" :key="index"
                                                        class="d-flex align-center py-0 ma-0">
                                                        <v-col md="6" cols="12" class="py-1">
                                                            <span class="">{{ item.label }}</span>
                                                        </v-col>
                                                        <v-col md="6" cols="12" class="py-1">
                                                            <v-text-field
                                                                :rules="[validations.required, validations.maxLength(3)]"
                                                                color="primary" variant="underlined" v-numbers-only-app
                                                                placeholder="">
                                                                <template v-slot:append-inner>
                                                                    <span class="font-weight-bold">%</span>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </div>

                                                <ul class="mt-3 text-caption">
                                                    <li><span class="text-secondary font-weight-medium">Ayuda 1:</span>
                                                        Si compró paquetes turísticos todo incluido y no incurrio en
                                                        gastos
                                                        adicionales por alimentación, entradas a atractivos o
                                                        alimentación,
                                                        digite 0 en estos tres últimos conceptos e introduzca todo
                                                        dentro de
                                                        paquete turístico.
                                                    </li>

                                                </ul>

                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>



                                <v-window-item :value="12" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    Cuál es su nivel de percepción del Departamento en general, de
                                                    acuerdo a los
                                                    siguientes criterios, teniendo en cuenta que 5 <v-icon
                                                        color="orange-lighten-1">mdi-star</v-icon> es "Muy satisfecho" y
                                                    1 "Muy
                                                    insatisfecho"?</span>
                                            </v-col>

                                            <v-row>
                                            <template v-for="(item, index) in ratingsPerceptionDepartamento" :key="index">
                                                <v-col sm="12" md="6" cols="12" class="py-1 d-flex align-center">
                                                    <span class="">{{ item.label }}</span>
                                                </v-col>
                                                <v-col sm="12" md="6" cols="12" class="py-1 d-flex align-center justify-center">
                                                    <v-rating v-model="item.value" active-color="orange-lighten-1"
                                                        color="orange-lighten-1"></v-rating>
                                                </v-col>
                                            </template>
                                            </v-row>

                                            

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="13" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Usted fue informado por parte de alguna
                                                    persona
                                                    o institución, acerca de la oferta de naturaleza (fauna, flora,
                                                    actividades
                                                    de aventura), agroturismo o cultura del Departamento?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.informado_oferta">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.informado_oferta == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.informado_oferta == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Qué instituciones o personas le
                                                    brindaron
                                                    información?</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.quien_informo" :items="itemsQuienInformo"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.quien_informo.length - 2 }} {{
                                                                form.quien_informo.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.quien_informo"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsQuienInformo.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleQuienInformo(item)"
                                                            v-for="(item, index) in itemsQuienInformo" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': form.quien_informo.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="form.quien_informo"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="14" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    ¿Cuál es su nivel de percepción respecto al acceso a los productos
                                                    turísticos del destino, teniendo en cuenta que 5 <v-icon
                                                        color="orange-lighten-1">mdi-star</v-icon> es "Muy difícil" y 1
                                                    "Muy
                                                    fácil"?</span>
                                                <v-row v-for="(item, index) in ratingsPerceptionAccesoProd" :key="index"
                                                    class="d-flex align-center py-0 ma-0">
                                                    <v-col md="6" cols="12" class="py-1">
                                                        <span class="">{{ item.label }}</span>
                                                    </v-col>
                                                    <v-col md="6" cols="12" class="py-1">
                                                        <v-rating v-model="item.value" active-color="orange-lighten-1"
                                                            color="orange-lighten-1"></v-rating>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Recomendaría el destino a amigos,
                                                    familiares,
                                                    seguidores en redes sociales u otros conocidos?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.recomendacion_destino">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.recomendacion_destino == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.recomendacion_destino == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="15" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                        

                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Deseas seguir recibiendo información acerca del destino Guaviare y su oferta turística?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.recibir_informacion">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.recibir_informacion == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.recibir_informacion == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>

                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Fecha de la llegada al departamento</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-menu :close-on-content-click="false" v-model="menuDateCheckin">
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field :rules="[validations.required]" rounded="lg"
                                                            v-model="form.date_checkin_format"
                                                            append-inner-icon="mdi-calendar-month" readonly
                                                            v-bind="props" variant="underlined"></v-text-field>
                                                    </template>
                                                    <VueDatePicker class="" v-model="form.date_checkin" locale="es"
                                                        :dark="false" :enable-time-picker="false" inline auto-apply />
                                                </v-menu>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Fecha de salida del departamento</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-menu :close-on-content-click="false" v-model="menuDateCheckout">
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field :rules="[validations.required]" rounded="lg"
                                                            v-model="form.date_checkout_format"
                                                            append-inner-icon="mdi-calendar-month" readonly
                                                            v-bind="props" variant="underlined"></v-text-field>
                                                    </template>
                                                    <VueDatePicker class="" v-model="form.date_checkout" locale="es"
                                                        :dark="false" :enable-time-picker="false" inline auto-apply />
                                                </v-menu>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>




                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>
        <UserExperience></UserExperience>
    </v-container>

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation2 from './includes/LeftPanelAnimation2.vue';
import NavBarAnimation2 from './includes/NavBarAnimation2.vue';
import UserExperience from './includes/UserExperience.vue';
import moment from 'moment';


export default {
    name: 'FormDestinyPerception',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation2,
        NavBarAnimation2,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()

        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            showTopShadow: false,
            showBottomShadow: true,
            menuDateCheckin: false,
            menuDateCheckout: false,
            form: {
                tipo_identificacion: null,
                numero_identificacion: null,
                sexo_nacer: null,
                edad: null,
                pais: null,
                departamento: null,
                ciudad: null,
                nivel_educativo: null,
                correo_electronico: null,
                telefono_contacto: null,
                tipo_alojamiento_utilizado: null,
                tipo_viaje: [],
                incluye_ninos: null,
                forma_entero: [],
                motivo_viaje: null,
                medio_transporte: [],
                municipios_visitar: [],
                actividades_realizo: [],
                valor_gastos: null,
                informado_oferta: null,
                quien_informo: [],
                recomendacion_destino: null,
                recibir_informacion: null,
                date_checkin_format: null,
                date_checkin: null,
                date_checkout_format: null,
                date_checkout: null,

            },

            itemsTipoIdentificación: [
                'Cédula de Ciudadanía',
                'Cédula de Extranjería',
                'Pasaporte',
                'Permiso de permanencia',
                'Otro'
            ],
            itemsSexoNacer: [
                'Hombre',
                'Mujer',
                'Intersexual'
            ],

            itemsNivelEducatico: [
                'Ninguno',
                'Primaria',
                'Bachiller',
                'Técnico/Tecnológo',
                'Profesional',
                'Profesional con postgrado',
            ],

            itemsTipoAlojamientoUtilizado: [
                'No dormí en el departamento',
                'Casa familiares - amigos',
                'Hotel',
                'Apartahotel',
                'Centro Vacacional',
                'Hostal',
                'Campamento',
                'Albergue',
                'Refugio',
                'Glamping',
                'Apartamento Turístico',
                'Casa Turística',
                'Finca Turistica (Alojamiento Rural)',
                'Otros Tipos De Hospedaje Turísticos No Permanentes',
                'Otro Tipo De Vivienda Turística',
                'Posada Turística',
                'Otro'
            ],

            ItemTipoViaje: [
                'Viajo Solo',
                'Viajo unicamente con mi pareja',
                'Viajo con Amigos',
                'Viajo con compañeros de trabajo',
                'Viajo con mi familia de primer grado (Padres, esposa y/o hijos)',
                'Viajo con otros familiares (familia de segundo grado como primos, tíos, etc)',
                'Viajo con compañeros de estudio',
                'Viajo con compañeros de equipo deportivo'
            ],

            itemsFormaEntero: [
                'Ya conocia el departamento',
                'Por amigos y/o familiares',
                'Medios de comunicación masivo, prensa',
                'Guías turísticas/medios especializados en turismo',
                'Búsquedas/paginas/avisos en internet',
                'Redes sociales/Influencers',
                'Por agencias de viajes',
                'Otra forma',
            ],

            itemsMotivoViaje: [
                'Compras - Negocios',
                'Educación y formación',
                'Evento deportivo',
                'Religión - peregrinación',
                'Paso hacia otro departamento o país',
                'Salud',
                'Trabajo',
                'Vacaciones - recreación - ocio',
                'Otro motivo'
            ],

            itemsMedioTransporte: [
                'Avión',
                'Barco',
                'Bus de la terminal de transporte',
                'Bus alquilado o de turismo',
                'Carro propio',
                'Carro de alquiler',
                'Moto',
                'Otro medio',
            ],


            itemsMunicipiosVisitar: [
                'San José del Guaviare',
                'El Retorno',
                'Miraflores',
                'Calamar',
            ],

            itemsActividadesRealizo: [
                'Visita a establecimientos comerciales - calles y parques del casco urbano',
                'Visita a museos/casas de la cultura/monumentos /haciendas',
                'Recorridos Agroturísticos',
                'Actividades religiosas - visitas a iglesias',
                'Asistencia a espectáculos artísticos y festivales, fiestas y ferias',
                'Actividades de esparcimiento (fogatas, espacios musicales y de literatura, etc)',
                'actividades de bienestar (yoga, meditación, sanación)',
                'Avistamiento de aves',
                'Observación Especializada de Fauna (especies diferentes a las aves)',
                'Observación de flora',
                'Visita/ Senderismo a ríos, lagunas y cascadas, reservas y / o parques naturales.',
                'Actividades de aventura y deportes extremos',
                'Actividades de baño recreativo',
                'Recorridos en bicicleta',
                'Visita a discotecas bares y karaokes, casinos y juegos de azar',
                'Compra de bienes de consumo duradero',
                'Actividades laborales y/o inversión - conferencias / congresos',
                'Turismo Etnográfico o Actividades con comunidades étnicas',
                'Visita a zonas arqueológicas',
            ],


            ratingsPerception: [
                { label: 'Variedad del Menú', value: 0 },
                { label: 'Menú consumido', value: 0 },
                { label: 'Tiempo de atención', value: 0 },
                { label: 'Aseo e higiene de las instalaciones gastronómicas', value: 0 },
                { label: 'Relación calidad precio', value: 0 },
                { label: 'Trato del personal del restaurante', value: 0 },
            ],

            ratingsPerceptionDepartamento: [
                { label: 'Estado de la carretera nacional (vía para llegar al departamento)', value: 0 },
                { label: 'Estado de las vias internas de los municipios', value: 0 },
                { label: 'Seguridad del destino', value: 0 },
                { label: 'Satisfacción de la visita en general al Departamento', value: 0 },
            ],

            ratingsPerceptionAccesoProd: [
                { label: 'Acceso a productos turísticos', value: 0 },
            ],

            itemsPorcentajeGastos: [
                { label: 'Atractivos Turísticos', value: null },
                { label: 'Alimentación (A&B)', value: null },
                { label: 'Transporte interno dentro del departamento', value: null },
                { label: 'Bienes de uso personal y salud', value: null },
                { label: 'Servicio cultural y recreacional', value: null },
                { label: 'Compras (Souvenirs, artesanías)', value: null },
                { label: 'Paquete turístico', value: null },
                { label: 'Otros', value: null },
            ],

            itemsQuienInformo: [
                'Policía de Turismo',
                'Punto de Información Turística (PIT) del parque principal de San José del Guaviare',
                'Punto de información en el aeropuerto',
                'Taxistas',
                'Transeúntes',
            ],

            isMobile: false,

            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),


            stepFormServiceOrder: 1,
            stepTitles: [
                'Identificación',
                'Datos Personales y Residencia',
                'Educación y Contacto',
                'Alojamiento Utilizado',
                'Detalles del Grupo de Viaje',
                'Información sobre el Descubrimiento del Destino',
                'Motivo y Medio de Transporte del Viaje',
                'Municipios Visitados',
                'Actividades en el Guaviare',
                'Percepción de Establecimientos de Alimentación',
                'Valor de Gastos del Viaje',
                'Percepción General del Departamento',
                'Información Recibida sobre la Oferta del Departamento',
                'Percepción del Acceso y Recomendación del Destino',
                'Información y Fechas de Viaje'
            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }
        },
        'form.date_checkin'() {
            if (this.form.date_checkin) {
                const fecha = moment(this.form.date_checkin);
                this.form.date_checkin_format = fecha.format('YYYY-MM-DD');
            } else {
                this.form.date_checkin_format = null;
            }
            this.menuDateCheckin = false;
        },
        'form.date_checkout'() {
            if (this.form.date_checkout) {
                const fecha = moment(this.form.date_checkout);
                this.form.date_checkout_format = fecha.format('YYYY-MM-DD');
            } else {
                this.form.date_checkout_format = null;
            }
            this.menuDateCheckout = false;
        },
    },
    methods: {
        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },

        // toggleActividadesCamara(activity) {
        //     const index = this.selectActividadesCamara.indexOf(activity);
        //     if (index === -1) {
        //         this.selectActividadesCamara.push(activity);
        //     } else {
        //         this.selectActividadesCamara.splice(index, 1);
        //     }
        // },

        toggleTipoViaje(activity) {
            const index = this.form.tipo_viaje.indexOf(activity);
            if (index === -1) {
                this.form.tipo_viaje.push(activity);
            } else {
                this.form.tipo_viaje.splice(index, 1);
            }
        },

        toggleFormaEntero(activity) {
            const index = this.form.forma_entero.indexOf(activity);
            if (index === -1) {
                this.form.forma_entero.push(activity);
            } else {
                this.form.forma_entero.splice(index, 1);
            }
        },

        toggleMedioTransporte(transporte) {

            const index = this.form.medio_transporte.indexOf(transporte);
            if (index === -1) {
                this.form.medio_transporte.push(transporte);
            } else {
                this.form.medio_transporte.splice(index, 1);
            }

        },

        toggleMunicipiosVisitar(municipio) {

            const index = this.form.municipios_visitar.indexOf(municipio);
            if (index === -1) {
                this.form.municipios_visitar.push(municipio);
            } else {
                this.form.municipios_visitar.splice(index, 1);
            }

        },

        toggleActividadesRealizo(actividad) {

            const index = this.form.actividades_realizo.indexOf(actividad);
            if (index === -1) {
                this.form.actividades_realizo.push(actividad);
            } else {
                this.form.actividades_realizo.splice(index, 1);
            }

        },

        toggleQuienInformo(informo) {

            const index = this.form.quien_informo.indexOf(informo);
            if (index === -1) {
                this.form.quien_informo.push(informo);
            } else {
                this.form.quien_informo.splice(index, 1);
            }

        },



        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        }


    },

    mounted() {
        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 8px;
}


/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
    margin: 0;
}

.two-columns-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    display: grid;
}
</style>
