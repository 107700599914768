import { createRouter, createWebHistory } from 'vue-router';

import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Register from '../views/Register.vue';
import RegisterEntity from '../views/RegisterEntity.vue';

import FormEmployability from '../views/forms/FormEmployability.vue';
import FormOfferPst from '../views/forms/FormOfferPst.vue';
import FormEntrepreneurRegistration from '../views/forms/FormEntrepreneurRegistration.vue';
import FormTouristRegistration from '../views/forms/FormTouristRegistration.vue';
import FormVisitorEntry from '../views/forms/FormVisitorEntry.vue';
import FormDestinyPerception from '../views/forms/FormDestinyPerception.vue';
import FormOfferGuides from '../views/forms/FormOfferGuides.vue';


import DashboardAdmin from '../views/admin/Dashboard.vue';
import UsersAdmin from '../views/admin/Users.vue';
import SurveysAdmin from '../views/admin/Surveys.vue';
import PermissionsAdmin from '../views/admin/Permissions.vue';
import ProfileAdmin from '../views/admin/Profile.vue';
import FormPerceptionTouristAttractive from '../views/forms/FormPerceptionTouristAttractive.vue';
import FormPerceptionTouristLodging from '../views/forms/FormPerceptionTouristLodging.vue';


// import axios from 'axios';
// import store from '../store' // Asegúrate de que la ruta sea correcta

const routes = [
  { path: '/', redirect: '/login' }, // Redirige a la vista de login por defecto
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { role: 'login' }
  },
  {
    path: '/registro',
    name: 'Register',
    component: Register,
    meta: { role: 'login' }
  },
  {
    path: '/recuperar-cotrasena',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { role: 'login' }
  },
  {
    path: '/registro-entidad',
    name: 'RegisterEntity',
    component: RegisterEntity,
    meta: { role: 'login' }
  },
  {
    path: '/formularios/empleabilidad',
    name: 'FormEmployability',
    component: FormEmployability,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/formularios/ofertas-pst',
    name: 'FormOfferPst',
    component: FormOfferPst,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/formularios/registro-empresarios',
    name: 'FormEntrepreneurRegistration',
    component: FormEntrepreneurRegistration,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/formularios/registro-turistas',
    name: 'FormTouristRegistration',
    component: FormTouristRegistration,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/formularios/ingreso-visitantes',
    name: 'FormVisitorEntry',
    component: FormVisitorEntry,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/formularios/percepcion-destino',
    name: 'FormDestinyPerception',
    component: FormDestinyPerception,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/formularios/ofertas-guias',
    name: 'FormOfferGuides',
    component: FormOfferGuides,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  {
    path: '/administrador',
    name: 'DashboardAdmin',
    component: DashboardAdmin,
    meta: { role: 'superadmin', title : 'Dashboard',  role_id: [1], requiresAuth: true, subTitle: 'Visión general de las encuestas y resultados en tiempo real.',}
  },
  {
    path: '/administrador/usuarios',
    name: 'UsersAdmin',
    component: UsersAdmin,
    meta: { role: 'superadmin', title : 'Usuarios',  role_id: [1], requiresAuth: true, subTitle: 'Gestión de usuarios y control de accesos en el sistema de encuestas.',}
  },
  {
    path: '/administrador/encuentas',
    name: 'SurveysAdmin',
    component: SurveysAdmin,
    meta: { role: 'superadmin', title : 'Encuestas',  role_id: [1], requiresAuth: true, subTitle: 'Visualiza resultados y gestiona el estado de tus encuestas.',}
  },
  {
    path: '/administrador/roles-permisos',
    name: 'PermissionsAdmin',
    component: PermissionsAdmin,
    meta: { role: 'superadmin', title : 'Roles y Permisos',  role_id: [1], requiresAuth: true, subTitle: 'Configura los roles y permisos para garantizar la seguridad y el acceso adecuado.',}
  },
  {
    path: '/administrador/perfil',
    name: 'ProfileAdmin',
    component: ProfileAdmin,
    meta: { role: 'superadmin', title : 'Mi perfil',  role_id: [1], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.',}
  },



  {
    path: '/formularios/percepcion-turista-atractivo',
    name: 'FormPerceptionTouristAttractive',
    component: FormPerceptionTouristAttractive,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },

  {
    path: '/formularios/percepcion-turista-alojamiento',
    name: 'FormPerceptionTouristLodging',
    component: FormPerceptionTouristLodging,
    meta: { role: 'admin', role_id: [1], requiresAuth: true }
  },
  


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   const isAuthenticated = localStorage.getItem('token');

//   if (to.meta.requiresAuth && !isAuthenticated) {
//     next('/login');
//   } else if (to.meta.requiresAuth) {
//     try {
//       let url = process.env.VUE_APP_API_URL;

//       const response = await axios.get(url + '/bearer', {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`
//         }
//       });

//       const userInfo = response.data;

//       if (to.meta.role_id.includes(userInfo.user.role_id)) {
//         store.dispatch('setUser', userInfo.user)
    

//         if (userInfo && userInfo.user && userInfo.user.role_id == 2 && userInfo.user.role_id) {
//           store.dispatch('setPropertyId', userInfo.user.property_id)
//         }

//         store.dispatch('setToken', localStorage.getItem('token'))
//         next();
//       } else {
//         next({ name: 'PermisoDenegado' })
//       }
//     } catch (error) {
//       console.error('Error fetching user roles:', error);
//       next('/login');
//     }
//   } else {
//     next();
//   }
// });

export default router;