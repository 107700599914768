<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation2 v-if="isMdOrLower" title="Formulario de Ofertas PST" subTitle="¡Hola! Apreciado empresario, en esta sección podrá registrar los datos de
oferta de su empresa. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos,
los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Esta información es estrictamente
confidencial y se utilizará únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara y Comercio,
Alcaldías, etc.">
        </NavBarAnimation2>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{
                                item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-secondary pa-0"
                style="position: sticky; ">

                <LeftPanelAnimation2 title="Formulario de" subTitle="¡Hola! Apreciado empresario, en esta sección podrá registrar los datos de
oferta de su empresa. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos,
los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Esta información es estrictamente
confidencial y se utilizará únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara y Comercio,
Alcaldías, etc." :wordsAnimate="['Ofertas PST']"></LeftPanelAnimation2>

            </v-col>
            <v-col lg="7" xl="8" cols="12" style=" background: #fff; z-index: 9;">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div>
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>



                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">

                                <v-window-item :value="1" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es el nombre del representante
                                                    legal de la
                                                    empresa?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">NIT</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(50)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Nombre del Establecimiento</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="2" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Dirección</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Email</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.emailFormat]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="3" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Registro Nacional de Turismo (RNT)</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Categoría de RNT</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.categoriasRnt"
                                                    :items="itemsCategoriasRnt"  variant="underlined">
                                                    

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-radio color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.categoriasRnt"></v-radio>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsCategoriasRnt.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.categoriasRnt">
                                                    <v-radio v-for="(item, index) in itemsCategoriasRnt" :key="index"
                                                        :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.categoriasRnt == item }"></v-radio>

                                                </v-radio-group> -->

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="4" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Señale las actividades económicas
                                                    registradas en
                                                    Cámara de Comercio de este establecimiento
                                                </span> <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectActividadesCamara" :items="itemsActividadesCamara"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectActividadesCamara.length - 2 }} {{
                                                                selectActividadesCamara.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectActividadesCamara"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsActividadesCamara.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="5" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    ¿En qué tipología de turismo se clasifica su empresa? 
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <div class="mt-4">
                                                    <template v-for="(item, index) in itemsTipoTurismo" :key="index">

                                                        <!-- divider -->
                                                        <v-divider v-if="index > 0" class="my-0"></v-divider>

                                                        <div v-if="item.isGroup">

                                                            <v-list-item @click="item.show = !item.show">
                                                                <template v-slot:prepend>
                                                                    <v-icon v-if="item.show">mdi-chevron-down</v-icon>
                                                                    <v-icon v-else>mdi-chevron-right</v-icon>
                                                                </template>

                                                                <v-list-item-title class="text-wrap">{{ item.title
                                                                    }}</v-list-item-title>

                                                            </v-list-item>

                                                            <template v-if="item.show">
                                                                <div
                                                                    class="list-container-documents px-4 animate__animated animate__fadeInDown">
                                                                    <div v-for="(itemSub, indexSub) in item.items"
                                                                        :key="indexSub">
                                                                        <v-checkbox color="primary" hide-details
                                                                            :label="itemSub" :value="itemSub">
                                                                        </v-checkbox>
                                                                    </div>
                                                                </div>

                                                            </template>

                                                        </div>

                                                        <v-list-item v-if="!item.isGroup">
                                                            <v-checkbox color="primary" hide-details :label="item.title"
                                                                :value="item.title">

                                                            </v-checkbox>
                                                        </v-list-item>

                                                    </template>
                                                </div>

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="6" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">La empresa realiza o promueve actividades
                                                    de
                                                    turismo de base comunitaria? (comunidad como gestor y/o
                                                    protagonista)</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.promueveActividades">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.promueveActividades == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.promueveActividades == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="7" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">Indique cuáles de las siguientes
                                                    actividades
                                                    ofrece su empresa</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectActividadesOfrecidas" :items="itemsActividadesOfrecidas"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectActividadesOfrecidas.length - 2 }} {{
                                                                selectActividadesOfrecidas.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectActividadesOfrecidas"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsActividadesOfrecidas.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleActividadesOfrecidas(item)"
                                                            v-for="(item, index) in itemsActividadesOfrecidas"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectActividadesOfrecidas.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectActividadesOfrecidas"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->


                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="8" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Durante la actual temporada cuál es el
                                                    porcentaje de ocupación?</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.porcentajeOcupacion">
                                                    <v-radio v-for="(item, index) in itemsPorcentajeOcupacion"
                                                        :key="index" :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.porcentajeOcupacion == item }"></v-radio>

                                                </v-radio-group>



                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Cuál es el valor promedio de los
                                                    servicios de
                                                    alojamiento que ofrece?</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.valorPromedioServicios">
                                                    <v-radio v-for="(item, index) in itemsValorPromedioServicios"
                                                        :key="index" :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.valorPromedioServicios == item }"></v-radio>

                                                </v-radio-group>



                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="9" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Cuál es la clasificación de su
                                                    alojamiento?</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.clasificacionAlojamiento">
                                                    <v-radio v-for="(item, index) in itemsClasificacionAlojamiento"
                                                        :key="index" :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.clasificacionAlojamiento == item }"></v-radio>

                                                </v-radio-group>



                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="10" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de habitaciones
                                                    disponibles</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de camas
                                                    disponibles</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad máxima de huéspedes
                                                    que puede
                                                    alojar</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="11" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Su establecimiento brinda algún servicio
                                                    adicional?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectServicioAdicional" :items="itemsServicioAdicional"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectServicioAdicional.length - 2 }} {{
                                                                selectServicioAdicional.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectServicioAdicional"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsServicioAdicional.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleServicioAdicional(item)"
                                                            v-for="(item, index) in itemsServicioAdicional" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectServicioAdicional.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectServicioAdicional"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->


                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="12" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Cuál es el precio promedio de los
                                                    platos?</span>
                                                <span class="font-weight-bold">*</span>


                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.preciosPromediosPlatos">
                                                    <v-radio v-for="(item, index) in itemsPreciosPromediosPlatos"
                                                        :key="index" :label="item" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.preciosPromediosPlatos == item }"></v-radio>

                                                </v-radio-group>



                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="13" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de mesas disponibles
                                                    del
                                                    restaurante
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de sillas disponibles
                                                    del
                                                    restaurante
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de mesas disponibles
                                                    del bar
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de sillas disponibles
                                                    del bar
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="14" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique el aforo máximo de clientes en el
                                                    establecimiento
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la aforo máximo de clientes en el
                                                    espacio
                                                    cultural
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col sm="12" md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la cantidad de vehículos
                                                    disponibles
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="15" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col sm="12" md="12" cols="12" class="py-1 d-md-flex align-center">
                                                <span class="font-weight-bold">Indique cuántos turistas atendió en esta
                                                    temporada *
                                                </span>
                                                <v-text-field min-width="200px"
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col sm="12" md="12" cols="12" class="py-1 d-md-flex align-center">
                                                <span class="font-weight-bold">¿Qué porcentaje de los turistas atendidos
                                                    en la
                                                    temporada eran extranjeros? *
                                                </span>
                                                <v-text-field min-width="200px"
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="16" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col sm="12" md="12" cols="12" class="py-1 align-center">
                                                <span class="font-weight-bold">Indique el porcentaje de ventas según la
                                                    forma de
                                                    comercialización *
                                                </span>
                                                <v-row>
                                                    <v-col sm="12" md="12" cols="12"
                                                        class="py-1 d-md-flex align-center">
                                                        <span>% Venta directa al Cliente (B2C)
                                                        </span>
                                                        <v-text-field min-width="200" max-width="200" class="ml-auto"
                                                            :rules="[validations.required, validations.maxLength(255)]"
                                                            v-numbers-only-app color="primary" variant="underlined"
                                                            placeholder=""></v-text-field>
                                                    </v-col>
                                                    <v-col sm="12" md="12" cols="12"
                                                        class="py-1 d-md-flex align-center">
                                                        <span class="">% Venta a través de empresas aliadas (B2B)
                                                        </span>
                                                        <v-text-field min-width="200" max-width="200" class="ml-auto"
                                                            :rules="[validations.required, validations.maxLength(255)]"
                                                            v-numbers-only-app color="primary" variant="underlined"
                                                            placeholder=""></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="17" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Pertenece a alguno de los siguientes
                                                    gremios/asociaciones/colectivos?</span>
                                                <span class="font-weight-bold">*</span>

                                                <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleGremiosAsociaciones(item)"
                                                            v-for="(item, index) in itemsGremios" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectGremiosAsociaciones.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectGremiosAsociaciones"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div>


                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Conocen en la empresa de la estrategia contra la ESCNNA?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.estrategiaContraEscnna">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.estrategiaContraEscnna == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.estrategiaContraEscnna == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>





                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->

                </v-row>




            </v-col>
        </v-row>

        <UserExperience></UserExperience>

    </v-container>
    <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation2 from './includes/LeftPanelAnimation2.vue';
import NavBarAnimation2 from './includes/NavBarAnimation2.vue';
import UserExperience from './includes/UserExperience.vue';


export default {
    name: 'FormOfferPst',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation2,
        NavBarAnimation2,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()

        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            showTopShadow: false,
            showBottomShadow: true,

            form: {
                categoriasRnt: null,
                promueveActividades: null,
                porcentajeOcupacion: null,
                valorPromedioServicios: null,
                clasificacionAlojamiento: null,
                preciosPromediosPlatos: null,
                estrategiaContraEscnna: null
            },

            itemsCategoriasRnt: [
                'Agencias de viajes',
                'Arrendadores de vehículos para turismo nacional e internacional',
                'Bares',
                'Compañía de intercambio vacacional',
                'Concesionarios de servicios turísticos en el parque',
                'Empresa de tiempo compartido y multipropiedad',
                'Empresa de transporte terrestre automotor',
                'Empresas captadoras de ahorro para viajes',
                'Establecimientos de alojamiento turístico',
                'Establecimientos de gastronomía y similares',
                'Oficinas de representación turística',
                'Operadores de plataforma electrónicas o digitales de servicios turísticos',
                'Operadores profesionales de congresos, ferias y convenciones',
                'Otros tipos de hospedaje turísticos no permanentes ',
                'Parques temáticos',
                'Viviendas turísticas'
            ],

            itemsActividadesCamara: [
                'Bar (CIIU 5630)',
                'Restaurante y Gastrobares (CIIU 5611, 5612, 5613, 5619, 5621, 5629)',
                'Alojamiento (CIIU 5511, 5512, 5513, 5514, 5519, 5520)',
                'Agencias de viajes (CIIU 7911, 7912, 7990)',
                'Transporte (CIIU 4911, 4921, 4922, 7710, 5111, 5112, 5011, 5021)',
                'Actividades culturales y eventos (CIIU  8230, 9006, 9007, 9008, 9102, 9103, 9311, 9319, 9321, 9329)',
            ],
            itemsActividadesOfrecidas: [
                'Visita a establecimientos comerciales - calles y parques del casco urbano',
                'Visita a museos/casas de la cultura/monumentos /haciendas',
                'Recorridos Agroturísticos',
                'Actividades religiosas - visitas a iglesias',
                'Asistencia a espectáculos artísticos y festivales, fiestas y ferias',
                'Actividades de esparcimiento (fogatas, espacios musicales y de literatura, etc)',
                'actividades de bienestar (yoga, meditación, sanación)',
                'Avistamiento de aves',
                'Observación Especializada de Fauna (especies diferentes a las aves)',
                'Observación de flora',
                'Visita/ Senderismo a ríos, lagunas y cascadas, reservas y / o parques naturales.',
                'Actividades de aventura y deportes extremos',
                'Actividades de baño recreativo',
                'Recorridos en bicicleta',
                'Visita a discotecas bares y karaokes, casinos y juegos de azar',
                'Compra de bienes de consumo duradero',
                'Actividades laborales y/o inversión - conferencias / congresos',
                'Turismo Etnográfico o Actividades con comunidades étnicas',
                'Visita a zonas arqueológicas',
            ],
            itemsPorcentajeOcupacion: [
                '1% a 25%',
                '26% a 50%',
                '51% a 75%',
                '76% a 100%'
            ],
            itemsValorPromedioServicios: [
                '$50.000 a $99.000 pesos',
                '$100.000 a $300.000 pesos',
                '$300.000 a $500.000 pesos',
                '+ de $500.000 pesos'
            ],

            itemsClasificacionAlojamiento: [
                '1 estrella',
                '2 estrellas',
                '3 estrellas',
                '4 estrellas',
                '5 estrellas',
                'No aplica'
            ],

            itemsPreciosPromediosPlatos: [
                '$50.000 a $99.000 pesos ',
                '$100.000 a $300.000 pesos ',
                '$300.000 a $500.000 pesos ',
                '+ de $500.000 pesos'
            ],

            itemsGremios: [
                'ACOTUR',
                'COTELCO',
                'ANATO',
                'AOPETURS',
                'CORPOLINDOSA',
                'Clúster turismo MECA',
                'Corredor del Jaguar',
                'ASOGUIAS',
                'Otro'
            ],

            itemsServicioAdicional: [
                'Actividades de bienestar (SPA, masajes, yoga)',
                'Senderismo, aviturismo, observación de flora y fauna',
                'Otras actividades en áreas naturales',
                'Zonas húmedas',
                'Zonas deportivas',
                'Restaurante',
                'Lavandería (enfocado al cliente)',
                'Eventos',
                'Transporte',
                'Guianza',
                'Parqueadero',
                'WIFI',
                'Otros'
            ],

            itemsTipoTurismo: [
                {
                    title: 'Turismo Cultural',
                    isGroup: true,
                    show: false,
                    items: [
                        'Arqueológico',
                        'Arquitectónico',
                        'Etnográfico',
                        'Histórico',
                        'Turismo religioso',
                        'Cultural inmaterial',
                        'Turismo gastronómico',
                        'Enoturismo'
                    ]
                },
                {
                    title: 'Turismo de Naturaleza',
                    isGroup: true,
                    show: false,
                    items: [
                        'Ecoturismo',
                        'Avistamiento de aves',
                        'Observación de flora y fauna',
                        'Turismo de aventura',
                        'Senderismo y turismo de montaña',
                    ]
                },
                {
                    title: 'Baño recreativo',
                    isGroup: false,
                    show: false,
                    items: [
                    ]
                },
                {
                    title: 'Medicina tradicional y alternativa',
                    isGroup: false,
                    show: false,
                    items: [
                    ]
                },
                {
                    title: 'Turismo de Negocios (MICE:Meetings, Incentives, Conferences and Exhibitions)',
                    isGroup: true,
                    show: false,
                    items: [
                        'Convenciones y congresos',
                        'Incentivos',
                        'Ferias y exposiciones',
                    ]
                },
                {
                    title: 'Turismo Rural',
                    isGroup: true,
                    show: false,
                    items: [
                        'Agroturismo',
                        'Turismo rural comunitario'
                    ]
                },
                {
                    title: 'Turismo de compras',
                    isGroup: true,
                    show: false,
                    items: [
                        'Centros comerciales',
                        'Artesanías y souvenirs'
                    ]
                },
                {
                    title: 'Turismo deportivo',
                    isGroup: true,
                    show: false,
                    items: [
                        'Turismo deportivo especializado',
                        'Turismo de aventura deportiva'
                    ]
                },
                {
                    title: 'Turismo urbano',
                    isGroup: false,
                    show: false,
                    items: [
                    ]
                },
                {
                    title: 'Turismo de bienestar',
                    isGroup: false,
                    show: false,
                    items: [
                    ]
                },
                {
                    title: 'Turismo de memoria y paz',
                    isGroup: false,
                    show: false,
                    items: [
                    ]
                },
                {
                    title: 'Etnoturismo',
                    isGroup: false,
                    show: false,
                    items: [
                    ]
                },

            ],






            selectActividadesCamara: [],
            selectActividadesOfrecidas: [],
            selectServicioAdicional: [],
            selectGremiosAsociaciones: [],



            isMobile: false,

            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),


            stepFormServiceOrder: 1,
            stepTitles: [
                'Información Legal',
                'Información de Contacto',
                'Registro y Categoría',
                'Actividades Económicas',
                'Clasificación de Turismo',
                'Turismo de Base Comunitaria',
                'Actividades Ofrecidas',
                'Ocupación y Alojamiento',
                'Clasificación del Alojamiento',
                'Capacidad de Alojamiento',
                'Servicios Adicionales',
                'Precio Promedio de los Platos',
                'Restaurantes y Bar',
                'Aforo y Vehículos',
                'Información de Turistas',
                'Información de Ventas',
                'Asociaciones y Estrategias'
            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }
        },
    },
    methods: {
        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },

        toggleActividadesCamara(activity) {
            const index = this.selectActividadesCamara.indexOf(activity);
            if (index === -1) {
                this.selectActividadesCamara.push(activity);
            } else {
                this.selectActividadesCamara.splice(index, 1);
            }
        },

        toggleActividadesOfrecidas(activity) {
            const index = this.selectActividadesOfrecidas.indexOf(activity);
            if (index === -1) {
                this.selectActividadesOfrecidas.push(activity);
            } else {
                this.selectActividadesOfrecidas.splice(index, 1);
            }
        },

        toggleServicioAdicional(activity) {
            const index = this.selectServicioAdicional.indexOf(activity);
            if (index === -1) {
                this.selectServicioAdicional.push(activity);
            } else {
                this.selectServicioAdicional.splice(index, 1);
            }
        },

        toggleGremiosAsociaciones(activity) {
            const index = this.selectGremiosAsociaciones.indexOf(activity);
            if (index === -1) {
                this.selectGremiosAsociaciones.push(activity);
            } else {
                this.selectGremiosAsociaciones.splice(index, 1);
            }
        },


        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        }


    },

    mounted() {
        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);



    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 8px;
}


/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
    margin: 0;
}
</style>
