<template>

    <v-app-bar scroll-behavior="fully-hide" scroll-threshold="350" color="primary" elevation="1">

        <v-app-bar-title class="text-wrap text-h6 font-weight-bold">

            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>

            {{ title }}

        </v-app-bar-title>

        <template v-slot:append>
            <v-btn icon size="small">
                <v-icon>mdi-information</v-icon>
            </v-btn>
        </template>
    </v-app-bar>

</template>

<script>

export default {
    name: 'NavBarAnimation1',
    props: {
        title: {
            type: String,
            required: true
        },
    },

}
</script>