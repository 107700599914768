<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation1 v-if="isMdOrLower" title="Formulario Gestión de Empleabilidad" 
        subTitle="Apreciado empresario, en esta
                        sección registrará los
                        datos de empleabilidad de su empresa. Recuerde que estos datos no serán utilizados
                        en ningún momento para realizar control fiscal o de impuestos, sino que nos ayudan a
                        caracterizar la empleabilidad generada en el sector turismo en temporadas altas y
                        bajas."></NavBarAnimation1>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{ item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-primary pa-0"
                style="position: sticky; ">

                <LeftPanelAnimation1 title="Formulario gestión de" subTitle="Apreciado empresario, en esta
                        sección registrará los
                        datos de empleabilidad de su empresa. Recuerde que estos datos no serán utilizados
                        en ningún momento para realizar control fiscal o de impuestos, sino que nos ayudan a
                        caracterizar la empleabilidad generada en el sector turismo en temporadas altas y
                        bajas." :wordsAnimate="['Empleabilidad']"></LeftPanelAnimation1>

            </v-col>
            <v-col lg="7" xl="8" cols="12" style="    background: #fff; z-index: 9;">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div>
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>



                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">
                                <v-window-item :value="1" >
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">NIT</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(50)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">DV</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(50)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es el nombre del representante
                                                    legal de la
                                                    empresa?</span> <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="2" >
                                    <v-card-text class="text-body-2">

                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Nombre del Establecimiento</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Dirección</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Email </span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.emailFormat]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="3" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Registro Nacional de Turismo
                                                    (RNT)</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Categoría de RNT</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="4" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Señale las actividades económicas
                                                    registradas en Cámara de
                                                    Comercio de este establecimiento</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-list lines="one" select-strategy="classic" class="overflow-visible">
                                                    <v-list-item class="rounded-lg ma-1" @click="toggleActivity(item)"
                                                        v-for="(item, index) in itemsActivities" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': selectActivities.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                :rules="[validations.required]"
                                                                    :model-value="isActive"
                                                                    v-model="selectActivities"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item>


                                                </v-list>





                                                <!-- si en array selectActivities contiene el valor agregamos la clase selected-check al div -->






                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="5" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">Indique el número de empleados
                                                    contratados el año anterior,
                                                    de acuerdo con la forma de la vinculación laboral que tienen (debe
                                                    tener al menos un
                                                    dato en toda la matriz)</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-table density="compact" class="mt-2">
                                                    <thead v-if="!isMobile">
                                                        <tr>
                                                            <th>

                                                            </th>
                                                            <th class="text-caption text-center font-weight-bold"
                                                                colspan="2">
                                                                Colaboradores de la empresa
                                                            </th>
                                                            <th class="text-caption text-center font-weight-bold"
                                                                colspan="2">
                                                                Dueño y/o socios que trabajan en la empresa
                                                            </th>
                                                            <th>

                                                            </th>

                                                        </tr>
                                                        <tr>
                                                            <th class="text-caption text-center font-weight-bold">Cargos
                                                                / Tipo de
                                                                Vinculación</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Hombres</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Mujeres</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Hombres</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Mujeres</th>
                                                            <th
                                                                class="text-caption text-center font-weight-bold text-red">
                                                                Total</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(row, index) in rowsTable1" :key="index">
                                                            <template v-if="!isMobile">
                                                                <td class="text-caption">{{ row.label }}</td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center">0</td>
                                                            </template>
                                                            <template v-else>
                                                                <td :colspan="6">
                                                                    <v-card class="py-3" elevation="0">
                                                                        <v-list>
                                                                            <v-list-item>
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Cargos
                                                                                    / Tipo
                                                                                    de Vinculación:</v-list-item-title>
                                                                                <v-list-item-subtitle
                                                                                    class="text-caption text-wrap">{{
                                                                                        row.label
                                                                                    }}</v-list-item-subtitle>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Hombres
                                                                                    (Colaboradores de la
                                                                                    empresa):</v-list-item-title>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Mujeres
                                                                                    (Colaboradores de la
                                                                                    empresa):</v-list-item-title>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Hombres
                                                                                    (Dueño
                                                                                    y/o socios que trabajan en la
                                                                                    empresa):</v-list-item-title>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Mujeres
                                                                                    (Dueño
                                                                                    y/o socios que trabajan en la
                                                                                    empresa):</v-list-item-title>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-list-item>
                                                                            <v-list-item>
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Total:</v-list-item-title>
                                                                                <span>0</span>
                                                                            </v-list-item>
                                                                        </v-list>
                                                                    </v-card>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>

                                                </v-table>

                                            </v-col>


                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="6" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Alguno de los cargos
                                                    directivos/gerenciales de la empresa
                                                    (Gerente, Administrador, Coordinador) durante el año anterior fue
                                                    ocupado por
                                                    mujeres?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary" class="mt-2" v-model="form.mujeres">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.mujeres == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.mujeres == 'No' }"></v-radio>
                                                </v-radio-group>


                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="7" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Cuántos de los empleados del año
                                                    anterior pertenecen a los
                                                    siguientes grupos poblacionales? (Recuerde que un individuo puede
                                                    ser clasificado en 2 o
                                                    más grupos poblacionales, por ejemplo, indigenas de 18 a 28
                                                    años)</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-table density="compact" class="mt-2">
                                                    <thead v-if="!isMobile">

                                                        <tr>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Cargos / Tipo de Vinculación</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Grupos étnicos</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Víctimas Conflicto</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Jóvenes (18 a 28 años)</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Firmantes de paz</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Discapacidad</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                LGBTIQ+</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Ninguno</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(row, index) in rowsTable2" :key="index">
                                                            <template v-if="!isMobile">
                                                                <td class="text-caption">{{ row.label }}</td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        v-if="row.label != 'Total'"
                                                                        hide-details></v-text-field> <span
                                                                        v-if="row.label == 'Total'">0</span> </td>
                                                            </template>
                                                            <template v-else>
                                                                <td :colspan="6">
                                                                    <v-card class="py-3" elevation="0">
                                                                        <v-row>
                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Cargos
                                                                                    / Tipo
                                                                                    de Vinculación:</v-list-item-title>
                                                                                <v-list-item-subtitle
                                                                                    class="text-caption text-wrap">{{
                                                                                        row.label
                                                                                    }}</v-list-item-subtitle>

                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row style="display: ruby;">
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Grupos
                                                                                    étnicos:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Víctimas
                                                                                    Conflicto:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Jóvenes (18 a
                                                                                    28 años):</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Firmantes de
                                                                                    paz:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span
                                                                                    class="text-caption">Discapacidad:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span
                                                                                    class="text-caption">LGBTIQ+:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <span
                                                                                    class="text-caption">Ninguno:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                        </v-row>

                                                                    </v-card>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>

                                                </v-table>

                                            </v-col>


                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="8" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Del total de empleados directos del año
                                                    anterior, indique el
                                                    número de empleados en cada casilla acuerdo con el valor del salario
                                                    mensual que pagó?
                                                    (incluya comisiones, bonificaciones y pagos extralaborales)</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-table density="compact" class="mt-2">
                                                    <thead v-if="!isMobile">

                                                        <tr>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Cargos / Tipo de Vinculación</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Menos de 1 SMMLV</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                1 SMMLV</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de 1 SMMLV-Hasta 2 SMMLV</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de 2 SMMLV-Hasta 4 SMMLV</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de 4 SMMLV</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Total</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(row, index) in rowsTable3" :key="index">
                                                            <template v-if="!isMobile">
                                                                <td class="text-caption">{{ row.label }}</td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center">0</td>


                                                            </template>
                                                            <template v-else>
                                                                <td :colspan="6">
                                                                    <v-card class="py-3" elevation="0">
                                                                        <v-row>
                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Cargos
                                                                                    / Tipo
                                                                                    de Vinculación:</v-list-item-title>
                                                                                <v-list-item-subtitle
                                                                                    class="text-caption text-wrap">{{
                                                                                        row.label
                                                                                    }}</v-list-item-subtitle>

                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row style="display: ruby;">
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Menos de 1
                                                                                    SMMLV:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">1
                                                                                    SMMLV:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Más de 1
                                                                                    SMMLV-Hasta 2 SMMLV:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Más de 2
                                                                                    SMMLV-Hasta 4 SMMLV:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <span class="text-caption">Más de 4
                                                                                    SMMLV:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <span class="text-caption">Total:</span>
                                                                                <br>
                                                                                0

                                                                            </v-col>

                                                                        </v-row>

                                                                    </v-card>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>

                                                </v-table>

                                            </v-col>


                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="9" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Del total de empleados indirectos del
                                                    año anterior, indique
                                                    el número de empleados en cada casilla acuerdo con el valor del pago
                                                    diario?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-table density="compact" class="mt-2">
                                                    <thead v-if="!isMobile">

                                                        <tr>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Cargos / Tipo de Vinculación</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                $50.000 o menos</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de $50.000 hasta $100.000</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de $100.000 hasta $150.000</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de $150.000 hasta $200.000</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Más de $200.000</th>
                                                            <th class="text-caption text-center font-weight-bold">
                                                                Total</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="(row, index) in rowsTable4" :key="index">
                                                            <template v-if="!isMobile">
                                                                <td class="text-caption">{{ row.label }}</td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"><v-text-field color="primary"
                                                                        variant="outlined" density="compact"
                                                                        hide-details></v-text-field> </td>
                                                                <td class="text-center"> 0 </td>


                                                            </template>
                                                            <template v-else>
                                                                <td :colspan="6">
                                                                    <v-card class="py-3" elevation="0">
                                                                        <v-row>
                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <v-list-item-title
                                                                                    class="text-caption text-wrap">Cargos
                                                                                    / Tipo
                                                                                    de Vinculación:</v-list-item-title>
                                                                                <v-list-item-subtitle
                                                                                    class="text-caption text-wrap">{{
                                                                                        row.label
                                                                                    }}</v-list-item-subtitle>

                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row style="display: ruby;">
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">$50.000 o
                                                                                    menos:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Más de
                                                                                    $50.000 hasta $100.000:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Más de
                                                                                    $100.000 hasta $150.000:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="6" md="6" cols="6" class="py-1">
                                                                                <span class="text-caption">Más de
                                                                                    $150.000 hasta $200.000:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>

                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <span class="text-caption">Más de
                                                                                    $200.000:</span>
                                                                                <v-text-field color="primary"
                                                                                    variant="outlined" density="compact"
                                                                                    hide-details></v-text-field>
                                                                            </v-col>
                                                                            <v-col sm="12" md="12" cols="12"
                                                                                class="py-1">
                                                                                <span class="text-caption">Total:</span>
                                                                                <br>
                                                                                0
                                                                            </v-col>

                                                                        </v-row>

                                                                    </v-card>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>

                                                </v-table>

                                            </v-col>


                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="10" >

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    Indique la cantidad de empleados (directos e indirectos) del año
                                                    anterior que manejan un
                                                    segundo idioma extranjero (ejemplo: inglés)
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    Indique los idiomas extranjeros que usted o su equipo de personal
                                                    directo o indirecto
                                                    conoce 
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-list lines="one" select-strategy="classic" class="overflow-visible">
                                                    <v-list-item class="rounded-lg ma-1" @click="toggleLanguage(item)"
                                                        v-for="(item, index) in itemsLanguage" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': selecteLanguage.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :model-value="isActive"
                                                                    v-model="selecteLanguage"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item>
                                                    <!-- Si en selecteLanguage esta la palabra Otro se muestra el campo cual -->
                                                    <v-list-item>
                                                        <v-text-field v-if="selecteLanguage.includes('Otro')"
                                                            color="primary" variant="underlined"
                                                            placeholder="¿Cuál?"></v-text-field>
                                                    </v-list-item>

                                                </v-list>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="11" >

                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    De las siguientes afirmaciones, marque las que son verdaderas para
                                                    su empresa 
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-list lines="one" select-strategy="classic" class="overflow-visible">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleAfirmations('Trabajo con guías certificados')"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': selectAfirmations.includes('Trabajo con guías certificados') }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary"
                                                                    true-value="Trabajo con guías certificados"
                                                                    :model-value="isActive"
                                                                    v-model="selectAfirmations"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>Trabajo con guías
                                                            certificados</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="selectAfirmations.includes('Trabajo con guías certificados')">
                                                        <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app color="primary" variant="underlined"
                                                            placeholder="¿Cuántos?"></v-text-field>
                                                    </v-list-item>
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleAfirmations('Trabajo con guías NO certificados')"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': selectAfirmations.includes('Trabajo con guías NO certificados') }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary"
                                                                    true-value="Trabajo con guías NO certificados"
                                                                    :model-value="isActive"
                                                                    v-model="selectAfirmations"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>Trabajo con guías NO
                                                            certificados</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-if="selectAfirmations.includes('Trabajo con guías NO certificados')">
                                                        <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app  color="primary" variant="underlined"
                                                            placeholder="¿Cuántos?"></v-text-field>
                                                    </v-list-item>


                                                </v-list>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="12" >

                                    <v-card-text class="text-body-2">
                                        <span class="font-weight-bold">
                                            ¿Digite la cantidad de empleados (directos e indirectos) del año anterior,
                                            según el último nivel
                                            educativo alcanzado?
                                        </span>
                                        <span class="font-weight-bold">*</span>

                                        <v-row>
                                            <v-col md="6" cols="12" class="py-1">
                                                <v-row>
                                                    <v-col md="6" cols="12" class="py-1">
                                                    </v-col>
                                                    <v-col md="6" cols="12" class="py-1 text-center">
                                                        <span class="font-weight-bold">Cantidad de empleados</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="(item, index) in rowsEmpleados1" :key="index">

                                                    <v-col md="6" cols="12" class="py-1 d-flex align-center">
                                                        {{ item.label }}
                                                    </v-col>
                                                    <v-col md="6" cols="12" class="py-1">
                                                        <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app  density="compact" color="primary"
                                                            variant="underlined" hide-details></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <v-row>
                                                    <v-col md="6" cols="12" class="py-1">
                                                    </v-col>
                                                    <v-col md="6" cols="12" class="py-1 text-center">
                                                        <span class="font-weight-bold">Cantidad de empleados</span>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="(item, index) in rowsEmpleados2" :key="index">
                                                    <v-col md="6" cols="12" class="py-1 d-flex align-center">
                                                        {{ item.label }}
                                                    </v-col>
                                                    <v-col md="6" cols="12" class="py-1">
                                                        <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app  density="compact" color="primary"
                                                            variant="underlined" hide-details></v-text-field>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="13">

                                    <v-card-text class="text-body-2" >
                                        
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                            ¿Cuántos de sus empleados (directos e indirectos) son personas
                                            originarias, nacidas o
                                            propias del Departamento del Guaviare?
                                        </span>
                                        <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="14">

                                    <v-card-text class="text-body-2" >

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    ¿Qué documentos exigio durante el año anterior para la contratacion
                                                    de sus empleados?
                                                    
                                                </span>
                                                <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectDocumentosExigidos" :items="itemsDocumentosExigidos"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectDocumentosExigidos.length - 2 }} {{
                                                                selectDocumentosExigidos.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectDocumentosExigidos"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsDocumentosExigidos.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleDocumentosExigidos(item)"
                                                            v-for="(item, index) in itemsDocumentosExigidos"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectDocumentosExigidos.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectDocumentosExigidos"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->


                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="15">

                                    <v-card-text class="text-body-2" >

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    ¿Qué obligaciones cumplio como empleador durante el año pasado?
                                                    
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectObligacionesEmpleador" :items="itemsObligacionesEmpleador"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectObligacionesEmpleador.length - 2 }} {{
                                                                selectObligacionesEmpleador.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectObligacionesEmpleador"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsObligacionesEmpleador.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleObligacionesEmpleador(item)"
                                                            v-for="(item, index) in itemsObligacionesEmpleador"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectObligacionesEmpleador.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectObligacionesEmpleador"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->


                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="16">

                                    <v-card-text class="text-body-2" >

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    ¿Qué documentos exigió durante el año anterior para la contratacion
                                                    de personas
                                                    jurídicas (es decir, cuando contrata a otras empresas)? 
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectDocumentosRequeridosPJ" :items="itemsDocumentosRequeridosPJ"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectDocumentosRequeridosPJ.length - 2 }} {{
                                                                selectDocumentosRequeridosPJ.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectDocumentosRequeridosPJ"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsDocumentosRequeridosPJ.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleDocumentosRequeridosPJ(item)"
                                                            v-for="(item, index) in itemsDocumentosRequeridosPJ"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectDocumentosRequeridosPJ.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectDocumentosRequeridosPJ"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->


                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="17">

                                    <v-card-text class="text-body-2" >

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    Indique los meses del año anterior, en el que requirió contratar más
                                                    empleados (directos o indirectos) 
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1" @click="toggleMeses(item)"
                                                            v-for="(item, index) in itemsMeses" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectMeses.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectMeses"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div>


                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="18" >

                                    <v-card-text class="text-body-2">
                                        <span class="font-weight-bold">
                                            ¿Por favor indique el número de empleados (directos e indirectos) que
                                            contrató el
                                            año anterior para las siguientes temporadas?
                                        </span>
                                        <span class="font-weight-bold">*</span>

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <div class="mt-3">

                                                    <v-row v-for="(item, index) in rowNumeroEmpleadosTemporadas"
                                                        :key="index">

                                                        <v-col md="6" cols="12" class="py-1 d-flex align-center">
                                                            {{ item.label }}
                                                        </v-col>
                                                        <v-col md="6" cols="12" class="py-1">
                                                            <v-text-field :rules="[validations.required, validations.maxLength(255)]" v-numbers-only-app  density="compact" color="primary"
                                                                variant="underlined" hide-details></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                </div>

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="19">

                                    <v-card-text class="text-body-2" >

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    Indique los meses del año anterior, en el que requirió contratar más
                                                    empleados (directos o indirectos) 
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectAccionesSeguridad" :items="itemsAccionesSeguridad"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectAccionesSeguridad.length - 2 }} {{
                                                                selectAccionesSeguridad.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectAccionesSeguridad"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsAccionesSeguridad.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleAccionesSeguridad(item)"
                                                            v-for="(item, index) in itemsAccionesSeguridad" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectAccionesSeguridad.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectAccionesSeguridad"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->


                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="20">

                                    <v-card-text class="text-body-2" >

                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">
                                                    ¿Qué acciones o medidas toma para promover el bienestar de las
                                                    personas
                                                    trabajadoras de su empresa / emprendimiento? 
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectMedidasBienestar" :items="itemsMedidasBienestar"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectMedidasBienestar.length - 2 }} {{
                                                                selectMedidasBienestar.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectMedidasBienestar"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsMedidasBienestar.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleMedidasBienestar(item)"
                                                            v-for="(item, index) in itemsMedidasBienestar" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': selectMedidasBienestar.includes(item) }">
                                                            <template v-slot:append="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="selectMedidasBienestar"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                                        </v-list-item>

                                                    </v-list>
                                                </div> -->


                                            </v-col>

                                        </v-row>

                                    </v-card-text>
                                </v-window-item>



                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->

                </v-row>




            </v-col>
        </v-row>

        <UserExperience></UserExperience>

    </v-container>
    <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation1 from './includes/LeftPanelAnimation1.vue';
import NavBarAnimation1 from './includes/NavBarAnimation1.vue';
import UserExperience from './includes/UserExperience.vue';


export default {
    name: 'FormEmployability',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation1,
        NavBarAnimation1,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()
        
        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            showTopShadow: false,
            showBottomShadow: true,

            form: {
                mujeres: null,
            },

            rowsTable1: [
                { label: 'Contrato laboral con la empresa' },
                { label: 'Prestación de servicios (por más de un mes)' },
                { label: 'Contrato de aprendizaje' },
                { label: 'Pasante' },
                { label: 'Indirectos (Menor a un mes/por temporada/Evento)' },
                { label: 'Por demanda (Transporte, servicios de limpieza, técnico, etc.)' },
                { label: 'Por demanda (Únicamente guianza)' },
                { label: 'Total' }
            ],
            rowsTable2: [
                { label: 'Contrato laboral con la empresa' },
                { label: 'Prestación de servicios (por más de un mes)' },
                { label: 'Contrato de aprendizaje' },
                { label: 'Pasante' },
                { label: 'Indirectos (Menor a un mes/por temporada/Evento)' },
                { label: 'Por demanda (Transporte, servicios de limpieza, técnico, etc.)' },
                { label: 'Por demanda (Únicamente guianza)' },
            ],
            rowsTable3: [
                { label: 'Contrato laboral con la empresa' },
                { label: 'Prestación de servicios' },
                { label: 'Contrato de aprendizaje' },
                { label: 'Pasante' },
            ],

            rowsTable4: [
                { label: 'Indirectos (Menor a un mes/por temporada/Evento)' },
                { label: 'Por demanda (Transporte, servicio técnico, etc.)' },
                { label: 'Por demanda (Guianza)' },
            ],

            rowsEmpleados1: [
                { label: 'Sin estudio' },
                { label: 'Primaria incompleta' },
                { label: 'Primaria completa' },
                { label: 'Bachillerato incompleto' },
                { label: 'Bachillerato completo' },
                { label: 'Técnico/Tecnológo sin titulo' },
            ],
            rowsEmpleados2: [
                { label: 'Técnico/Tecnológo con titulo' },
                { label: 'Profesional sin titulo' },
                { label: 'Profesional con titulo' },
                { label: 'Profesional con postgrado sin titulo' },
                { label: 'Profesional con postgrado con titulo' },
            ],

            rowNumeroEmpleadosTemporadas: [
                { label: 'Temporada baja (febrero a mayo y agosto a octubre)' },
                { label: 'Vacaciones Mitad de año' },
                { label: 'Temporada de vacaciones de fin de año (noviembre, diciembre, enero)' },
                { label: 'Semana Santa' },
                { label: 'Semana de receso estudiantil (octubre)' },
                { label: 'Ferias y fiestas (noviembre, diciembre, enero)' },
            ],

            itemsDocumentosExigidos: [
                'Hoja de vida',
                'Certificados de estudios',
                'Certificados laborales',
                'Documentos de identificación',
                'Constancia de afiliación/pago de pensión',
                'Constancia de afiliación/pago de salud',
                'Copia del RUT',
                'Exámenes médicos de ingreso',
                'Certificación bancaria',
                'Permiso de permanencia (extranjeros)',
                'Certificados de antecedentes',
                'Ninguno'
            ],

            itemsObligacionesEmpleador: [
                'Pago de salarios',
                'Afiliación a salud, pensión y arl',
                'Vacaciones',
                'Prestaciones sociales (Primas y cesantias)',
                'Auxilio transporte (cuando aplica)',
                'Pago horas extra y recargos',
                'Pago de incapacidades',
                'Dotación (cuando aplica)',
                'Viáticos (cuando aplica)',
                'Reconocimiento de licencias laborales					',
                'Pago de licencias laborales (Maternidad, luto, sindical, calamidad, electoral)	',

            ],

            itemsDocumentosRequeridosPJ: [
                'Certificado existencia y representación legal',
                'Registro Único Tributario (RUT)',
                'Antecedentes representante legal y empresa (Fiscales, Judiciales, Disciplinarios, Medidas correctivas)',
                'Certificado seguridad social y parafiscales',
                'Certificación de experiencia',
                'Certificado REDAM (representante legal)',
                'Estados financieros (últimos 12 meses)',
                'Certificado de capacidad financiera y organizacional',
                'Certificados ambientales (Cuando aplica)',
            ],

            itemsMeses: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],

            itemsLanguage: [
                'Inglés',
                'Francés',
                'Alemán',
                'Portugués',
                'Italiano',
                'Otro',
            ],

            itemsAccionesSeguridad: [
                'Estrategia de seguridad y salud en el trabajo',
                'Plan de emergencias',
                'Programas de capacitación en primeros auxilios',
                'Simulacros de emergencia',
                'Dotación de elementos de seguridad (botiquín, extintores, radios comunicación, etc.)',
                'Inspección y mantenimiento a instalaciones eléctricas ',
                'Evaluación de riesgos y mantenimiento del edificio',
                'Señalización de emergencia',
                'Programas de capacitación en atención de emergencias',
                'Medición de incidentes',
            ],

            itemsMedidasBienestar: [
                'Equilibrio entre el trabajo y la vida familiar',
                'Mejora constante de los espacios de trabajo',
                'Proponer retos y objetivos',
                'Obtener reconocimiento continuo',
                'Formación constante',
                'Fomentar el buen ambiente laboral',
                'Promover la participación activa de los empleados',
                'Ofrecer las máximas facilidades para la comida',
                'Incentivar un estilo de vida saludable',
                'Fomentar una cultura de apoyo y colaboración',
                'Escuchar y responder a las necesidades de los empleados',
                'Actividades sociales, recreativas, benéficas o de integración.',
            ],

            selecteLanguage: [],
            selectAfirmations: [],
            selectDocumentosExigidos: [],
            selectObligacionesEmpleador: [],
            selectDocumentosRequeridosPJ: [],
            selectMeses: [],
            selectAccionesSeguridad: [],
            selectMedidasBienestar: [],

            isMobile: false,

            num: 120,
            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

            itemsActivities: [
                'Bar',
                'Restaurante y Gastrobares',
                'Alojamiento',
                'Agencias de viajes',
                'Transporte (Terminal transporte)',
                'Actividades culturales y eventos',
            ],
            selectActivities: [],
            drawerStep: true,
            stepFormServiceOrder: 1,
            marketplace: '',
            stepTitles: [
                'Información de la Empresa',
                'Información del Establecimiento',
                'Registro Nacional de Turismo',
                'Información de Cámara de Comercio',
                'Información General de Empleados',
                'Diversidad de Género en Cargos Directivos',
                'Distribución de Empleados por Grupos Poblacionales',
                'Distribución de Empleados por Salario Mensual',
                'Distribución de Empleados Indirectos por Pago Diario',
                'Idiomas Extranjeros en el Personal',
                'Afirmaciones sobre la Empresa (Agencias de Viajes)',
                'Nivel Educativo de los Empleados',
                'Empleados Originarios del Departamento del Guaviare',
                'Documentos Requeridos para la Contratación de Empleados',
                'Obligaciones Cumplidas como Empleador',
                'Documentos Requeridos para la Contratación de Personas Jurídicas',
                'Meses con Mayor Contratación de Empleados',
                'Contratación de Empleados por Temporada',
                'Acciones para Garantizar la Seguridad de Empleados y Clientes',
                'Medidas para Promover el Bienestar de los Trabajadores'
            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }
        },
    },
    methods: {
        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },

        toggleActivity(activity) {
            const index = this.selectActivities.indexOf(activity);
            if (index === -1) {
                this.selectActivities.push(activity);
            } else {
                this.selectActivities.splice(index, 1);
            }
        },

        toggleLanguage(language) {
            const index = this.selecteLanguage.indexOf(language);
            if (index === -1) {
                this.selecteLanguage.push(language);
            } else {
                this.selecteLanguage.splice(index, 1);
            }
        },

        toggleAfirmations(afirmation) {
            const index = this.selectAfirmations.indexOf(afirmation);
            if (index === -1) {
                this.selectAfirmations.push(afirmation);
            } else {
                this.selectAfirmations.splice(index, 1);
            }
        },

        toggleDocumentosExigidos(documento) {
            const index = this.selectDocumentosExigidos.indexOf(documento);
            if (index === -1) {
                this.selectDocumentosExigidos.push(documento);
            } else {
                this.selectDocumentosExigidos.splice(index, 1);
            }
        },

        toggleObligacionesEmpleador(obligacion) {
            const index = this.selectObligacionesEmpleador.indexOf(obligacion);
            if (index === -1) {
                this.selectObligacionesEmpleador.push(obligacion);
            } else {
                this.selectObligacionesEmpleador.splice(index, 1);
            }
        },

        toggleDocumentosRequeridosPJ(documento) {
            const index = this.selectDocumentosRequeridosPJ.indexOf(documento);
            if (index === -1) {
                this.selectDocumentosRequeridosPJ.push(documento);
            } else {
                this.selectDocumentosRequeridosPJ.splice(index, 1);
            }
        },

        toggleMeses(mes) {
            const index = this.selectMeses.indexOf(mes);
            if (index === -1) {
                this.selectMeses.push(mes);
            } else {
                this.selectMeses.splice(index, 1);
            }
        },

        toggleAccionesSeguridad(accion) {
            const index = this.selectAccionesSeguridad.indexOf(accion);
            if (index === -1) {
                this.selectAccionesSeguridad.push(accion);
            } else {
                this.selectAccionesSeguridad.splice(index, 1);
            }
        },

        toggleMedidasBienestar(medida) {
            const index = this.selectMedidasBienestar.indexOf(medida);
            if (index === -1) {
                this.selectMedidasBienestar.push(medida);
            } else {
                this.selectMedidasBienestar.splice(index, 1);
            }
        },


        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        }


    },

    mounted() {
        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);



    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 8px;
}


/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
    margin: 0;
}

</style>
