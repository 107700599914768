<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation1 v-if="isMdOrLower" title="Formulario de Ofertas para Guías" subTitle="Hola apreciado/a guía de turismo, en esta sección podrás registrar tus datos. Recuerda que
para el departamento es importante contar con esta información actualizada, ya que es útil para realizar
planificación de políticas públicas del departamento, planificar proyectos o realizar gestiones para la mejora de nuestro destino turístico.
Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos,
por lo que no se compartirán con otras entidades como: DIAN, Cámara y Comercio, Alcaldías, etc.">
        </NavBarAnimation1>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{
                                item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-primary pa-0"
                style="position: sticky; ">

                <LeftPanelAnimation1 title="Formulario de Ofertas para" subTitle="Hola apreciado/a guía de turismo, en esta sección podrás registrar tus datos. Recuerda que
para el departamento es importante contar con esta información actualizada, ya que es útil para realizar
planificación de políticas públicas del departamento, planificar proyectos o realizar gestiones para la mejora de nuestro destino turístico.
Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos,
por lo que no se compartirán con otras entidades como: DIAN, Cámara y Comercio, Alcaldías, etc."
                    :wordsAnimate="['Guías']"></LeftPanelAnimation1>

            </v-col>
            <v-col lg="7" xl="8" cols="12" style="    background: #fff; z-index: 9;">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div>
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>



                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">
                                <v-window-item :value="1">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Tipo de documento de
                                                    identificación</span> <span class="font-weight-bold">*</span>
                                                <v-select :rules="[validations.required]" v-model="form.tipo_documento"
                                                    color="primary" variant="underlined" :items="[
                                                        'Cédula de ciudadanía',
                                                        'Cédula de extranjería',
                                                        'Otro'
                                                    ]"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1" v-if="form.tipo_documento == 'Otro'">
                                                <span class="font-weight-bold">¿Cuál?</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col :md="form.tipo_documento == 'Otro' ? 12 : 6" cols="12" class="py-1">
                                                <span class="font-weight-bold">Número de documento de
                                                    identificación</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(50)]"
                                                    color="primary" variant="underlined"
                                                    v-numbers-only-app></v-text-field>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es el nombre del representante
                                                    legal de la
                                                    empresa?</span> <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Sexo al nacer del representante legal de
                                                    la
                                                    empresa?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.repre_genero">
                                                    <v-radio hi v-for="(item, index) in itemsRepreGenero" :key="index"
                                                        :value="item.value" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.repre_genero == item.value }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item.label }}
                                                                <p class="text-caption font-italic"
                                                                    v-if="item.description">{{ item.description }}</p>
                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="3">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique si pertenece a estos grupos
                                                    poblacionales</span> <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.repre_grupos_poblacionales"
                                                    :items="itemRepreGruposPoblacionales" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.repre_grupos_poblacionales.length - 2 }} {{
                                                                form.repre_grupos_poblacionales.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.repre_grupos_poblacionales"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemRepreGruposPoblacionales.length"></v-divider>
                                                    </template>

                                                </v-select>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">De acuerdo con su cultura, pueblo, rasgos
                                                    físicos, se reconoce como:</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.repre_reconoce_como" :items="itemRepreCultura"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.repre_reconoce_como.length - 2 }} {{
                                                                form.repre_reconoce_como.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.repre_reconoce_como"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemRepreCultura.length"></v-divider>
                                                    </template>

                                                </v-select>

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Télefono(s) personal(es)</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-combobox :rules="[validations.required]" color="primary"
                                                    variant="underlined" v-numbers-only-app chips multiple
                                                    closable-chips></v-combobox>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Correo electrónico personal</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.emailFormat]"
                                                    color="primary" variant="underlined"></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="4">
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Tiene Registro Nacional de Turismo
                                                    activo?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.tiene_rnt">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.tiene_rnt == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.tiene_rnt == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" v-if="form.tiene_rnt == 'Si'">
                                                <span class="font-weight-bold">Registro Nacional de Turismo (RNT)</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Tiene tarjeta profesional activa?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.tiene_tarjeta_prof">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.tiene_tarjeta_prof == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.tiene_tarjeta_prof == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1"
                                                v-if="form.tiene_tarjeta_prof == 'Si'">
                                                <span class="font-weight-bold">Número de Tarjeta Profesional. Escriba el
                                                    número
                                                    de la Tarjeta Profesional</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="5">
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Ejerce actualmente la actividad de
                                                    guianza
                                                    turística?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.ejerce_actividad">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.ejerce_actividad == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.ejerce_actividad == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" v-if="form.ejerce_actividad == 'Si'">
                                                <span class="font-weight-bold">¿En qué municipios ejerce su
                                                    actividad?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-list lines="one" select-strategy="classic"
                                                    class="overflow-visible list-container-documents">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="toggleMunicipiosEjerceActividad(item)"
                                                        v-for="(item, index) in itemsMunicipiosEjerceActividad"
                                                        :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': form.municipios_ejerce_actividad.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :model-value="isActive"
                                                                    v-model="form.municipios_ejerce_actividad"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item>



                                                </v-list>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Tiene usted alguna profesión diferente a
                                                    la
                                                    guianza?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.tiene_profesion">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.tiene_profesion == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.tiene_profesion == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" v-if="form.tiene_profesion == 'Si'">
                                                <span class="font-weight-bold">¿Qué profesión?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="6">
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es el nivel educativo máximo en
                                                    esta
                                                    profesión?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-select :rules="[validations.required]" color="primary"
                                                    variant="underlined"
                                                    :items="itemsNivelEducaticoMax"></v-select>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuánto tiempo lleva operando?
                                                    Experiencia. Por
                                                    favor indicar cuántos meses y/o años.</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-row>
                                                    <v-col md="6" cols="12">
                                                        <span class="font-weight-medium">Años</span>
                                                        <v-text-field
                                                            :rules="[validations.required, validations.maxLength(2)]"
                                                            color="primary" variant="underlined"
                                                            v-numbers-only-app></v-text-field>
                                                    </v-col>
                                                    <v-col md="6" cols="12">
                                                        <span class="font-weight-medium">Meses</span>
                                                        <v-text-field
                                                            :rules="[validations.required, validations.maxLength(2)]"
                                                            color="primary" variant="underlined"
                                                            v-numbers-only-app></v-text-field>
                                                    </v-col>
                                                </v-row>

                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>



                                <v-window-item :value="7">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique su especialidad en la guianza
                                                    turística.
                                                    Seleccione las actividades en las que se especializa.</span> <span
                                                    class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.indique_especialidad_guianza"
                                                    :items="itemsEspecialidadGuianza" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.indique_especialidad_guianza.length - 2 }} {{
                                                                form.indique_especialidad_guianza.length - 2 == 1 ? 'opción'
                                                                    :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.indique_especialidad_guianza"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsEspecialidadGuianza.length"></v-divider>
                                                    </template>

                                                </v-select>

                                            </v-col>






                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="8">
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Seleccione el idioma que está en
                                                    capacidad de
                                                    hablar en sus recorridos y su nivel.</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-select :rules="[validations.required]" color="primary"
                                                    variant="underlined" v-model="form.idiomas_recorridos"
                                                    :items="itemsIdiomasRecorridos"></v-select>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1"
                                                v-if="form.idiomas_recorridos == 'Otro'">
                                                <v-text-field :rules="[validations.required]" color="primary"
                                                    variant="underlined" placeholder="¿Cuál?"></v-text-field>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">

                                                <span class="font-weight-bold">¿Cuál es su nivel de manejo del
                                                    idioma?</span>
                                                <span class="font-weight-bold">*</span>
                                                <!-- campo tipo radio con opciones -->
                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="form.nivel_idioma">
                                                    <v-radio label="Básico" value="Básico" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.nivel_idioma == 'Básico' }"></v-radio>
                                                    <v-radio label="Intermedio" value="Intermedio" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.nivel_idioma == 'Intermedio' }"></v-radio>
                                                    <v-radio label="Avanzado" value="Avanzado" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': form.nivel_idioma == 'Avanzado' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>




                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="9">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la tarifa promedio por hora de la
                                                    prestación de sus servicios en idioma Español</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined"
                                                    v-numbers-only-app></v-text-field>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique la tarifa promedio por hora de la
                                                    prestación de sus servicios en idioma diferente al Español</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined"
                                                    v-numbers-only-app></v-text-field>

                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="10">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Principalmente, ¿quién contrata sus
                                                    servicios
                                                    como Guía Profesional de Turismo?</span> <span
                                                    class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.quien_contrata_servicios_guia"
                                                    :items="itemsQuienContrataServiciosGuia" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.quien_contrata_servicios_guia.length - 2 }} {{
                                                                form.quien_contrata_servicios_guia.length - 2 == 1 ? 'opción'
                                                                    :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.quien_contrata_servicios_guia"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsQuienContrataServiciosGuia.length"></v-divider>
                                                    </template>

                                                </v-select>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Indique los días de la semana en los que labora principalmente.</span> <span
                                                    class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="form.dias_semana_labora"
                                                    :items="itemsLaboraPrincipalmente" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ form.dias_semana_labora.length - 2 }} {{
                                                                form.dias_semana_labora.length - 2 == 1 ? 'opción'
                                                                    :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="form.dias_semana_labora"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsLaboraPrincipalmente.length"></v-divider>
                                                    </template>

                                                </v-select>

                                            </v-col>






                                        </v-row>
                                    </v-card-text>
                                </v-window-item>




                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->

                </v-row>




            </v-col>
        </v-row>

        <UserExperience></UserExperience>

    </v-container>
    <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation1 from './includes/LeftPanelAnimation1.vue';
import NavBarAnimation1 from './includes/NavBarAnimation1.vue';
import UserExperience from './includes/UserExperience.vue';


export default {
    name: 'FormOfferGuides',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation1,
        NavBarAnimation1,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()

        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            showTopShadow: false,
            showBottomShadow: true,

            form: {
                tipo_documento: null,
                repre_genero: null,
                repre_grupos_poblacionales: [],
                repre_reconoce_como: [],
                tiene_rnt: null,
                tiene_tarjeta_prof: null,
                ejerce_actividad: null,
                municipios_ejerce_actividad: [],
                tiene_profesion: null,
                indique_especialidad_guianza: [],
                idiomas_recorridos: null,
                nivel_idioma: null,
                quien_contrata_servicios_guia: [],
                dias_semana_labora: [],

            },

            itemsRepreGenero: [
                {
                    label: 'Mujer',
                    description: null,
                    value: 'Mujer'
                },
                {
                    label: 'Hombre',
                    description: null,
                    value: 'Hombre'
                },
                {
                    label: 'Intersexual',
                    description: 'Son personas que biológicamente desarrollan características',
                    value: 'Intersexual'
                }
            ],

            itemRepreGruposPoblacionales: [
                'Grupos Étnicos',
                'Sector Social LGBTIQ+',
                'Víctimas del conflicto',
                'Reincorporado/reinsertado',
                'Lider Social',
                'Mujer cabeza de hogar',
                'Ninguno'
            ],

            itemRepreCultura: [
                'Indígena',
                'Gitano/a o Rrom',
                'Palenquero/a de San Basilio',
                'Raizal del archipielago de San Andrés, Providencia y Santa Catalina',
                'Negro/a, mulato/a, afrodescendiente, afrocolombiano/a',
            ],


            itemsMunicipiosEjerceActividad: [
                'San José del Guaviare',
                'El Retorno',
                'Miraflores',
                'Calamar',
            ],

            itemsNivelEducaticoMax: [
                'Técnico',
                'Tecnólogo',
                'Profesional Incompleto',
                'Profesional Graduado',
                'Especialista',
                'Magister'
            ],

            itemsEspecialidadGuianza: [
                'Histórico - Cultural',
                'Arte y museos',
                'Interpretación ambiental',
                'Arquitectónico o religioso',
                'Aventura',
                'Agroturismo',
                'Balsaje',
                'Gastrónomico',
                'Bienestar (sanación, yoga, etc)',
                'Botánica - observación de flora',
                'Senderismo/Caminatas',
                'Avistamiento de Aves',
                'Avistamiento de fauna (diferente a las aves)',
                'Otro',
            ],
            itemsIdiomasRecorridos: [
                'Español',
                'Inglés',
                'Francés',
                'Alemán',
                'Portugués',
                'Italiano',
                'Otro',
            ],

            itemsQuienContrataServiciosGuia: [
                'Agencia de Viajes',
                'Hoteles',
                'OPC (Operadores Profesionales de Congresos)',
                'Empresas de Transporte Terrestre Automotor',
                'Administradores de Atractivos',
                'Agremiaciones',
                'Entidades Distritales',
                'Turistas',
                'Instituciones Académicas',
                'Independiente',
                'Otros',
            ],

            itemsLaboraPrincipalmente: [
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado',
                'Domingo',
            ],


            isMobile: false,

            num: 120,
            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),


            drawerStep: true,
            stepFormServiceOrder: 1,
            stepTitles: [
                'Información de Identificación',
                'Información del Representante Legal',
                'Información Personal y Pertenencia a Grupos Poblacionales',
                'Información sobre Registro y Tarjeta Profesional',
                'Actividad de Guianza y Profesión',
                'Educación y Experiencia Profesional',
                'Especialidad en Guianza Turística',
                'Idiomas en Guianza Turística',
                'Tarifas por Hora de Servicios de Guianza',
                'Contratación y Disponibilidad Laboral',

            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }
        },
    },
    methods: {
        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },

        // toggleActivity(activity) {
        //     const index = this.selectActivities.indexOf(activity);
        //     if (index === -1) {
        //         this.selectActivities.push(activity);
        //     } else {
        //         this.selectActivities.splice(index, 1);
        //     }
        // },

        toggleMunicipiosEjerceActividad(municipio) {
            const index = this.form.municipios_ejerce_actividad.indexOf(municipio);
            if (index === -1) {
                this.form.municipios_ejerce_actividad.push(municipio);
            } else {
                this.form.municipios_ejerce_actividad.splice(index, 1);
            }
        },





        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        }


    },

    mounted() {
        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);



    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 8px;
}


/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
    margin: 0;
}
</style>
