<template>
  <v-container fluid>
    <v-card color="transparent" elevation="0" class="overflow-visible">
      <v-card-text class="px-0">
        <v-container fluid class="px-0">
          <v-row no-gutters>
            <v-col sm="12" md="9" cols="12" class="">
              <v-row>
                <v-col sm="12" md="6" cols="12" class="two-columns-card">
                  <v-card class="column-item">
                    <v-card-text class="d-flex pb-0">
                      <div>
                        <p class="mb-2 font-w">
                          Usuarios <br />
                          Registrados
                        </p>
                        <h4 class="mb-0 text-h6 font-weight-bold mb-2">109</h4>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <v-avatar size="45" color="#3fad8833" rounded="2">
                          <v-icon color="primary" size="30">mdi-account-outline</v-icon>
                        </v-avatar>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <div>
                        <v-chip
                          color="primary"
                          size="small"
                          label
                          append-icon="mdi-trending-up"
                          >1.5%
                        </v-chip>

                        <span class="text-muted ms-1">en este mes</span>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card class="column-item">
                    <v-card-text class="d-flex pb-0">
                      <div>
                        <p class="mb-2 font-w">
                          Total Encuestas <br />
                          Registradas
                        </p>
                        <h4 class="mb-0 text-h6 font-weight-bold mb-2">30</h4>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <v-avatar size="45" color="#3f51b533" rounded="2">
                          <v-icon color="indigo" size="30">mdi-folder-open</v-icon>
                        </v-avatar>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <div>
                        <v-chip
                          color="red"
                          size="small"
                          label
                          append-icon="mdi-trending-down"
                          >1.5%
                        </v-chip>

                        <span class="text-muted ms-1">en este mes</span>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card class="column-item">
                    <v-card-text class="d-flex pb-0">
                      <div>
                        <p class="mb-2 font-w">
                          Número de Guías <br />
                          Inscritos
                        </p>
                        <h4 class="mb-0 text-h6 font-weight-bold mb-2">7</h4>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <v-avatar size="45" color="#ff865733" rounded="2">
                          <v-icon color="orange" size="30">mdi-account-multiple</v-icon>
                        </v-avatar>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <div>
                        <v-chip
                          color="red"
                          size="small"
                          label
                          append-icon="mdi-trending-down"
                          >1.5%
                        </v-chip>

                        <span class="text-muted ms-1">en este mes</span>
                      </div>
                    </v-card-text>
                  </v-card>
                  <v-card class="column-item">
                    <v-card-text class="d-flex pb-0">
                      <div>
                        <p class="mb-2 font-w">
                          Atractivos <br />
                          Turisticos
                        </p>
                        <h4 class="mb-0 text-h6 font-weight-bold mb-2">20</h4>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <v-avatar size="45" color="#9c27b033" rounded="2">
                          <v-icon color="purple" size="30"
                            >mdi-book-education-outline</v-icon
                          >
                        </v-avatar>
                      </div>
                    </v-card-text>
                    <v-card-text>
                      <div>
                        <v-chip
                          color="red"
                          size="small"
                          label
                          append-icon="mdi-trending-down"
                          >1.5%
                        </v-chip>

                        <span class="text-muted ms-1">en este mes</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col sm="12" md="6" cols="12">
                  <v-card height="100%">
                    <v-card-title class="text-body-1 font-weight-bold py-3 px-7"
                      >Análisis De Encuestas</v-card-title
                    >
                    <v-divider class="ma-0"></v-divider>
                    <v-card-text>
                      <canvas id="myChart"></canvas>
                    </v-card-text> </v-card
                ></v-col>

                <v-col sm="12" md="6" cols="12">
                  <v-card height="100%">
                    <v-card-title class="text-body-1 font-weight-bold py-3 px-7"
                      >Oferta por Municipio</v-card-title
                    >
                    <v-divider class="ma-0"></v-divider>
                    <v-card-text :class="{ 'px-16': !smAndDown }">
                      <canvas id="myChart3"></canvas>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col sm="12" md="6" cols="12">
                  <v-card style="">
                    <v-card-title class="text-body-1 font-weight-bold py-3 px-7"
                      >Atractivos Turísticos</v-card-title
                    >
                    <v-divider class="ma-0"></v-divider>
                    <v-card-text :class="{ 'px-16': !smAndDown }">
                      <canvas id="myChart2"></canvas>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="3" cols="12" class="overflow-visible">
              <v-row>
                <v-col>
                  <v-card class="overflow-visible card-profile" :class="{'mt-12' : smAndDown}">
                    <div class="d-flex grow flex-wrap">
                      <v-badge
                        style="position: relative; top: -50px; margin-bottom: -32px"
                        class="mx-auto"
                        overlap
                        color="transparent"
                        location="bottom right"
                        offset-x="15"
                        offset-y="15"
                        bottom
                      >
                        <template v-slot:badge>
                          <v-tooltip
                            width="300"
                            text="Aquí puedes cambiar la foto de perfil de tu cuenta."
                          >
                            <template v-slot:activator="">
                              <v-btn
                                variant="flat"
                                color="primary-second"
                                icon="mdi-camera"
                                density="compact"
                                @click="dialogProfile = true"
                              ></v-btn>
                              <!-- <v-icon>mdi-camera</v-icon> -->
                            </template>
                          </v-tooltip>
                        </template>

                        <v-avatar class="mx-auto" size="100" color="primary">
                          <v-img
                            src="https://randomuser.me/api/portraits/men/81.jpg"
                            alt="Profile picture"
                            cover
                            srcset=""
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey-lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <!-- <span v-else class="text-h3">{{ getInitials($root.infoUser.name,
                                                $root.infoUser.last_name) }}</span> -->
                        </v-avatar>
                      </v-badge>
                    </div>
                    <v-card-title class="text-center" style="line-height: 1.2">
                      <span class="font-weight-bold">Empresa JMC S.A.</span>
                      <br />
                      <span class="text-body-2">correo@email.com</span>
                    </v-card-title>

                    <v-card-actions class="text-center d-block py-8">
                      <!-- <v-btn
                        width="120"
                        variant="tonal"
                        color="primary"
                        class="text-capitalize"
                        >Editar Perfil</v-btn
                      > -->
                      <v-btn
                      to="/administrador/perfil"
                        width="120"
                        variant="tonal"
                        color="primary"
                        class="text-capitalize"
                        >Ver Perfil</v-btn
                      >
                    </v-card-actions>
                  </v-card>

                  <v-card class="mt-5">
                    <v-card-title class="d-flex text-body-1 font-weight-bold py-3 px-7">
                      {{ formatDate() }}
                      <!-- - {{ endDate.locale('es').format('D [de] MMMM [de] YYYY') }} -->
                      <v-spacer></v-spacer>
                      <v-btn
                        density="comfortable"
                        icon
                        @click="previousWeek"
                        variant="flat"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn density="comfortable" icon @click="nextWeek" variant="flat">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text class="py-5">
                      <table style="max-width: 600px" class="calendar mx-auto">
                        <thead>
                          <tr>
                            <th
                              v-for="day in daysOfWeek"
                              :key="day.short"
                              :class="{
                                'current-day':
                                  isCurrentDay(day.full) && isInCurrentWeek(),
                              }"
                            >
                              {{ day.short }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="week in weeks" :key="week[0].format('YYYY-MM-DD')">
                            <td
                              v-for="day in week"
                              :key="day.format('YYYY-MM-DD')"
                              :class="{
                                'current-day':
                                  isCurrentDay(day.format('dddd')) && isInCurrentWeek(),
                              }"
                            >
                              {{ day.format("D") }}
                              <span
                                v-if="
                                  isCurrentDay(day.format('dddd')) && isInCurrentWeek()
                                "
                              >
                                <br />
                                •
                              </span>
                              <span v-else>
                                <br />
                                &nbsp;</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import moment from "moment";
import Chart from "chart.js/auto";
import { useDisplay } from "vuetify";

export default {
  name: "DashboardAdmin",
  setup() {
    const { smAndDown } = useDisplay();

    return {
      smAndDown: smAndDown,
    };
  },
  data() {
    return {
      startDate: moment().startOf("week"),
      endDate: moment().endOf("week"),
      daysOfWeek: [
        { full: "Sunday", short: "Dom" },
        { full: "Monday", short: "Lun" },
        { full: "Tuesday", short: "Mar" },
        { full: "Wednesday", short: "Mié" },
        { full: "Thursday", short: "Jue" },
        { full: "Friday", short: "Vie" },
        { full: "Saturday", short: "Sáb" },
      ],
      monthsInSpanish: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  computed: {
    weeks() {
      const weeks = [];
      let currentDay = this.startDate.clone();

      while (currentDay.isBefore(this.endDate)) {
        const week = [];
        for (let i = 0; i < 7; i++) {
          week.push(currentDay.clone());
          currentDay.add(1, "day");
        }
        weeks.push(week);
      }

      return weeks;
    },
  },

  mounted() {
    const ctx1 = document.getElementById("myChart");

    const data1 = {
      labels: [
        "Ago-2023",
        "Sep-2023",
        "Oct-2023",
        "Nov-2023",
        "Dic-2023",
        "Ene-2024",
        "Feb-2024",
      ],
      datasets: [
        {
          type: "bar",
          label: "Opción 1",
          data: [10, 20, 30, 40, 20, 38],
          borderColor: "#00A551",
          backgroundColor: "#00A551",
          borderRadius: 6,
        },
        {
          type: "bar",
          label: "Opción 2",
          data: [50, 10, 25, 4, 18, 60],
          borderColor: "#00679F",
          backgroundColor: "#00679F",
          borderRadius: 6,
        },
        {
          type: "bar",
          label: "Opción 3",
          data: [2, 1, 0, 5, 15, 20],
          borderColor: "#FF5856",
          backgroundColor: "#FF5856",
          borderRadius: 6,
        },
      ],
    };

    new Chart(ctx1, {
      type: "scatter",
      data: data1,
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    const ctx2 = document.getElementById("myChart2");

    const data2 = {
      labels: [
        "Ago-2023",
        "Sep-2023",
        "Oct-2023",
        "Nov-2023",
        "Dic-2023",
        "Ene-2024",
        "Feb-2024",
      ],
      datasets: [
        {
          label: "Opción 1",
          data: [10, 20, 30, 40, 20, 38],
          borderColor: "#00A551",
          backgroundColor: "rgb(0 165 81 / 50%)",
        },
        {
          label: "Opción 2",
          data: [50, 10, 25, 4, 18, 60],
          borderColor: "#00679F",
          backgroundColor: "rgb(0 103 159 / 50%)",
        },
        {
          label: "Opción 3",
          data: [2, 1, 0, 5, 15, 20],
          borderColor: "#FF5856",
          backgroundColor: "rgb(255 88 86 / 50%)",
        },
      ],
    };

    new Chart(ctx2, {
      type: "radar",
      data: data2,
      options: {
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: "Chart.js Radar Chart",
          },
        },
      },
    });

    const ctx3 = document.getElementById("myChart3");

    const data3 = {
      labels: ["San José Del Guaviare", "El Retorno", "Calamar", "Miraflores"],
      datasets: [
        {
          label: "Dataset 1",
          data: [5, 10, 15, 20],
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
          ],
        },
      ],
    };

    new Chart(ctx3, {
      type: "pie",
      data: data3,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    });
  },

  methods: {
    formatDate() {
      const month = this.startDate.month();
      const year = this.startDate.year();
      return `${this.monthsInSpanish[month]} ${year}`;
    },
    previousWeek() {
      this.startDate = this.startDate.clone().subtract(1, "week");
      this.endDate = this.endDate.clone().subtract(1, "week");
    },
    nextWeek() {
      this.startDate = this.startDate.clone().add(1, "week");
      this.endDate = this.endDate.clone().add(1, "week");
    },
    isCurrentDay(day) {
      return day.toLowerCase() === moment().format("dddd").toLowerCase();
    },
    isInCurrentWeek() {
      const today = moment();
      return today.isBetween(this.startDate, this.endDate, null, "[]");
    },
  },
};
</script>

<style>
table.calendar {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.calendar th,
.calendar td {
  text-align: center;
  /* Borde izquierdo entre días */
  padding: 8px;
}

th.current-day,
td.current-day {
  border: none !important;
}

th.current-day {
  border-radius: 15px 15px 0 0;
}

td.current-day {
  border-radius: 0 0 15px 15px;
}

.current-day {
  background-color: #00a551;
  color: #fff;
  font-weight: bold;
  /* border: 2px solid #4caf50; */
  /* Bordes más destacados para el día actual */
}

.two-columns-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual ancho */
  gap: 20px; /* Espacio igual entre todos los elementos */
  grid-auto-rows: minmax(135px, auto);
}

.column-item {
  padding: 5px;
  box-sizing: border-box; /* Incluye padding y border en el tamaño total del elemento */
}

/* cuando el tamaño de la pagina sea menor a 600px two-columns-card cambia */
@media (max-width: 600px) {
  .two-columns-card {
    grid-template-columns: 1fr; /* Una sola columna */
  }
}
</style>
