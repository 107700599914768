// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const customeTheme = {
  dark: false,
  colors: {
    primary: "#00679F",
    "primary-second": "#f3faff",
    "red-app": "#FF5856",
    "green-app-2": "#72BF44",
    "grey-app-2": "#E6E7E8",
    "yellow-app-2": "#FEBF0F",
    secondary: "#00A551",
    accent: "#82B1FF",
    error: "#FA5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFAB00",
    lightblue: "#14c6FF",
    yellow: "#FFAB00",
    pink: "#FF1976",
    orange: "#FF8657",
    magenta: "#C33AFC",
    darkblue: "#1E2D56",
    gray: "#909090",
    neutralgray: "#9BA6C1",
    green: "#069169",
    red: "#A80521",
    darkblueshade: "#308DC2",
    lightgray: "#BDBDBD",
    lightpink: "#FFCFE3",
    white: "#FFFFFF",
    muted: "#6c757d",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "customeTheme",
    themes: {
      customeTheme,
    },
  },
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
})
