<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation1 v-if="isMdOrLower" title="Formulario Percepción Turista Atractivo"
            subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible.
        Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento.">
        </NavBarAnimation1>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{
                                item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-primary pa-0"
                style="position: sticky; ">

                <LeftPanelAnimation1 title="Formulario Percepción Turista"
                    subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible.
                Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
                    :wordsAnimate="['Atractivo']"></LeftPanelAnimation1>

            </v-col>
            <v-col lg="7" xl="8" cols="12" style="    background: #fff; z-index: 9;">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div>
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>



                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">
                                <v-window-item :value="1">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Nombre del atractivo turistico</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select color="primary" variant="underlined"
                                                    :items="sitesTuristics"></v-select>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Tipo de documento de
                                                    identificación</span> <span class="font-weight-bold">*</span>
                                                <v-select color="primary" :items="typesDocs"
                                                    variant="underlined"></v-select>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">Número de documento</span> <span
                                                    class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="2">
                                    <v-card-text class="text-body-2">


                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es su nivel de percepción de las
                                                    siguientes
                                                    características del atractivo, teniendo en cuenta que 5 <v-icon
                                                        color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1 "Muy
                                                    insatisfecho"?</span>
                                            </v-col>

                                            <v-row class="py-0 ma-0">
                                                <template v-for="(item, index) in ratings" :key="index">
                                                    <v-col md="6" lg="6" sm="12" cols="12"
                                                        class="py-1 d-flex align-center">
                                                        <span class="">{{ item.label }}</span>
                                                    </v-col>
                                                    <v-col md="6" lg="6" sm="12" cols="12"
                                                        class="py-1 d-flex align-center justify-center">
                                                        <v-rating v-model="item.value" active-color="orange-lighten-1"
                                                            color="orange-lighten-1"></v-rating>
                                                    </v-col>
                                                </template>
                                            </v-row>

                                        </v-row>


                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="3">

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Usted observó en el atractivo visitado
                                                    alguna de
                                                    las siguientes practicas de sostenibilidad ambiental?.
                                                </span>
                                                <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="selectPractAmbiental" :items="itemsPracticesSustainability"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ selectPractAmbiental.length - 2 }} {{
                                                                selectPractAmbiental.length - 2 == 1 ? 'opción' : 'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="selectPractAmbiental"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsPracticesSustainability.length"></v-divider>
                                                    </template>

                                                </v-select>


                                                <!-- <v-list lines="one" select-strategy="classic" class="overflow-visible ">
                                                    <v-list-item class="rounded-lg ma-1" @click="togglePractAmbiental(item)"
                                                        v-for="(item, index) in itemsPracticesSustainability" :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': selectPractAmbiental.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                :rules="[validations.required]"
                                                                    :model-value="isActive"
                                                                    v-model="selectPractAmbiental"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title class="text-wrap">{{ item }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list> -->
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="4">

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Usted observó en el atractivo visitado
                                                    alguna de
                                                    las siguientes practicas de sostenibilidad social?. .
                                                </span>
                                                <span class="font-weight-bold">*</span>

                                                <v-list lines="one" select-strategy="classic" class="overflow-visible ">
                                                    <v-list-item class="rounded-lg ma-1"
                                                        @click="togglePractSocial(item)"
                                                        v-for="(item, index) in itemsPracticesSustainabilitySocial"
                                                        :key="index"
                                                        :class="{ 'animate__animated animate__headShake bg-primary-second': selectPractSocial.includes(item) }">
                                                        <template v-slot:append="{ isActive }">


                                                            <v-list-item-action start>
                                                                <v-checkbox-btn color="primary" :true-value="item"
                                                                    :rules="[validations.required]"
                                                                    :model-value="isActive"
                                                                    v-model="selectPractSocial"></v-checkbox-btn>
                                                            </v-list-item-action>
                                                        </template>

                                                        <v-list-item-title class="text-wrap text-body-2">{{ item
                                                            }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="5">

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Durante la visita al atractivo uso
                                                    servicio de
                                                    guianza turistica o interprete local?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group v-model="guianzaTuristica" class="mt-2">
                                                    <v-radio color="primary" label="Sí" value="si"
                                                        class="text-caption-font"></v-radio>
                                                    <v-radio color="primary" label="No" value="no"
                                                        class="text-caption-font"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="6">

                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es su nivel de percepción de las
                                                    siguientes
                                                    caracteristicas del guia o intérprete local, teniendo en cuenta que
                                                    5
                                                    <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                                                    "Muy
                                                    insatisfecho"? </span>
                                            </v-col>


                                            <v-row>
                                                <template v-for="(item, index) in ratings_guianza_turistica"
                                                    :key="index">
                                                    <v-col md="6" cols="12" class="py-1 d-flex align-center">
                                                        <span class="">{{ item.label }}</span>
                                                    </v-col>
                                                    <v-col md="6" cols="12"
                                                        class="py-1 d-flex align-center justify-center">
                                                        <v-rating v-model="item.value" active-color="orange-lighten-1"
                                                            color="orange-lighten-1"></v-rating>
                                                    </v-col>


                                                </template>
                                            </v-row>
                                        </v-row>
                                    </v-card-text>
                                </v-window-item>

                                <v-window-item :value="7">

                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Realizo la compra de comidas preparadas
                                                    al
                                                    interior del atractivo?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group v-model="shopingFood" class="mt-2">
                                                    <v-radio color="primary" label="Sí" value="si"
                                                        class="text-caption-font"></v-radio>
                                                    <v-radio color="primary" label="No" value="no"
                                                        class="text-caption-font"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-window-item>

                                <v-window-item :value="8">

                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-bold">¿Cuál es su nivel de percepción de las
                                                    siguientes
                                                    caracteristicas respecto a la comida adquirida, teniendo en cuenta
                                                    que 5
                                                    <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                                                    "Muy
                                                    insatisfecho"? </span>
                                            </v-col>
                                            <v-row>
                                                <template v-for="(item, index) in rating_shop_food" :key="index">
                                                    <v-col md="6" cols="12" class="py-1 d-flex align-center">
                                                        <span class="">{{ item.label }}</span>
                                                    </v-col>
                                                    <v-col md="6" cols="12" class="py-1 d-flex align-center justify-center">
                                                        <v-rating v-model="item.value" active-color="orange-lighten-1"
                                                            color="orange-lighten-1"></v-rating>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </v-row>
                                    </v-card-text>

                                </v-window-item>


                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->

                </v-row>




            </v-col>
        </v-row>

        <UserExperience></UserExperience>

    </v-container>
    <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation1 from './includes/LeftPanelAnimation1.vue';
import NavBarAnimation1 from './includes/NavBarAnimation1.vue';
import UserExperience from './includes/UserExperience.vue';



export default {
    name: 'FormEmployability',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation1,
        NavBarAnimation1,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()

        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            showTopShadow: false,
            showBottomShadow: true,
            ratings: [
                { label: 'Limpieza y aseo del atractivo', value: 0 },
                { label: 'Conservación de los lugares visitados', value: 0 },
                { label: 'Hospitalidad', value: 0 },
                { label: 'Facilidad para comprar las entradas', value: 0 },
                { label: 'Facilidad para llegar a los atractivos', value: 0 },
                { label: 'Seguridad en el desplazamiento hacia el atractivo', value: 0 },
                { label: 'Relación calidad precio', value: 0 },
                { label: 'Estado de las vías de acceso', value: 0 },
                { label: 'Elementos de Seguridad (guía con botiquín, suministro de cascos, etc)', value: 0 },
                { label: 'Seguridad al interior del atractivo (presencia de individuos o situaciones que puedan afectar la actividad)', value: 0 }
            ],
            ratings_guianza_turistica: [
                { label: 'Presentación personal e higiene', value: 0 },
                { label: 'Vocabulario utilizado y habilidad para expresar las ideas', value: 0 },
                { label: 'Conocimiento y calidad de la información suministrada', value: 0 },
                { label: 'Respuesta a preguntas o dudas', value: 0 }
            ],
            rating_shop_food: [
                { label: 'Variedad del Menú', value: 0 },
                { label: 'Menú consumido', value: 0 },
                { label: 'Tiempo de atención', value: 0 },
                { label: 'Aseo e higiene de las instalaciones gastronómicas', value: 0 },
                { label: 'Relación calidad precio', value: 0 },
                { label: 'Trato del personal del restaurante', value: 0 }
            ],
            guianzaTuristica: 0,
            shopingFood: 0,

            typesDocs: [
                'Cédula de ciudadanía',
                'Cédula de extranjería',
                'Pasaporte',
                'Tarjeta de identidad',
                'Registro civil',
                'Ninguno'
            ],

            rowsTable1: [
                { label: 'Contrato laboral con la empresa' },
                { label: 'Prestación de servicios (por más de un mes)' },
                { label: 'Contrato de aprendizaje' },
                { label: 'Pasante' },
                { label: 'Indirectos (Menor a un mes/por temporada/Evento)' },
                { label: 'Por demanda (Transporte, servicios de limpieza, técnico, etc.)' },
                { label: 'Por demanda (Únicamente guianza)' },
                { label: 'Total' }
            ],
            rowsTable2: [
                { label: 'Contrato laboral con la empresa' },
                { label: 'Prestación de servicios (por más de un mes)' },
                { label: 'Contrato de aprendizaje' },
                { label: 'Pasante' },
                { label: 'Indirectos (Menor a un mes/por temporada/Evento)' },
                { label: 'Por demanda (Transporte, servicios de limpieza, técnico, etc.)' },
                { label: 'Por demanda (Únicamente guianza)' },
            ],
            rowsTable3: [
                { label: 'Contrato laboral con la empresa' },
                { label: 'Prestación de servicios' },
                { label: 'Contrato de aprendizaje' },
                { label: 'Pasante' },
            ],

            rowsTable4: [
                { label: 'Indirectos (Menor a un mes/por temporada/Evento)' },
                { label: 'Por demanda (Transporte, servicio técnico, etc.)' },
                { label: 'Por demanda (Guianza)' },
            ],

            rowsEmpleados1: [
                { label: 'Sin estudio' },
                { label: 'Primaria incompleta' },
                { label: 'Primaria completa' },
                { label: 'Bachillerato incompleto' },
                { label: 'Bachillerato completo' },
                { label: 'Técnico/Tecnológo sin titulo' },
            ],
            rowsEmpleados2: [
                { label: 'Técnico/Tecnológo con titulo' },
                { label: 'Profesional sin titulo' },
                { label: 'Profesional con titulo' },
                { label: 'Profesional con postgrado sin titulo' },
                { label: 'Profesional con postgrado con titulo' },
            ],

            rowNumeroEmpleadosTemporadas: [
                { label: 'Temporada baja (febrero a mayo y agosto a octubre)' },
                { label: 'Vacaciones Mitad de año' },
                { label: 'Temporada de vacaciones de fin de año (noviembre, diciembre, enero)' },
                { label: 'Semana Santa' },
                { label: 'Semana de receso estudiantil (octubre)' },
                { label: 'Ferias y fiestas (noviembre, diciembre, enero)' },
            ],

            sitesTuristics: [
                'Charco Indio',
                'Ciudad de Piedra',
                'Finca Agroecoturística El Paraido',
                'Finca Ecoturística Trankilandia',
                'Laberintos Naturales',
                'Laguna Damas del Nare',
                'Laguna Negra',
                'Manatú',
                'Pinturas Rupestres de Cerro Azul',
                'Pinturas Rupestres de Nuevo Tolima',
                'Pinturas Rupestres del Raudal del Guayabero',
                'Pozos Naturales',
                'Puentes Naturales',
                'Puerta de Orión',
                'Túneles Naturales',
            ],








            isMobile: false,

            num: 120,
            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

            itemsPracticesSustainability: [
                'Gestión sobre residuos sólidos (no uso de plástico, reciclaje, compostaje, etc.) ',
                'Gestión sobre ahorro y uso eficiente de agua (sanitarios ahorradores, grifos de cierre automático, aprovechamiento de aguas lluvias, etc.) ',
                'Gestión sobre ahorro y uso eficiente de energía (paneles solares, bombillos ahorradores, aprovechamiento de luz natural, etc.) ',
                'Gestión sobre manejo de aguas residuales y vertimientos (uso de jabones biodegradables, baños secos, pozo séptico, etc.) ',
                'Gestión sobre manejo y cuidado de la biodiversidad (restauración y siembra de árboles, no se interfiere con el hábitat de la especie observada, etc.) ',
                'Gestión sobre manejo de gases efecto invernadero (GEI) (cálculo de huella de carbono, oferta de productos locales, menú vegetariano, etc.)',
                'Campañas de sensibilización y/o capacitación sobre el cuidado del medio ambiente',
                'Certificaciones, aval, o reconocimiento de buenas prácticas ambientales alcanzadas',
                'Ninguno',
            ],
            itemsPracticesSustainabilitySocial: [
                'Involucramiento de las comunidades turísticas en su experiencia turística',
                'No explotación infantil',
                'Pago de tarifas justas por los servicios o productos recibidos',
                'Uso de productos locales',
                'Respeto por la cultura y/o costumbres locales',
            ],
            selectPractAmbiental: [],
            selectPractSocial: [],
            drawerStep: true,
            stepFormServiceOrder: 1,
            marketplace: '',
            stepTitles: [
                'Información del sitio turístico',
                'Nivel de percepción del sitio turístico',
                'Practicas de sostenibilidad ambiental',
                'Practicas de sostenibilidad social',
                'Servicio de guianza turística',
                'Nivel de percepción de la guianza turística',
                'Compra de comidas en el sitio turístico',
                'Nivel de percepción de comidas'
            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }
        },
    },
    methods: {
        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },

        togglePractAmbiental(activity) {
            const index = this.selectPractAmbiental.indexOf(activity);
            if (index === -1) {
                this.selectPractAmbiental.push(activity);
            } else {
                this.selectPractAmbiental.splice(index, 1);
            }
        },

        togglePractSocial(activity) {
            const index = this.selectPractSocial.indexOf(activity);
            if (index === -1) {
                this.selectPractSocial.push(activity);
            } else {
                this.selectPractSocial.splice(index, 1);
            }
        },













        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        }


    },

    mounted() {
        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);



    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 8px;
}


/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
    margin: 0;
}
</style>
