<template>

    <v-app-bar scroll-behavior="fully-hide" scroll-threshold="350" color="secondary" elevation="1">
        <div class="hero"> 
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
  <div class="cube"></div>
</div>
        <v-app-bar-title class="text-wrap text-h6 font-weight-bold">

           

            {{ title }}

        </v-app-bar-title>

        <template v-slot:append>
            <v-btn icon size="small">
                <v-icon>mdi-information</v-icon>
            </v-btn>
        </template>
    </v-app-bar>

</template>

<script>

export default {
    name: 'NavBarAnimation3',
    props: {
        title: {
            type: String,
            required: true
        },
    },

}
</script>