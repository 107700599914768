<template>

    <v-dialog v-if="dialogResponse" v-model="dialogResponse.show" max-width="600" persistent>
        <v-card rounded="xl" class="animate__animated animate__bounceIn">
            <v-card-title class="d-flex pa-2">
                <v-spacer></v-spacer>
                <v-btn class="ma-2" @click="cancel()" size="x-small" icon variant="tonal" color="primary">
                    <v-icon size="25">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="headline text-center px-16 pt-0">
                <v-icon size="90" :color="dialogResponse.color" class="mb-3">mdi-check-circle-outline</v-icon>
                <h3 class="text-h5 font-weight-bold mb-5" :class="'text-' + dialogResponse.color"
                    style="line-height: 1.2;"><span v-html="dialogResponse.title"></span></h3>
                <p class="text-body-1 font-weight-medium" > <span v-html="dialogResponse.message"></span>  </p>
            </v-card-text>

            <v-card-actions class="text-center pb-7 d-block">
                <v-btn width="180" :to="dialogResponse.redirect" class="ma-1 text-capitalize  font-weight-bold" :color="dialogResponse.color"
                    rounded="lg" variant="flat">
                    Entendido
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "DialogResponse",
    props: {
        dialog: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialogResponse: null,
        }
    },
    watch: {

        dialog: {
            immediate: true,
            handler(newValue) {
                this.dialogResponse = {
                    title: newValue.title,
                    message: newValue.message,
                    type: newValue.type,
                    color: newValue.color,
                    icon: newValue.icon,
                    show: newValue.show,
                    redirect: newValue.redirect,
                }
            },
            deep: true,
        },

        
    },
    methods: {
        cancel() {
            this.dialogResponse.show = false;
            this.$emit('cancel');
        },
    }
}
</script>