<template>
    <v-row class="fill-height">
        <v-col cols="12" md="12">

            <div class="d-flex pt-3">
                <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
                <v-spacer></v-spacer>
            </div>

            <div class="d-flex justify-center align-center mb-16 pb-6" style="height: 100%;">

                <v-form ref="formlogin">
                    <v-card elevation="0" max-width="410" class="mx-auto">
                        <!-- <v-img height="120" :src="require('@/assets/img/logo-guaviare-large.png')"></v-img> -->
                        <v-card-title class="">
                            <h4 class="text-left text-h4 font-weight-bold"
                                style="white-space: normal;word-break: auto-phrase;">
                                Administrador encuestas de turismo</h4>
                        </v-card-title>
                        <v-card-subtitle class="text-wrap pb-9">
                            Ingresa tu correo electrónico y contraseña para continuar.
                        </v-card-subtitle>
                        <v-card-text>

                            <v-responsive class="mx-auto mb-2">
                                <span class="font-weight-medium">
                                    Correo electrónico
                                </span>
                                <v-text-field :rules="[validations.required, validations.emailFormat]" rounded="lg"
                                    color="secondary" @keydown.enter="login" required name="email"
                                    autocomplete="new-password" variant="outlined" density="compact" type="input"
                                    v-model="email"></v-text-field>
                            </v-responsive>
                            <v-responsive class="mx-auto">
                                <span class="font-weight-medium">
                                    Contraseña
                                </span>
                                <v-text-field :rules="[validations.required]" rounded="lg" color="secondary"
                                    @keydown.enter="login" required autocomplete="new-password" v-model="password"
                                    variant="outlined" density="compact" type="password"></v-text-field>
                            </v-responsive>
                            <div class="d-flex">
                                <!-- <v-checkbox v-model="remerberMe" color="secondary" hide-details
                                        label="Recuérdame"></v-checkbox> -->
                                <v-spacer></v-spacer>
                                <router-link
                                    class="text-decoration-none text-primary font-weight-medium d-flex align-self-center"
                                    to="/recuperar-cotrasena">¿Olvidó su
                                    contraseña?</router-link>
                            </div>
                            <v-responsive class="mx-auto mt-5 pt-5">

                                <v-hover>
                                    <template v-slot:default="{ isHovering, props }">
                                        <v-btn v-bind="props" :disabled="loadingForm" rounded="lg"
                                            :loading="loadingForm" @click="login" color="secondary" block
                                            :variant="isHovering ? 'outlined' : 'flat'" class="text-capitalize">
                                            <span v-if="isHovering"
                                                :class="{ 'animate__animated  animate__slideInLeft': isHovering }">
                                                Ingresar Ahora </span>
                                            <span class="animate__animated animate__slideInRight"
                                                :class="{ 'd-none': isHovering }"> Ingresar Ahora </span>
                                            <div class="area">
                                                <ul class="circles">
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                    <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                                                </ul>
                                            </div>
                                        </v-btn>
                                    </template>
                                </v-hover>

                            </v-responsive>
                            <!-- <div class="mx-auto text-subtitle text-grey text-center py-5 or-login-text">
                                        O ingresa con
                                    </div> -->
                            <v-responsive class="mx-auto py-5 mt-4 text-center">
                                <span> ¿No tienes una cuenta?

                                    <router-link to="/registro"
                                        class="text-decoration-none text-primary font-weight-medium">Registrate</router-link>
                                </span>
                            </v-responsive>


                        </v-card-text>
                    </v-card>
                </v-form>

            </div>



        </v-col>



    </v-row>

</template>

<script>

import validationFields from './functions/validationFields';

export default {
    mixins: [validationFields],
    name: "LoginView",
    setup() {
        // Get toast interface
    },
    data() {
        return {
            email: "",
            password: "",
            remerberMe: false,
            loadingForm: false,
        };
    },
    methods: {
        async login() {

            //validar formulario formlogin
            const { valid } = await this.$refs.formlogin.validate()

            if (valid) {

                this.loadingForm = true;
                setTimeout(() => {
                    this.$router.push('/administrador');
                }, 2000);

            }

        },
    },

}
</script>

<style>
.slider-login {
    height: 100%;
    /*background-image: url('../assets/img/forest-animals-nature-branch-snake-green-14903-wallhere.com.jpg');*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 0;
}

.slider-login::before {
    content: '';
    position: absolute;
    z-index: 2;
    border-left: 130px solid #ffffff;
    border-top: 20vh solid transparent;
    border-bottom: 80vh solid transparent
}

.app-carousel::before {
    background-color: rgb(0 165 81 / 18%);
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
}

.or-login-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}


.or-login-text::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: #B0BEC5;
    /* Ajusta el color de la línea según sea necesario */
    margin: 0 10px;
    /* Ajusta el margen según sea necesario */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.or-login-text::before {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: #B0BEC5;
    /* Ajusta el color de la línea según sea necesario */
    margin: 0 10px;
    /* Ajusta el margen según sea necesario */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
</style>