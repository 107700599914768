<template>

    <div class="floating-buttons-wrapper" ref="floatingWrapper">
        <div class="drag-handle" ref="dragHandle">
            <v-btn class="ma-0 pa-0 mb-2" size="x-small" icon variant="flat">
                <v-icon size="25" color="primary">mdi-drag</v-icon>
            </v-btn>
        </div>
        <div class="floating-buttons">
            <v-btn v-for="action in actions" :key="action.icon" :icon="action.icon" @click="action.method"
                class="fab rounded-lg rounded-te-0 rounded-e-0 mb-1" color="secondary" size="x-small">
                <v-icon>{{ action.icon }}</v-icon>
            </v-btn>
        </div>
    </div>

</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'UserExperience',
    setup() {
        const fontSizes = ref({
            '--global-font-size-btn-x-small': 10,
            '--global-font-size-caption': 12,
            '--global-font-size-button': 14,
            '--global-font-size-body-2': 14,
            '--global-font-size-body-1': 16,
            '--global-font-size-subtitle-2': 14,
            '--global-font-size-subtitle-1': 16,
            '--global-font-size-h6': 20,
            '--global-font-size-h5': 24,
            '--global-font-size-h4': 32,
            '--global-font-size-h3': 48,
            '--global-font-size-h2': 60,
            '--global-font-size-h1': 96,
        })

        const updateFontSize = () => {
            for (const key in fontSizes.value) {
                document.documentElement.style.setProperty(key, `${fontSizes.value[key]}px`)
            }
        }

        const increaseFontSize = () => {
            for (const key in fontSizes.value) {
                fontSizes.value[key] += 1
            }
            updateFontSize()
        }

        const decreaseFontSize = () => {
            for (const key in fontSizes.value) {
                if (fontSizes.value[key] > 1) {
                    fontSizes.value[key] -= 1
                }
            }
            updateFontSize()
        }

        const setupDrag = () => {
            const wrapper = document.querySelector('.floating-buttons-wrapper');
            const handle = document.querySelector('.drag-handle');

            let shiftY = 0;

            const moveAt = (pageY) => {
                const windowHeight = window.innerHeight;
                const wrapperHeight = wrapper.offsetHeight;

                // Calcula la nueva posición en píxeles
                let newY = Math.min(
                    Math.max(pageY - shiftY, 0),
                    windowHeight - wrapperHeight
                );

                // Ajusta la posición en porcentaje
                wrapper.style.top = `${(newY / windowHeight) * 100}%`;
            };

            const onMove = (event) => {
                let pageY;

                if (event.touches && event.touches.length > 0) {
                    pageY = event.touches[0].clientY;
                } else if (event.clientY !== undefined) {
                    pageY = event.clientY;
                } else {
                    console.error("No se pudo obtener la coordenada Y");
                    return;
                }

                moveAt(pageY);
            };

            const onEnd = () => {
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onEnd);
                document.removeEventListener('touchmove', onMove);
                document.removeEventListener('touchend', onEnd);
            };

            handle.onmousedown = (event) => {
                event.preventDefault();

                shiftY = event.clientY - wrapper.getBoundingClientRect().top;

                document.addEventListener('mousemove', onMove);
                document.addEventListener('mouseup', onEnd);
            };

            handle.ontouchstart = (event) => {
                event.preventDefault();

                const touch = event.touches[0];
                shiftY = touch.clientY - wrapper.getBoundingClientRect().top;

                document.addEventListener('touchmove', onMove);
                document.addEventListener('touchend', onEnd);
            };

            handle.ondragstart = () => false;
        };
        onMounted(() => {
            updateFontSize()
            setupDrag()
        })

        const actions = [
            {
                icon: 'mdi-format-font-size-increase',
                method: increaseFontSize,

            },
            {
                icon: 'mdi-format-font-size-decrease',
                method: decreaseFontSize,

            },
        ]

        return {
            actions
        };
    },
}
</script>

<style>
.fab {
    color: white;
    cursor: pointer;
}

.floating-buttons-wrapper {
    position: fixed;
    right: 0;
    bottom: 20px;
    z-index: 1010;
    display: flex;
    flex-direction: column;
}

.floating-buttons {
    display: flex;
    flex-direction: column;
}

.drag-handle {

    cursor: move;
    z-index: 1001;
}


:root {
    --global-font-size-btn-x-small: 10px;
    --global-font-size-caption: 12px;
    --global-font-size-button: 14px;
    --global-font-size-body-2: 14px;
    --global-font-size-body-1: 16px;
    --global-font-size-subtitle-2: 14px;
    --global-font-size-subtitle-1: 16px;
    --global-font-size-h6: 20px;
    --global-font-size-h5: 24px;
    --global-font-size-h4: 34px;
    --global-font-size-h3: 48px;
    --global-font-size-h2: 60px;
    --global-font-size-h1: 96px;
}

.v-btn--size-x-small:not(.floating-buttons .v-btn--size-x-small) .v-btn__content {
    font-size: var(--global-font-size-btn-x-small) !important;
}

span .text-caption,
p .text-caption,
div .text-caption,
.v-list-item__title .text-caption,
.v-list-item__subtitle .text-caption {
    font-size: var(--global-font-size-caption) !important;
}

.v-list-item.text-caption-font .v-list-item-title{
    font-size: var(--global-font-size-body-2) !important;
    text-wrap: wrap;
}

.text-caption-font .v-label{
    font-size: var(--global-font-size-body-2) !important;
}

.text-button {
    font-size: var(--global-font-size-button) !important;
}

.v-btn--size-default .v-btn__content {
    font-size: var(--global-font-size-button) !important;
}

span .text-body-2,
p .text-body-2,
div .text-body-2,
.v-list-item__title .text-body-2,
.v-list-item__subtitle .text-body-2 {
    font-size: var(--global-font-size-body-2) !important;
}

span .v-card-text,
p .v-card-text,
div .v-card-text {
    font-size: var(--global-font-size-body-2) !important;
}

span .text-body-1,
p .text-body-1,
div .text-body-1,
.v-list-item__title .text-body-1,
.v-list-item__subtitle .text-body-1 {
    font-size: var(--global-font-size-body-1) !important;
}

span .text-subtitle-2,
p .text-subtitle-2,
div .text-subtitle-2,
.v-list-item__title .text-subtitle-2,
.v-list-item__subtitle .text-subtitle-2 {
    font-size: var(--global-font-size-subtitle-2) !important;
}

span .v-card-subtitle,
p .v-card-subtitle,
div .v-card-subtitle {
    font-size: var(--global-font-size-subtitle-2) !important;
}

.v-table td,
.v-table th {
    font-size: var(--global-font-size-subtitle-2) !important;
}

.text-subtitle-1,
.v-list-item-title {
    font-size: var(--global-font-size-subtitle-1) !important;
}

span .text-h6,
p .text-h6,
div .text-h6,
.v-list-item__title .text-h6,
.v-list-item__subtitle .text-h6 {
    font-size: var(--global-font-size-h6) !important;
}

span .v-card-title,
p .v-card-title,
div .v-card-title {
    font-size: var(--global-font-size-h6) !important;
}

span .text-h5,
p .text-h5,
div .text-h5,
.v-list-item__title .text-h5,
.v-list-item__subtitle .text-h5 {
    font-size: var(--global-font-size-h5) !important;
}

span .text-h4,
p .text-h4,
div .text-h4,
.v-list-item__title .text-h4,
.v-list-item__subtitle .text-h4 {
    font-size: var(--global-font-size-h4) !important;
}

span .text-h3,
p .text-h3,
div .text-h3,
.v-list-item__title .text-h3,
.v-list-item__subtitle .text-h3 {
    font-size: var(--global-font-size-h3) !important;
}

span .text-h2,
p .text-h2,
div .text-h2,
.v-list-item__title .text-h2,
.v-list-item__subtitle .text-h2 {
    font-size: var(--global-font-size-h2) !important;
}

span .text-h1,
p .text-h1,
div .text-h1,
.v-list-item__title .text-h1,
.v-list-item__subtitle .text-h1 {
    font-size: var(--global-font-size-h1) !important;
}

/* Estilos para el indicador */
</style>