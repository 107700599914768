window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true;

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import router from './router/index';
import store from './store/index';
import 'animate.css';
import anime from 'animejs/lib/anime.es.js';
// import './assets/global.css'; // Importa tus estilos globales
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

const app = createApp(App);

// Define la función para convertir texto a camelCase
function toCamelCase(text) {
  if (!text) {
    return '';
  }
  return text.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

//v-uppercase-app
app.directive('uppercaseApp', {
  mounted(el) {
    let cursorPosition = 0;

    el.addEventListener('input', function (event) {
      const value = event.target.value;
      cursorPosition = event.target.selectionStart;

      const newValue = toCamelCase(value).toUpperCase();

      // Guarda el valor actual del campo de texto antes de actualizarlo
      const oldValue = event.target.value;

      // Actualiza el valor del campo de texto
      event.target.value = newValue;

      // Restaura la posición del cursor
      event.target.setSelectionRange(cursorPosition, cursorPosition);

      // Dispara el evento de entrada solo si el valor ha cambiado
      if (newValue !== oldValue) {
        event.target.dispatchEvent(new Event('input'));
      }
    });
  },
});
//v-capitalize-letter-app
app.directive('capitalizeLetterApp', {
  mounted(el) {
    let cursorPosition = 0;

    el.addEventListener('input', function (event) {
      const value = event.target.value;
      cursorPosition = event.target.selectionStart;

      // Capitaliza la primera letra de cada palabra y convierte el resto a minúsculas
      const newValue = value.toLowerCase().replace(/(^|[\s\xA0])[^\s\xA0]?/g, function (match) {
        return match.toUpperCase();
      });

      // Guarda el valor actual del campo de texto antes de actualizarlo
      const oldValue = event.target.value;

      // Actualiza el valor del campo de texto
      event.target.value = newValue;

      // Restaura la posición del cursor
      event.target.setSelectionRange(cursorPosition, cursorPosition);

      // Dispara el evento de entrada solo si el valor ha cambiado
      if (newValue !== oldValue) {
        event.target.dispatchEvent(new Event('input'));
      }
    });
  },
});

//v-numbers-only-app
app.directive('numbersOnlyApp', {
  beforeMount: (el) => {
    el.addEventListener('keypress', (e) => {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    });
  },
});


  app.component('VueDatePicker', VueDatePicker);
  app.use(anime)
  app.use(router)
  app.use(store)
  app.use(vuetify)
  app.mount('#app')
