<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script>

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import LoginLayout from './layouts/LoginLayout.vue'
import FormsLayout from './layouts/FormsLayout.vue'
import AdminLayout from './layouts/AdminLayout.vue'




export default {
  name: 'App',
  components: {
    LoginLayout,
    FormsLayout,
    AdminLayout
  },
 
  setup() {
    const route = useRoute()

    const layout = computed(() => {
      switch (route.meta.role) {
        case 'superadmin':
           return AdminLayout
        case 'admin':
           return FormsLayout
        case 'login':
           return LoginLayout
        default:
          return null
      }
    })

    return { layout }
  },

  data: () => ({
    //
  }),
}
</script>

<style>
html {
  overflow-y: auto !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

</style>
