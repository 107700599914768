<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation1 v-if="isMdOrLower" title="Formulario Ingreso de Visitantes" subtitle="En este formulario se realiza el registro del (los) 
                    turista (s) que ingresen a los atractivos. 
                    Si se registraron previamente bien sea en el hotel o por la agencia encargada del grupo, solamente debe diligenciar el 
                    tipo y número de documento de cada viajero, en caso contrario, el sistema le pedirá otros datos 
                    adicionales de cada uno."></NavBarAnimation1>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{
                                item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-primary pa-0"
                style="position: fixed; height: 100%; ">

                <LeftPanelAnimation1 title="Formulario Ingreso de" subTitle="En este formulario se realiza el registro del (los) turista (s) que ingresen a los atractivos. 
                    Si se registraron previamente bien sea en el hotel o por la agencia encargada del grupo, solamente debe diligenciar el 
                    tipo y número de documento de cada viajero, en caso contrario, el sistema le pedirá otros datos 
                    adicionales de cada uno." :wordsAnimate="['Visitantes']"></LeftPanelAnimation1>

            </v-col>
            <v-col lg="7" xl="8" cols="12" style="    background: #fff; z-index: 9;" class="ml-auto">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <!-- <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div> -->
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>

                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">
                                <v-window-item :value="1">
                                    <v-card-text class="text-body-2">
                                        <v-row>

                                            <v-col md="12" cols="12" class="py-1" :class="getAnimationClasses(1)"
                                            :style="getAnimationStyle(1)">
                                                <span class="font-weight-bold">Nombre del atractivo turístico</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-select
                                                    :rules="[validations.required, validations.maxLength(50)]"
                                                    color="primary" :items="[
                                                        'Charco Indio',
                                                        'Ciudad de Piedra',
                                                        'Finca Agroecoturística El Paraido',
                                                        'Finca Ecoturística Trankilandia',
                                                        'Laberintos Naturales',
                                                        'Laguna Damas del Nare',
                                                        'Laguna Negra',
                                                        'Manatú',
                                                        'Pinturas Rupestres de Cerro Azul',
                                                        'Pinturas Rupestres de Nuevo Tolima',
                                                        'Pinturas Rupestres del Raudal del Guayabero',
                                                        'Pozos Naturales',
                                                        'Puentes Naturales',
                                                        'Puerta de Orión',
                                                        'Túneles Naturales'
                                                    ]" variant="underlined" placeholder=""></v-select>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" :class="getAnimationClasses(2)"
                                            :style="getAnimationStyle(2)">
                                                <span class="font-weight-bold">Fecha de visita</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-menu :close-on-content-click="false" v-model="menuDateVisit">
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field :rules="[validations.required]" rounded="lg"
                                                            v-model="form.date_visit_format"
                                                            append-inner-icon="mdi-calendar-month" readonly
                                                            v-bind="props" variant="underlined"></v-text-field>
                                                    </template>
                                                    <VueDatePicker class="" v-model="form.date_visit" locale="es"
                                                        :dark="false" :enable-time-picker="false" inline auto-apply />
                                                </v-menu>

                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1" :class="getAnimationClasses(1)"
                                            :style="getAnimationStyle(1)">
                                                <span class="font-weight-bold">¿Indique con quién programó la
                                                    visita?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.programo_visita">
                                                    <v-radio hi v-for="(item, index) in itemsProgramoVisita"
                                                        :key="index" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.programo_visita == item }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item }}
                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group>



                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" :class="getAnimationClasses(2)"
                                            :style="getAnimationStyle(2)">
                                                <span class="font-weight-bold">Nombre del guía asignado</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined"></v-text-field>
                                            </v-col>
                                            <v-col v-if="form.programo_visita == 'Agencia de viajes'" md="12" cols="12"
                                                class="py-1" >
                                                <span class="font-weight-bold">Nombre de la agencia de viajes</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-select
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined"></v-select>
                                            </v-col>





                                        </v-row>
                                    </v-card-text>
                                </v-window-item>



                                <v-window-item :value="3">
                                    <v-card-text class="text-body-2">
                                        <v-row :class="getAnimationClasses(1)"
                                        :style="getAnimationStyle(1)"> 
                                            <v-col md="12" cols="12" class="py-1 mb-3" >
                                                <span class="font-weight-bold">Registre los datos de cada uno de los
                                                    integrantes
                                                    del grupo</span> <span class="font-weight-bold">*</span>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Tipo Documento</span>
                                                <v-select v-model="formIntegrantes.tipo_doc"
                                                    :rules="[validations.required]" color="primary" variant="outlined"
                                                    :items="['Cédula de ciudadania',
                                                        'Cédula de extranjeria',
                                                        'Pasaporte',
                                                        'Permiso de permanencia',
                                                        'Otro']" density="compact"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Número de identificación</span>
                                                <v-text-field v-model="formIntegrantes.numero_identificacion"
                                                    v-numbers-only-app
                                                    :rules="[validations.required, validations.maxLength(255), validations.numeric]"
                                                    color="primary" variant="outlined" density="compact"></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Nombre completo</span>
                                                <v-text-field v-model="formIntegrantes.nombre_completo"
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="outlined" density="compact"></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Sexo</span>
                                                <v-select v-model="formIntegrantes.sexo" :rules="[validations.required]"
                                                    color="primary" variant="outlined" :items="['Hombre',
                                                        'Mujer',
                                                        'Intersexual']" density="compact"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Edad</span>
                                                <v-text-field v-model="formIntegrantes.edad" v-numbers-only-app
                                                    :rules="[validations.required, validations.maxLength(3), validations.numeric]"
                                                    color="primary" variant="outlined" density="compact"></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Grupo Poblacional</span>
                                                <v-select v-model="formIntegrantes.grupo_poblacional" color="primary"
                                                    variant="outlined" :items="['Campesino',
                                                        'Indigena',
                                                        'Afro',
                                                        'Room',
                                                        'Ninguno']" density="compact"></v-select>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-medium">Procedencia / Residencia</span>
                                            </v-col>

                                            <v-col md="4" cols="12" class="py-1">
                                                <span class="font-weight-medium">País</span>
                                                <v-select v-model="formIntegrantes.procedencia.pais"
                                                    :rules="[validations.required]" color="primary" variant="outlined"
                                                    :items="['Ejempo 1',
                                                        'Ejemplo 2',
                                                        'Ejemplo 3',
                                                        'Ejemplo 4',
                                                        'Ejemplo 5']" density="compact"></v-select>
                                            </v-col>
                                            <v-col md="4" cols="12" class="py-1">
                                                <span class="font-weight-medium">Departamento/Estado</span>
                                                <v-select v-model="formIntegrantes.procedencia.departamento"
                                                    :rules="[validations.required]" color="primary" variant="outlined"
                                                    :items="['Ejempo 1',
                                                        'Ejemplo 2',
                                                        'Ejemplo 3',
                                                        'Ejemplo 4',
                                                        'Ejemplo 5']" density="compact"></v-select>
                                            </v-col>
                                            <v-col md="4" cols="12" class="py-1">
                                                <span class="font-weight-medium">Ciudad</span>
                                                <v-select v-model="formIntegrantes.procedencia.ciudad"
                                                    :rules="[validations.required]" color="primary" variant="outlined"
                                                    :items="['Ejempo 1',
                                                        'Ejemplo 2',
                                                        'Ejemplo 3',
                                                        'Ejemplo 4',
                                                        'Ejemplo 5']" density="compact"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Nivel Educativo</span>
                                                <v-select v-model="formIntegrantes.nivel_educativo" :items="[
                                                    'Ninguno',
                                                    'Primaria',
                                                    'Bachiller',
                                                    'Técnico/Tecnológo',
                                                    'Profesional',
                                                    'Profesional con postgrado'
                                                ]" color="primary" variant="outlined" density="compact"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1">
                                                <span class="font-weight-medium">Teléfono</span>
                                                <v-text-field v-model="formIntegrantes.telefono" v-numbers-only-app
                                                    :rules="formIntegrantes.telefono ? [validations.required, validations.maxLength(255), validations.numeric] : []"
                                                    color="primary" variant="outlined" density="compact"></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1">
                                                <span class="font-weight-medium">Correo Electrónico</span>
                                                <v-text-field v-model="formIntegrantes.correo_electronico"
                                                    :rules="formIntegrantes.correo_electronico ? [validations.emailFormat] : []"
                                                    color="primary" variant="outlined" density="compact"></v-text-field>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1 text-center">

                                                <v-btn @click="addIntegrante" variant="outlined" class="text-capitalize"
                                                    color="secondary" prepend-icon="mdi-plus-circle-outline">
                                                    Guardar Integrante
                                                </v-btn>

                                            </v-col>

                                            <!-- {{ itemsIntegrantes }} -->

                                            <v-col v-if="itemsIntegrantes.length > 0" sm="12" md="12" cols="12">

                                                <v-table density="compact" class="integrantes-table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="7"></th>
                                                            <th colspan="3" class="text-center text-caption">
                                                                Procedencia </th>
                                                            <th colspan="4"></th>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-center text-caption">

                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Tipo Documento
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Número de identificación
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Nombre completo
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Sexo
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Edad
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Grupo Poblacional
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                País
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Departamento/Estado
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Ciudad
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Nivel Educativo
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Teléfono
                                                            </th>
                                                            <th class="text-center text-caption">
                                                                Correo Electrónico
                                                            </th>
                                                            <th></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody class="" style="    display: contents;">
                                                        <tr v-for="(item, index) in itemsIntegrantes" :key="index">
                                                            <td data-label="" class="text-center">

                                                                <div class="d-flex">

                                                                    <v-btn @click="editFormIntegrantes(item)"
                                                                        rounded="lg" style="border: 2px solid"
                                                                        class="my-2 mx-1 font-weight-bold" height="35"
                                                                        max-width="25" size="x-small" variant="outlined"
                                                                        color="primary">
                                                                        <v-icon size="20">mdi-file-edit-outline</v-icon>
                                                                    </v-btn>
                                                                    <v-btn @click="itemsIntegrantes.splice(index, 1)"
                                                                        rounded="lg" style="border: 2px solid"
                                                                        class="my-2 mx-1 font-weight-bold" height="35"
                                                                        max-width="25" size="x-small" variant="outlined"
                                                                        color="red-app">
                                                                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                                                                    </v-btn>

                                                                </div>

                                                            </td>
                                                            <td data-label="Tipo Documento" clas="text-caption">{{
                                                                item.tipo_doc
                                                                }}</td>
                                                            <td data-label="Número de identificación"
                                                                clas="text-caption">{{
                                                                    item.numero_identificacion }}</td>
                                                            <td data-label="Nombre completo" clas="text-caption">{{
                                                                item.nombre_completo }}</td>
                                                            <td data-label="Sexo" clas="text-caption">{{ item.sexo }}
                                                            </td>
                                                            <td data-label="Edad" clas="text-caption">{{ item.edad }}
                                                            </td>
                                                            <td data-label="Grupo Poblacional" clas="text-caption">{{
                                                                item.grupo_poblacional }}</td>
                                                            <td data-label="País" clas="text-caption">{{
                                                                item.procedencia.pais
                                                                }}</td>
                                                            <td data-label="Departamento/Estado" clas="text-caption">{{
                                                                item.procedencia.departamento }}</td>
                                                            <td data-label="Ciudad" clas="text-caption">{{
                                                                item.procedencia.ciudad }}</td>
                                                            <td data-label="Nivel Educativo" clas="text-caption">{{
                                                                item.nivel_educativo }}</td>
                                                            <td data-label="Teléfono" clas="text-caption">{{
                                                                item.telefono }}
                                                            </td>
                                                            <td data-label="Correo Electrónico" clas="text-caption">{{
                                                                item.correo_electronico }}</td>
                                                            <td></td>

                                                        </tr>
                                                    </tbody>
                                                </v-table>

                                            </v-col>




                                        </v-row>
                                    </v-card-text>
                                </v-window-item>




                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->

                </v-row>




            </v-col>
        </v-row>

        <UserExperience></UserExperience>

    </v-container>
    <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation1 from './includes/LeftPanelAnimation1.vue';
import NavBarAnimation1 from './includes/NavBarAnimation1.vue';
import UserExperience from './includes/UserExperience.vue';
import moment from 'moment';


export default {
    name: 'FormVisitorEntry',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation1,
        NavBarAnimation1,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()

        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            showTopShadow: false,
            showBottomShadow: true,
            menuDateVisit: false,
            menuDateCheckout: false,

            form: {
                autoriza: null,
                tipo_viaje: [],

                date_visit_format: null,
                date_visit: null,
                date_checkout_format: null,
                date_checkout: null,
                programo_visita: null,
                municipios_visitar: [],
                forma_entero: [],
                medio_transporte: [],
            },

            formIntegrantes: {
                tipo_doc: null,
                numero_identificacion: null,
                nombre_completo: null,
                sexo: null,
                edad: null,
                grupo_poblacional: null,
                procedencia: {
                    pais: null,
                    departamento: null,
                    ciudad: null,
                },
                nivel_educativo: null,
                telefono: null,
                correo_electronico: null,
            },

            itemsIntegrantes: [],



            itemsProgramoVisita: [
                'Agencia de viajes',
                'Guia o Interprete local',
                'Persona de la zona',
                'De forma independiente'
            ],




            isMobile: false,

            num: 120,
            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),


            stepFormServiceOrder: 1,
            stepTitles: [
                'Información del Atractivo Turístico',
                'Detalle de visita y Guía',
                'Registro de Integrantes',
            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }
        },

        'form.date_visit'() {
            if (this.form.date_visit) {
                const fecha = moment(this.form.date_visit);
                this.form.date_visit_format = fecha.format('YYYY-MM-DD');
            } else {
                this.form.date_visit_format = null;
            }
            this.menuDateVisit = false;
        },

    },
    methods: {
        editFormIntegrantes(item) {
            this.formIntegrantes = item;
            this.itemsIntegrantes = this.itemsIntegrantes.filter((integrante) => integrante !== item);
        },

        addIntegrante() {
            this.itemsIntegrantes.push(this.formIntegrantes);
            this.formIntegrantes = {
                tipo_doc: null,
                numero_identificacion: null,
                nombre_completo: null,
                sexo: null,
                edad: null,
                grupo_poblacional: null,
                procedencia: {
                    pais: null,
                    departamento: null,
                    ciudad: null,
                },
                nivel_educativo: null,
                telefono: null,
                correo_electronico: null,
            }
        },

        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },





        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        }


    },

    mounted() {
        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);



    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 8px;
}


.v-row {
    margin: 0;
}


.integrantes-table-responsive {
    width: 100%;
    overflow-x: auto;
}

@media (max-width: 800px) {
    .integrantes-table-responsive .v-table__wrapper {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    .integrantes-table-responsive table {
        border-collapse: collapse;
        width: 100%;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .integrantes-table-responsive thead {
        display: none;
    }

    .integrantes-table-responsive tbody tr {
        display: block;


    }

    .integrantes-table-responsive tbody td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
    }

    .integrantes-table-responsive tbody td::before {
        content: attr(data-label);
        flex-basis: 50%;
        text-align: left;
        font-weight: bold;
        padding-right: 10px;
    }

    .integrantes-table-responsive td {
        border-bottom: 1px solid #e0e0e0;
        height: 40px !important;
    }

}
</style>
