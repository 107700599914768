<template>
    <v-container class="pa-0" style=" height: 100%; width: 100%; min-width: 100%; position: relative;">

        <NavBarAnimation3 v-if="isMdOrLower" title="Registro de Empresarios" subTitle="Hola apreciado/a empresario/a, en esta sección podrás actualizar los datos 
                de ubicación y contacto de tu empresa. Recuerda que para el departamento es importante contar con tu información actualizada ya 
                que es útil para realizar planificación de políticas públicas del departamento, incluirte en proyectos o eventos que realicemos, 
                así como en gestiones para las intervenciones de cooperación internacional. Los datos que se solicitan en este formulario son 
                estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartirán con otras entidades 
                como: DIAN, Cámara y Comercio, Alcaldías, etc.">
        </NavBarAnimation3>

        <v-app-bar class="elevation-0" v-if="isMdOrLower">
            <v-slide-group v-model="stepFormServiceOrder" class="py-4" center-active show-arrows>
                <v-slide-group-item v-for="(item, index) in stepTitles" :key="index" v-slot="{ isSelected }"
                    :value="index + 1">

                    <v-list-item :color="isSelected ? 'primary' : 'grey-lighten-1'"
                        @click="stepFormServiceOrder = index + 1">
                        <template v-slot:prepend>
                            <v-avatar
                                :color="isSelected || stepFormServiceOrder > index + 1 ? 'primary' : 'grey-lighten-1'"
                                class="text-white" size="x-small">
                                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                <span v-else>{{ index + 1 }}</span>

                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-caption"
                            :class="isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''">{{
                                item
                            }}</v-list-item-title>
                    </v-list-item>

                </v-slide-group-item>
            </v-slide-group>
        </v-app-bar>


        <v-row class="h-100 mh-100 auth">
            <v-col lg="5" xl="4" cols="12" class="d-lg-flex d-none align-center justify-center  bg-secondary pa-0"
                style="position: fixed; height: 100%;">

                <LeftPanelAnimation3 title="Registro de" subTitle="Hola apreciado/a empresario/a, en esta sección podrás actualizar los datos 
                de ubicación y contacto de tu empresa. Recuerda que para el departamento es importante contar con tu información actualizada ya 
                que es útil para realizar planificación de políticas públicas del departamento, incluirte en proyectos o eventos que realicemos, 
                así como en gestiones para las intervenciones de cooperación internacional. Los datos que se solicitan en este formulario son 
                estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartirán con otras entidades 
                como: DIAN, Cámara y Comercio, Alcaldías, etc." :wordsAnimate="['Empresarios']"></LeftPanelAnimation3>

            </v-col>
            <v-col class="ml-auto" lg="7" xl="8" cols="12" style=" background: #fff; z-index: 9;">

                <v-row class="h-100 mh-100">

                    <v-col lg="5" xl="4" cols="12"
                        class="d-lg-flex d-none align-center justify-center position-relative ">
                        <div class="scroll-container-wrapper">
                            <div class="scroll-container position-relative" ref="scrollContainer"
                                @scroll="handleScroll">
                                <div class="item">
                                    <div class="mouse m-2" :class="{
                                        'animate__animated animate__fadeInDown': showIndicator,
                                        'animate__animated animate__fadeOutDown': !showIndicator
                                    }"></div>
                                </div>
                                <v-timeline class="py-8" align="start" side="start" :line-thickness="1"
                                    truncate-line="both">
                                    <v-timeline-item v-for="(item, index) in stepTitles" :key="index"
                                        :class="stepFormServiceOrder > index ? 'success-step' : ''"
                                        :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                                        size="small">

                                        <template v-slot:icon>
                                            <v-btn width="20" height="20" @click="stepFormServiceOrder = index + 1"
                                                size="x-small" icon variant="text">
                                                <v-icon v-if="stepFormServiceOrder == index + 1">mdi-pencil</v-icon>
                                                <v-icon v-else-if="stepFormServiceOrder > index">mdi-check</v-icon>
                                                <span v-else>{{ index + 1 }}</span>
                                            </v-btn>

                                        </template>
                                        <div class="d-flex">
                                            <div :ref="'timelineItem' + index"></div>

                                            <div class="text-end text-body-2 font-weight-medium"
                                                :class="{ 'text-primary': stepFormServiceOrder > index }">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </v-timeline-item>


                                </v-timeline>
                            </div>
                            <div v-if="showTopShadow" class="top-shadow"></div>
                            <div v-if="showBottomShadow" class="bottom-shadow"></div>
                        </div>
                    </v-col>

                    <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
                        <v-card width="100%" elevation="0">
                            <v-card-text>
                                <span class="text-h4 font-weight-bold">
                                    {{ currentTitle }}

                                </span>
                            </v-card-text>
                            <v-col md="12" cols="12" class="py-1">
                                <br>
                            </v-col>



                            <v-window :touch="false" direction="vertical" v-model="stepFormServiceOrder">

                                <v-window-item :value="1" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿Cuál es el nombre del representante
                                                    legal de la
                                                    empresa?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Tipo de documento de
                                                    identificación</span> <span class="font-weight-bold">*</span>
                                                <v-select v-model="formData.repre_tipo_documento"
                                                    :rules="[validations.required]" color="primary" variant="underlined"
                                                    :items="['Cédula de identidad', 'Cédula de extranjería', 'Otro']"
                                                    placeholder="Selecciona una opción"></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1" 
                                                v-if="formData.repre_tipo_documento == 'Otro'">
                                                <span class="font-weight-bold">¿Cuál?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field color="primary" variant="underlined"
                                                    placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Número de documento de
                                                    identificación</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(11)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="2" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿Sexo al nacer del representante legal de
                                                    la
                                                    empresa?</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="formData.repre_genero">
                                                    <v-radio hi v-for="(item, index) in itemsRepreGenero" :key="index"
                                                        :value="item.value" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': formData.repre_genero == item.value }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item.label }}
                                                                <p class="text-caption font-italic"
                                                                    v-if="item.description">{{ item.description }}</p>
                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Indique si pertenece a estos grupos
                                                    poblacionales</span> <span class="font-weight-bold">*</span>
                                                

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.repre_grupos_poblacionales"
                                                    :items="itemRepreGruposPoblacionales" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ formData.repre_grupos_poblacionales.length - 2 }} {{
                                                                formData.repre_grupos_poblacionales.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.repre_grupos_poblacionales"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemRepreGruposPoblacionales.length"></v-divider>
                                                    </template>

                                                </v-select>

                                               

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">De acuerdo con su cultura, pueblo, rasgos
                                                    físicos, se reconoce como:</span> <span
                                                    class="font-weight-bold">*</span>
                                                    <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.repre_cultura"
                                                    :items="itemRepreCultura" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ formData.repre_cultura.length - 2 }} {{
                                                                formData.repre_cultura.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.repre_cultura"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemRepreCultura.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleRepreCultura(item)"
                                                            v-for="(item, index) in itemRepreCultura" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': formData.repre_cultura.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="formData.repre_cultura"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="3" >
                                    <v-card-text class="text-body-2">
                                        <!-- <div class="d-flex">
                                            <v-spacer></v-spacer>

                                            <v-btn class="text-capitalize" size="small" color="primary"
                                                @click="addPhoneNumber">
                                                <v-icon>mdi-plus-circle</v-icon> Agregar Teléfono
                                            </v-btn>

                                        </div> -->

                                        <v-row>
                                            <v-col v-for="(phone, index) in formData.repre_numero_telefono" :key="index"
                                                md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Teléfono(s) personal(es)</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-combobox :rules="[validations.required]" color="primary"
                                                    variant="underlined" v-numbers-only-app chips multiple
                                                    closable-chips></v-combobox>

                                                <!-- <v-text-field v-model="formData.repre_numero_telefono[index]"
                                                    v-numbers-only-app :rules="[validations.required]" color="primary"
                                                    variant="underlined" placeholder="">

                                                    <template v-slot:append>

                                                        <v-btn size="x-small" height="35" rounded="lg"
                                                            variant="outlined" style="border: 2px solid;" color="error"
                                                            v-if="formData.repre_numero_telefono.length > 1"
                                                            @click="removePhoneNumber(index)">
                                                            <v-icon size="16">mdi-trash-can</v-icon>
                                                        </v-btn>

                                                    </template>

                                                </v-text-field> -->

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Correo electrónico personal</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.emailFormat]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="4" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿La empresa es la administradora de algún
                                                    sitio o
                                                    atractivo turístico?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="formData.empresa_administradora">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': formData.empresa_administradora == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': formData.empresa_administradora == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿Cuál o cuáles de los siguientes
                                                    sitios/atractivos administra?
                                                </span> <span class="font-weight-bold">*</span>


                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.sitios_administra"
                                                    :items="itemsSitiosAdministra" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ formData.sitios_administra.length - 2 }} {{
                                                                formData.sitios_administra.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.sitios_administra"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsSitiosAdministra.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleSitiosAdministra(item)"
                                                            v-for="(item, index) in itemsSitiosAdministra" :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': formData.sitios_administra.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="formData.sitios_administra"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="5" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿La empresa tiene matricula activa
                                                    actualmente en
                                                    cámara y comercio?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="formData.empresa_matricula_activa">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': formData.empresa_matricula_activa == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': formData.empresa_matricula_activa == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿El portafolio de productos y/o servicios
                                                    comerciales de su empresa incluye actividades del sector turismo
                                                    (Alojamiento, venta de tours, operadores de congresos, gastronomía,
                                                    etc)?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="formData.empresa_actividades_turismo">
                                                    <v-radio label="Si" value="Si" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': formData.empresa_actividades_turismo == 'Si' }"></v-radio>
                                                    <v-radio label="No" value="No" class=" pa-1 my-1"
                                                        :class="{ 'rounded-lg animate__animated animate__headShake bg-primary-second': formData.empresa_actividades_turismo == 'No' }"></v-radio>
                                                </v-radio-group>

                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿De acuerdo con las actividades
                                                    económicas que
                                                    ejerce cómo se clasifica?
                                                </span> <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.empresa_actividades_economicas"
                                                    :items="itemsActividadesEconomicas" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ formData.empresa_actividades_economicas.length - 2 }} {{
                                                                formData.empresa_actividades_economicas.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.empresa_actividades_economicas"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsActividadesEconomicas.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleActividadesEconomicas(item)"
                                                            v-for="(item, index) in itemsActividadesEconomicas"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': formData.empresa_actividades_economicas.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="formData.empresa_actividades_economicas"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>


                                                    </v-list>
                                                </div> -->

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                </v-window-item>


                                <v-window-item :value="6" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="6" cols="12" class="py-1" >
                                                <span class="font-weight-bold">NIT</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="6" cols="12" class="py-1" >
                                                <span class="font-weight-bold">DV</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Nombre del Establecimiento</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Ciudad de ubicación del establecimiento
                                                </span>
                                                <span class="font-weight-bold">*</span>
                                                <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2" v-model="formData.establecimiento_ciudad">
                                                    <v-radio hi v-for="(item, index) in itemsEstablecimientoUbicacion"
                                                        :key="index" :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': formData.establecimiento_ciudad == item }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item }}

                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group>
                                            </v-col>


                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="7" >
                                    <v-card-text class="text-body-2">
                                        <v-row>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Dirección de la empresa</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" @input="onPlaceChanged" v-model="address"
                                                    ref="addressField" variant="underlined"
                                                    placeholder="Escribe la dirección de la empresa"></v-text-field>


                                                <div ref="map" class="map mb-3" style="  width: 100%; height: 400px; ">
                                                </div>

                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Email de la empresa</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.emailFormat]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Página web</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.urlFormat]"
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="8" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Teléfono(s) de la empresa</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required]" v-numbers-only-app
                                                    color="primary" variant="underlined" placeholder=""></v-text-field>
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Indique el año de inicio de actividades
                                                    del
                                                    establecimiento</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field :rules="[validations.required, validations.maxLength(4)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="9" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">¿Qué documentos posee el establecimiento
                                                    para la
                                                    operación?</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.establecimiento_documentos"
                                                    :items="itemsDocumentosEstablecimiento" multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ formData.establecimiento_documentos.length - 2 }} {{
                                                                formData.establecimiento_documentos.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.establecimiento_documentos"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsDocumentosEstablecimiento.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleDocumentosEstablecimiento(item)"
                                                            v-for="(item, index) in itemsDocumentosEstablecimiento"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': formData.establecimiento_documentos.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="formData.establecimiento_documentos"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>

                                                        <v-list-item>
                                                            <v-text-field
                                                                v-if="formData.establecimiento_documentos.includes('Otro')"
                                                                color="primary" variant="underlined"
                                                                placeholder="¿Cuál?"></v-text-field>
                                                        </v-list-item>
                                                    </v-list>
                                                </div> -->
                                            </v-col>
                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Registro Nacional de Turismo (RNT)</span>
                                                <span class="font-weight-bold">*</span>
                                                <v-text-field
                                                    :rules="[validations.required, validations.maxLength(255)]"
                                                    color="primary" variant="underlined" v-numbers-only-app
                                                    placeholder=""></v-text-field>
                                            </v-col>



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                                <v-window-item :value="10" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Categoría de RNT</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.categoria_rnt"
                                                    :items="itemsCategoriaRnt"  variant="underlined">
                                                    

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-radio color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.categoria_rnt"></v-radio>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsCategoriaRnt.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="formData.categoria_rnt">
                                                    <v-radio hi v-for="(item, index) in itemsCategoriaRnt" :key="index"
                                                        :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': formData.categoria_rnt == item }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item }}
                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group> -->
                                            </v-col>

                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Subcategoría de RNT</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.subcategoria_rnt"
                                                    :items="itemsSubcategoriaRnt"  variant="underlined">
                                                    

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-radio color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.subcategoria_rnt"></v-radio>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsSubcategoriaRnt.length"></v-divider>
                                                    </template>

                                                </v-select>

                                              
                                            </v-col>




                                        </v-row>
                                    </v-card-text>
                                </v-window-item>
                               

                                <v-window-item :value="11" >
                                    <v-card-text class="text-body-2">
                                        <v-row>


                                            <v-col md="12" cols="12" class="py-1" >
                                                <span class="font-weight-bold">Señale las actividades económicas registradas en Cámara de Comercio de este establecimiento</span>
                                                <span class="font-weight-bold">*</span>

                                                <v-select :menu-props="{ 'max-width': '100%' }"
                                                    v-model="formData.establecimiento_documentos_camara" :items="itemsActividadesEconomicasCamara"
                                                    multiple variant="underlined">
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index < 2">
                                                            <span>{{ item.title && item.title.length > 20 ?
                                                                item.title.substring(0, 20) + '...' : item.title
                                                                }}</span>
                                                        </v-chip>
                                                        <span v-if="index === 2"
                                                            class="text-grey text-caption align-self-center">
                                                            (+{{ formData.establecimiento_documentos_camara.length - 2 }} {{
                                                                formData.establecimiento_documentos_camara.length - 2 == 1 ? 'opción' :
                                                                    'opciones'
                                                            }})
                                                        </span>
                                                    </template>

                                                    <template v-slot:item="{ props, item, index }">
                                                        <v-list-item v-bind="props" class="text-caption-font">
                                                            <template v-slot:prepend="{ isActive }">
                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary"
                                                                        :true-value="item.raw" :model-value="isActive"
                                                                        v-model="formData.establecimiento_documentos_camara"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap text-caption">
                                                                <span class="text-caption">{{ item.raw.title }} </span>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider
                                                            v-if="index + 1 < itemsActividadesEconomicasCamara.length"></v-divider>
                                                    </template>

                                                </v-select>

                                                <!-- <div>
                                                    <v-list lines="one" select-strategy="classic"
                                                        class="overflow-visible list-container-documents">
                                                        <v-list-item class="rounded-lg ma-1"
                                                            @click="toggleActividadesEconomicasCamara(item)"
                                                            v-for="(item, index) in itemsActividadesEconomicasCamara"
                                                            :key="index"
                                                            :class="{ 'animate__animated animate__headShake bg-primary-second': formData.establecimiento_documentos_camara.includes(item) }">
                                                            <template v-slot:append="{ isActive }">


                                                                <v-list-item-action start>
                                                                    <v-checkbox-btn color="primary" :true-value="item"
                                                                        :model-value="isActive"
                                                                        v-model="formData.establecimiento_documentos_camara"></v-checkbox-btn>
                                                                </v-list-item-action>
                                                            </template>

                                                            <v-list-item-title class="text-wrap">{{ item
                                                                }}</v-list-item-title>
                                                        </v-list-item>

                                                      
                                                    </v-list>
                                                </div> -->
                                            </v-col>
                                         



                                        </v-row>
                                    </v-card-text>
                                </v-window-item>






                            </v-window>
                            <v-card-actions>
                                <br>
                            </v-card-actions>
                            <v-card-actions class="mb-9">
                                <v-spacer></v-spacer>

                                <v-btn class="text-capitalize" :disabled="stepFormServiceOrder > 1 ? false : true"
                                    variant="text" @click="stepFormServiceOrder--">
                                    Anterior
                                </v-btn>
                                <v-btn class="text-capitalize" v-if="stepFormServiceOrder < stepTitles.length"
                                    color="primary" variant="text" @click="stepFormServiceOrder++">
                                    Siguiente
                                </v-btn>
                                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                                    Finalizar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->

                </v-row>




            </v-col>
        </v-row>

        <UserExperience></UserExperience>

    </v-container>
    <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->

</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import { useDisplay } from 'vuetify'
import validationFields from '../functions/validationFields';
import LeftPanelAnimation3 from './includes/LeftPanelAnimation3.vue';
import NavBarAnimation3 from './includes/NavBarAnimation3.vue';
import UserExperience from './includes/UserExperience.vue';

/* global google */
export default {
    name: 'FormEntrepreneurRegistration',
    mixins: [validationFields],
    components: {
        LeftPanelAnimation3,
        NavBarAnimation3,
        UserExperience
    },

    setup() {
        const { mdAndDown } = useDisplay()

        return {
            isMdOrLower: mdAndDown
        };
    },

    data() {
        return {
            formData: {
                repre_tipo_documento: null,
                repre_genero: null,
                repre_grupos_poblacionales: [],
                repre_cultura: [],
                repre_numero_telefono: [''],
                repre_correo_electronico: null,
                empresa_administradora: null,
                sitios_administra: [],
                empresa_matricula_activa: null,
                empresa_actividades_turismo: null,
                empresa_actividades_economicas: [],
                establecimiento_nombre: null,
                establecimiento_ciudad: null,
                establecimiento_direccion: null,
                establecimiento_email: null,
                establecimiento_web: null,
                establecimiento_telefono: null,
                establecimiento_inicio_actividades: null,
                establecimiento_documentos: [],
                categoria_rnt: null,
                subcategoria_rnt: null,
                establecimiento_documentos_camara: [],
            },
            itemsRepreGenero: [
                {
                    label: 'Mujer',
                    description: null,
                    value: 'Mujer'
                },
                {
                    label: 'Hombre',
                    description: null,
                    value: 'Hombre'
                },
                {
                    label: 'Intersexual',
                    description: 'Son personas que biológicamente desarrollan características',
                    value: 'Intersexual'
                }
            ],
            itemRepreGruposPoblacionales: [
                'Grupos Étnicos',
                'Sector Social LGBTIQ+',
                'Víctimas del conflicto',
                'Reincorporado/reinsertado',
                'Lider Social',
                'Mujer cabeza de hogar',
                'Ninguno'
            ],
            itemRepreCultura: [
                'Indígena',
                'Gitano/a o Rrom',
                'Palenquero/a de San Basilio',
                'Raizal del archipielago de San Andrés, Providencia y Santa Catalina',
                'Negro/a, mulato/a, afrodescendiente, afrocolombiano/a',
            ],

            itemsSitiosAdministra: [
                'Arte Rupestre Cerro Azul',
                'Arte Rupestre Nuevo Tolima',
                'Arte Rupestre Raudal del Guayabero',
                'Cachiveras de Itilla',
                'Cachiveras del Chiribiquete',
                'Caño sabana Trankilandia',
                'Cavernas Rupicolas',
                'Charco Indio',
                'Ciudad de Piedra',
                'Damas de Nare',
                'Diamante de las aguas',
                'Finca Agro ecoturística El Paraíso',
                'Laguna Negra',
                'Los Picachos',
                'Los Termales',
                'Pozos Naturales',
                'Puentes Naturales',
                'Puerta de Orión',
                'Reserva Amadai',
                'Reserva Cheyen',
                'Reserva El Gallito de Roca',
                'Rincón de los Toros',
                'Sector Dique',
                'Túneles Naturales',
            ],

            itemsActividadesEconomicas: [
                'Agencia de viajes',
                'Arrendador de vehículos para turismo nacional e internacional',
                'Bar con potencial turístico',
                'Compañía de intercambio vacacional',
                'Concesionario de servicios turísticos en el parque',
                'Empresa de tiempo compartido y multipropiedad',
                'Establecimientos de alojamiento turistico',
                'Establecimientos de gastronomía y similares',
                'Operadores profesionales de congresos, ferias y convenciones',
            ],

            itemsEstablecimientoUbicacion: [
                'San José del Guaviare',
                'El Retorno',
                'Miraflores',
                'Calamar',
            ],

            itemsDocumentosEstablecimiento: [
                'Industría y Comercio (Pago a alcaldía)',
                'Póliza De Responsabilidad Civil Y Extracontractual',
                'RUT',
                'Concepto Bomberos',
                'Uso Del Suelo  ',
                'RNT',
                'Concepto Sanidad',
                'Tarjetas de asistencia médica',
                'Licencia de Construcción',
                'Resolución de Facturación',
                'Derechos de autor',
                'Procesos ante CDA',
                'Certificado Secretaria Salud',
                'Otro',
            ],

            itemsCategoriaRnt: [
                'Agencias de viajes ',
                'Arrendadores de vehículos para turismo nacional e internacional',
                'Bares',
                'Compañía de intercambio vacacional',
                'Concesionarios de servicios turísticos en el parque',
                'Empresa de tiempo compartido y multipropiedad',
                'Empresa de transporte terrestre automotor',
                'Empresas captadoras de ahorro para viajes',
                'Establecimientos de alojamiento turistico',
                'Establecimientos de gastronomía y similares',
                'Oficinas de representación turística',
                'Operadores de plataforma electrónicas o digitales de servicios turísticos',
                'Operadores profesionales de congresos, ferias y convenciones',
                'Viviendas Turísticas',
            ],

            itemsSubcategoriaRnt: [
                'agencia de viajes y de turismo',
                'agencia de viajes mayoristas',
                'agencia de viajes operadoras',
                'gastrobar',
                'bar social',
                'bar con música en vivo',
                'comercializadora',
                'promotora',
                'promotora y comercializadora',
                'transporte terrestre automotor especial',
                'operador de chivas',
                'operador de yipaos',
                'hotel',
                'apartahotel',
                'centro vacacional',
                'hostal',
                'campamento',
                'albergue',
                'refugio',
                'glamping',
                'restaurante',
                'restaurante de autoservicio',
                'bar y restaurante',
                'café o cafetería',
                'restaurante de comida rápida',
                'cevichería y pescadería',
                'heladería',
                'panadería repostería pastelería o chocolatería',
                'piqueteadero',
                'frutería',
                'parque temático',
                'parque de ecoturismo',
                'parque de agroturismo',
                'apartamento turístico',
                'casa turística',
                'finca turistica (alojamiento rural)',
                'otros tipos de hospedaje turísticos no permanentes',
                'otro tipo de vivienda turística',
                'Posada turística',
            ],

            itemsActividadesEconomicasCamara: [
                'Bar (CIIU 5630)',
                'Restaurante y Gastrobares (CIIU 5611, 5612, 5613, 5619, 5621, 5629)',
                'Alojamiento (CIIU 5511, 5512, 5513, 5514, 5519, 5520)',
                'Agencias de viajes (CIIU 7911, 7912, 7990)',
                'Transporte (CIIU 4911, 4921, 4922, 7710, 5111, 5112, 5011, 5021)',
                'Actividades culturales y eventos (CIIU  8230, 9006, 9007, 9008, 9102, 9103, 9311, 9319, 9321, 9329)',
            ],




            showTopShadow: false,
            showBottomShadow: true,

            address: null,
            map: null,
            marker: null,
            geocoder: null,


            isMobile: false,

            vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),


            stepFormServiceOrder: 1,
            stepTitles: [
                'Información del Representante Legal',
                'Identidad y Pertenencia del Representante Legal',
                'Contacto Personal del Representante Legal',
                'Administración de Sitios Turísticos',
                'Registro y Actividades Comerciales de la Empresa',
                'Información General del Establecimiento',
                'Contacto del Establecimiento',
                'Teléfono y Año de Inicio de Actividades',
                'Documentos y Registro Nacional de Turismo',
                'Categorías y Subcategorías Registro Nacional de Turismo',
                'Actividades Económicas Registradas'
            ],

            showIndicator: true, // Initially show the indicator

        };
    },
    watch: {
        stepFormServiceOrder(newVal) {
            if (newVal < this.stepTitles.length) {
                this.scrollToItem(newVal);
            }

            if (this.stepFormServiceOrder == 7 && this.address == null) {
                setTimeout(() => {
                    this.initMap();
                    this.initAutocomplete();
                }, 2000);
            }

        },
    },
    methods: {
        setPlace() {

        },
        addPhoneNumber() {
            this.formData.repre_numero_telefono.push(''); // Agrega un nuevo campo vacío al array
        },
        removePhoneNumber(index) {
            if (this.formData.repre_numero_telefono.length > 1) {
                this.formData.repre_numero_telefono.splice(index, 1); // Elimina el campo de teléfono en el índice dado
            }
        },

        handleScroll() {
            const container = this.$refs.scrollContainer;
            if (container) {
                this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

                // Control de las sombras
                this.showTopShadow = container.scrollTop > 0;
                this.showBottomShadow = container.scrollTop < (container.scrollHeight - container.clientHeight);
            }
        },
        scrollToItem(index) {
            const item = this.$refs['timelineItem' + index][0]; // Acceso directo al primer elemento
            if (item) {
                const container = this.$refs.scrollContainer;
                const itemRect = item.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                // Ajuste adicional para el margen superior
                const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

                container.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
                console.log('Item Rect:', itemRect, 'Container Rect:', containerRect, 'Offset Top:', offsetTop);
            }
        },


        async nextStep() {
            this.stepFormServiceOrder++
        },

        toggleRepreGruposPoblacionales(item) {
            const index = this.formData.repre_grupos_poblacionales.indexOf(item);
            if (index === -1) {
                this.formData.repre_grupos_poblacionales.push(item);
            } else {
                this.formData.repre_grupos_poblacionales.splice(index, 1);
            }
        },

        toggleRepreCultura(item) {
            const index = this.formData.repre_cultura.indexOf(item);
            if (index === -1) {
                this.formData.repre_cultura.push(item);
            } else {
                this.formData.repre_cultura.splice(index, 1);
            }
        },

        toggleSitiosAdministra(item) {
            const index = this.formData.sitios_administra.indexOf(item);
            if (index === -1) {
                this.formData.sitios_administra.push(item);
            } else {
                this.formData.sitios_administra.splice(index, 1);
            }
        },

        toggleActividadesEconomicas(item) {
            const index = this.formData.empresa_actividades_economicas.indexOf(item);
            if (index === -1) {
                this.formData.empresa_actividades_economicas.push(item);
            } else {
                this.formData.empresa_actividades_economicas.splice(index, 1);
            }
        },

        toggleDocumentosEstablecimiento(item) {
            const index = this.formData.establecimiento_documentos.indexOf(item);
            if (index === -1) {
                this.formData.establecimiento_documentos.push(item);
            } else {
                this.formData.establecimiento_documentos.splice(index, 1);
            }
        },

        toggleActividadesEconomicasCamara(item) {
            const index = this.formData.establecimiento_documentos_camara.indexOf(item);
            if (index === -1) {
                this.formData.establecimiento_documentos_camara.push(item);
            } else {
                this.formData.establecimiento_documentos_camara.splice(index, 1);
            }
        },






        starryNight() {
            anime({
                targets: ["#sky .star"],
                opacity: [
                    { duration: 700, value: "0" },
                    { duration: 700, value: "1" },
                ],
                easing: "linear",
                loop: true,
                delay: (el, i) => 50 * i,
            });
        },
        shootingStars() {
            anime({
                targets: ["#shootingstars .wish"],
                easing: "linear",
                loop: true,
                delay: (el, i) => 1000 * i,
                opacity: [{ duration: 700, value: "1" }],
                width: [{ value: "150px" }, { value: "0px" }],
                translateX: 350,
            });
        },
        randomRadius() {
            return Math.random() * 0.7 + 0.6;
        },
        getRandomX() {
            return Math.floor(Math.random() * Math.floor(this.vw)).toString();
        },
        getRandomY() {
            return Math.floor(Math.random() * Math.floor(this.vh)).toString();
        },



        checkMobile() {
            this.isMobile = window.innerWidth < 600;
        },

        initMap() {

            this.geocoder = new google.maps.Geocoder(); // Inicializa el servicio de Geocoder


            this.map = new google.maps.Map(this.$refs.map, {
                center: { lat: 3.432973174626513, lng: -73.24313931951718 },
                zoom: 7,
                disableDefaultUI: true, // Desactivar los controles predeterminados
                zoomControl: false, // Desactivar los controles de zoom
                streetViewControl: false, // Desactivar el control de Street View
                mapTypeControl: false, // Desactivar el control de tipo de mapa
                fullscreenControl: false, // Desactivar el control de pantalla completa
                styles: [
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#e0efef"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "hue": "#1900ff"
                            },
                            {
                                "color": "#c0e8e8"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": 100
                            },
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "lightness": 700
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#7dcdcd"
                            }
                        ]
                    }
                ]
            });
            this.marker = new google.maps.Marker({
                map: this.map,
                position: this.map.getCenter(),
                draggable: true, // Permitir arrastrar el marcador
            });

            // Añadir un listener para el evento click en el mapa
            this.map.addListener('click', (event) => {
                this.setAddressFromCoordinates(event.latLng);
            });

            // Añadir un listener para el evento dragend del marcador
            this.marker.addListener('dragend', (event) => {
                this.setAddressFromCoordinates(event.latLng);
            });
        },
        initAutocomplete() {
            // Asegurarse de que el campo de texto está correctamente referenciado
            const input = this.$refs.addressField.$el.querySelector('input');

            if (input) {
                const autocomplete = new google.maps.places.Autocomplete(input);
                autocomplete.bindTo('bounds', this.map);

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (!place.geometry || !place.geometry.location) {
                        return;
                    }

                    this.address = place.formatted_address;
                    this.map.setCenter(place.geometry.location);
                    this.marker.setPosition(place.geometry.location);
                });
            } else {
                console.error('No se pudo encontrar el campo de entrada para Google Maps Autocomplete.');
            }
        },

        setAddressFromCoordinates(latLng) {
            if (this.geocoder) {
                this.geocoder.geocode({ location: latLng }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.address = results[0].formatted_address;
                            this.marker.setPosition(latLng);
                            this.map.setCenter(latLng);
                        } else {
                            console.error('No se encontraron resultados.');
                        }
                    } else {
                        console.error('Error en Geocoder: ' + status);
                    }
                });
            } else {
                console.error('Geocoder no está inicializado.');
            }
        },

        onPlaceChanged() {
            // This method is to handle manual address entry
        },


    },

    mounted() {


        this.starryNight();
        this.shootingStars();

        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);



    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },

    computed: {
        currentTitle() {
            return this.stepTitles[this.stepFormServiceOrder - 1];
        },
        randomAnimationClass() {
            // Generar una clase de animación aleatoria
            return `bounce-${Math.floor(Math.random() * 2) + 1}`;
        },
    },

};
</script>
<style>
.v-field__input {
    padding-top: 9px;
}


/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
    margin: 0;
}
</style>
