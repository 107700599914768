<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation1
      v-if="isMdOrLower"
      title="Formulario Percepción Turista Alojamiento"
      subtitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible. 
        Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
    >
    </NavBarAnimation1>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles"
          :key="index"
          v-slot="{ isSelected }"
          :value="index + 1"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: fixed; height: 100%;"
      >
        <LeftPanelAnimation1
          title="Formulario Percepción Turista"
          subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible.
          Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
          :wordsAnimate="['Alojamiento']"
        ></LeftPanelAnimation1>
      </v-col>
      <v-col class="ml-auto" lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline
                  class="py-8"
                  align="start"
                  side="start"
                  :line-thickness="1"
                  truncate-line="both"
                >
                  <v-timeline-item
                    v-for="(item, index) in stepTitles"
                    :key="index"
                    :class="stepFormServiceOrder > index ? 'success-step' : ''"
                    :dot-color="stepFormServiceOrder > index ? 'primary' : 'dot-customer'"
                    size="small"
                  >
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = index + 1"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == index + 1"
                          >mdi-pencil</v-icon
                        >
                        <v-icon v-else-if="stepFormServiceOrder > index"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + index"></div>

                      <div
                        class="text-end text-body-2 font-weight-medium"
                        :class="{ 'text-primary': stepFormServiceOrder > index }"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <span class="text-h4 font-weight-bold">
                  {{ currentTitle }}
                </span>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">Lugar de Alojamiento</span>
                        <span class="font-weight-bold">*</span>

                        <v-select :items="placesLodging" variant="underlined"></v-select>
                      </v-col>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >Tipo de documento de identificación</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-select variant="underlined" :items="typesDocs"></v-select>
                      </v-col>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold">Número de documento</span>
                        <span class="font-weight-bold">*</span>
                        <v-text-field
                          :rules="[validations.required, validations.maxLength(255)]"
                          color="primary"
                          variant="underlined"
                          placeholder=""
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción de las siguientes
                          caracteristicas del alojamiento, teniendo en cuenta que 5
                          <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                          "Muy insatisfecho"?
                        </span>

                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in ratings_zone_comun"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-row class="py-0 ma-0">
                        <template
                          v-for="(item, index) in ratings_zone_comun"
                          :key="index"
                        >
                          <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center"
                          >
                            <span class="">{{ item.label }}</span>
                          </v-col>
                          <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center justify-center"
                          >
                            <v-rating
                              v-model="item.value"
                              active-color="orange-lighten-1"
                              color="orange-lighten-1"
                            ></v-rating>
                          </v-col>
                        </template>
                      </v-row> -->

                      <!-- <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in ratings_zone_comun" :key="index" class="border">
                                                        <td>
                                                            <span class="text-caption">{{ item.label }}</span>
                                                        </td>
                                                        <td>
                                                            <v-rating
                                                                v-model="item.value"
                                                                active-color="blue"
                                                                color="orange-lighten-1"
                                                            ></v-rating>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción de las siguientes
                          caracteristicas del alojamiento, teniendo en cuenta que 5
                          <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                          "Muy insatisfecho"?
                        </span>

                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in ratings_services"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-row class="py-0 ma-0">
                        <template v-for="(item, index) in ratings_services" :key="index">
                          <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center"
                          >
                            <span class="">{{ item.label }}</span>
                          </v-col>
                          <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center justify-center"
                          >
                            <v-rating
                              v-model="item.value"
                              active-color="orange-lighten-1"
                              color="orange-lighten-1"
                            ></v-rating>
                          </v-col>
                        </template>
                      </v-row> -->

                      <!-- <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in ratings_services" :key="index" class="border">
                                                        <td>
                                                            <span class="text-caption">{{ item.label }}</span>
                                                        </td>
                                                        <td>
                                                            <v-rating
                                                                v-model="item.value"
                                                                active-color="blue"
                                                                color="orange-lighten-1"
                                                            ></v-rating>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item
                  :value="4"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción de las siguientes
                          caracteristicas del alojamiento, teniendo en cuenta que 5
                          <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                          "Muy insatisfecho"?
                        </span>

                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in rating_room"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-row class="py-0 ma-0">
                        <template v-for="(item, index) in rating_room" :key="index">
                          <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center"
                          >
                            <span class="">{{ item.label }}</span>
                          </v-col>
                          <v-col
                            md="6"
                            lg="6"
                            sm="12"
                            cols="12"
                            class="py-1 d-flex align-center justify-center"
                          >
                            <v-rating
                              v-model="item.value"
                              active-color="orange-lighten-1"
                              color="orange-lighten-1"
                            ></v-rating>
                          </v-col>
                        </template>
                      </v-row> -->
                      <!-- <v-simple-table>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-left"></th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in rating_room" :key="index" class="border">
                                                        <td>
                                                            <span class="text-caption">{{ item.label }}</span>
                                                        </td>
                                                        <td>
                                                            <v-rating
                                                                v-model="item.value"
                                                                active-color="blue"
                                                                color="orange-lighten-1"
                                                            ></v-rating>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item
                  :value="5"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción de las siguientes
                          caracteristicas del alojamiento, teniendo en cuenta que 5
                          <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                          "Muy insatisfecho"?
                        </span>

                        <v-list style="width: 100%">
                          <v-list-item class="px-0">
                            <v-list-item-title class="text-wrap"> </v-list-item-title>
                            <template v-slot:append>
                              <div
                                class="d-flex text-center align-center justify-center"
                                style="width: 100px"
                              >
                                Sin servicio/No utilizó
                              </div>
                            </template>
                          </v-list-item>
                          <v-list-item
                            v-for="(item, index) in rating_services_lodging"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                            <template v-slot:append>
                              <div
                                class="d-flex text-center align-center justify-center"
                                style="width: 100px"
                              >
                                <v-checkbox
                                  v-model="item.value"
                                  color="primary"
                                  hide-details
                                ></v-checkbox>
                              </div>
                            </template>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-simple-table class="">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left col-label"></th>
                                                        <th class="text-left col-rating"></th>
                                                        <th class="text-center col-checkbox">
                                                            <span class="text-caption">Sin servicio/No utilizó</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in rating_services_lodging" :key="index" class="border">
                                                        <td class=" col-label">
                                                            <span class="text-caption">{{ item.label }}</span>
                                                        </td>
                                                        <td class=" col-rating">
                                                            <v-rating
                                                                v-model="item.value"
                                                                active-color="blue"
                                                                color="orange-lighten-1"
                                                            ></v-rating>
                                                        </td>
                                                        <td class="text-center col-checkbox">
                                                            <v-checkbox
                                                                v-model="item.value"
                                                                color="primary"
                                                                hide-details
                                                            ></v-checkbox>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item
                  :value="6"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Consumio Alimentos ofertados por el establecimiento de
                          alojamiento?</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-radio-group v-model="shopingFood">
                          <v-radio color="primary" label="Sí" value="si"></v-radio>
                          <v-radio color="primary" label="No" value="no"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item
                  :value="7"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción de las siguientes
                          caracteristicas respecto a la comida adquirida, teniendo en
                          cuenta que 5 <v-icon color="yellow">mdi-star</v-icon> es "Muy
                          satisfecho" y 1 "Muy insatisfecho"?
                        </span>

                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in rating_shop_food"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left"></th>
                            <th class="text-left"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in rating_shop_food"
                            :key="index"
                            class="border"
                          >
                            <td>
                              <span class="text-caption">{{ item.label }}</span>
                            </td>
                            <td>
                              <v-rating
                                v-model="item.value"
                                active-color="blue"
                                color="orange-lighten-1"
                              ></v-rating>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>

                <v-window-item
                  :value="8"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción respecto a la condición de los
                          edificios e instalaciones del alojamiento, teniendo en cuenta
                          que 5 <v-icon color="yellow">mdi-star</v-icon> es "Muy buen
                          estado" y 1 "Muy mal estado"?
                        </span>
                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in rating_status_location"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-row
                        v-for="(item, index) in rating_status_location"
                        :key="index"
                        class="d-flex align-center py-1"
                      >
                        <v-col md="6" cols="6">
                          <span class="text-caption">{{ item.label }}</span>
                        </v-col>
                        <v-col md="6" cols="6">
                          <v-rating
                            v-model="item.value"
                            active-color="blue"
                            color="orange-lighten-1"
                          ></v-rating>
                        </v-col>
                      </v-row> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <v-window-item
                  :value="9"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de percepción de la relación calidad precio
                          del alojamiento, teniendo en cuenta que 5
                          <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                          "Muy insatisfecho"?</span
                        >

                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in rating_calidad_precio"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-row
                        v-for="(item, index) in rating_calidad_precio"
                        :key="index"
                        class="d-flex align-center py-1"
                      >
                        <v-col md="6" cols="6">
                          <span class="text-caption">{{ item.label }}</span>
                        </v-col>
                        <v-col md="6" cols="6">
                          <v-rating
                            v-model="item.value"
                            active-color="blue"
                            color="orange-lighten-1"
                          ></v-rating>
                        </v-col>
                      </v-row> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <v-window-item
                  :value="10"
                >
                  <v-card-text class="text-body-2">
                    <v-row>
                      <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de satisfacción general del alojamiento,
                          teniendo en cuenta que 5
                          <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y 1
                          "Muy insatisfecho"?
                        </span>

                        <v-list style="width: 100%">
                          <v-list-item
                            v-for="(item, index) in rating_satisfaccion_general"
                            :key="index"
                            class="px-0"
                          >
                            <v-list-item-title class="text-wrap">
                              <span class="">{{ item.label }}</span>
                            </v-list-item-title>

                            <v-list-item-title>
                              <v-rating color="orange-lighten-1" v-model="item.value">
                                <template v-slot:item="props">
                                  <v-icon
                                    large
                                    :color="props.isFilled ? 'orange-lighten-1' : 'grey'"
                                  >
                                    {{ props.isFilled ? "mdi-star" : "mdi-star-outline" }}
                                  </v-icon>
                                </template>
                              </v-rating>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- <v-row
                        v-for="(item, index) in rating_satisfaccion_general"
                        :key="index"
                        class="d-flex align-center py-1"
                      >
                        <v-col md="6" cols="6">
                          <span class="text-caption">{{ item.label }}</span>
                        </v-col>
                        <v-col md="6" cols="6">
                          <v-rating
                            v-model="item.value"
                            active-color="blue"
                            color="orange-lighten-1"
                          ></v-rating>
                        </v-col>
                      </v-row> -->
                    </v-row>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="stepFormServiceOrder--"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="stepFormServiceOrder < stepTitles.length"
                  color="primary"
                  variant="text"
                  @click="stepFormServiceOrder++"
                >
                  Siguiente
                </v-btn>
                <v-btn class="text-capitalize" v-else color="primary" variant="flat">
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>

    <UserExperience></UserExperience>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation1 from "./includes/LeftPanelAnimation1.vue";
import NavBarAnimation1 from "./includes/NavBarAnimation1.vue";
import UserExperience from "./includes/UserExperience.vue";

export default {
  name: "FormPerceptionTouristLodging",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation1,
    NavBarAnimation1,
    UserExperience,
  },

  setup() {
    const { mdAndDown, smAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
      issmAndDown: smAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      ratings_zone_comun: [
        { label: "Higiene y Limpieza", value: 0 },
        { label: "Estado de muebles", value: 0 },
        { label: "Conectividad al WiFi", value: 0 },
      ],
      ratings_services: [
        { label: "Prontitud", value: 0 },
        { label: "Trato del personal", value: 0 },
        { label: "Servcicio telefonico", value: 0 },
        { label: "Información brindada", value: 0 },
      ],
      //Rating de habitación

      rating_room: [
        { label: "Comodidad de la habitación", value: 0 },
        { label: "Estado de muebles", value: 0 },
        { label: "Tiempo de atención", value: 0 },
        { label: "Higiene y Limpieza", value: 0 },
        { label: "Relación calidad precio", value: 0 },
        { label: "Conectividad al WiFi", value: 0 },
      ],

      rating_services_lodging: [
        { label: "Actividades de bienestar (SPA, masajes, yoga)", value: 0 },
        { label: "Senderismo, aviturismo, observación de flora y fauna", value: 0 },
        { label: "Otras actividades en áreas naturales", value: 0 },
        { label: "Zonas húmedas", value: 0 },
        { label: "Zonas deportivas", value: 0 },
        { label: "Lavandería (enfocado al cliente)", value: 0 },
        { label: "Eventos", value: 0 },
        { label: "Transporte", value: 0 },
        { label: "Guianza", value: 0 },
        { label: "Parqueadero", value: 0 },
        { label: "WIFI", value: 0 },
        { label: "Otros", value: 0 },
      ],
      rating_shop_food: [
        { label: "Variedad del Menú", value: 0 },
        { label: "Menú consumido", value: 0 },
        { label: "Tiempo de atención", value: 0 },
        { label: "Aseo e higiene de las instalaciones gastronómicas", value: 0 },
        { label: "Relación calidad precio", value: 0 },
        { label: "Trato del personal del restaurante", value: 0 },
      ],

      rating_status_location: [{ label: "Edificios e instalaciones físicas", value: 0 }],

      rating_calidad_precio: [{ label: "Calidad precio del  alojamiento", value: 0 }],
      rating_satisfaccion_general: [
        { label: "Satisfacción general del alojamiento", value: 0 },
      ],
      guianzaTuristica: 0,
      shopingFood: 0,

      typesDocs: [
        "Cédula de ciudadanía",
        "Cédula de extranjería",
        "Pasaporte",
        "Permiso de permanencia",
        "Otro",
      ],

      placesLodging: ["Lugar 1", "Lugar 2", "Lugar 3"],

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      itemsPracticesSustainability: [
        "Gestión sobre residuos sólidos (no uso de plástico, reciclaje, compostaje, etc.) ",
        "Gestión sobre ahorro y uso eficiente de agua (sanitarios ahorradores, grifos de cierre automático, aprovechamiento de aguas lluvias, etc.) ",
        "Gestión sobre ahorro y uso eficiente de energía (paneles solares, bombillos ahorradores, aprovechamiento de luz natural, etc.) ",
        "Gestión sobre manejo de aguas residuales y vertimientos (uso de jabones biodegradables, baños secos, pozo séptico, etc.) ",
        "Gestión sobre manejo y cuidado de la biodiversidad (restauración y siembra de árboles, no se interfiere con el hábitat de la especie observada, etc.) ",
        "Gestión sobre manejo de gases efecto invernadero (GEI) (cálculo de huella de carbono, oferta de productos locales, menú vegetariano, etc.)",
        "Campañas de sensibilización y/o capacitación sobre el cuidado del medio ambiente",
        "Certificaciones, aval, o reconocimiento de buenas prácticas ambientales alcanzadas",
        "Ninguno",
      ],
      itemsPracticesSustainabilitySocial: [
        "Involucramiento de las comunidades turísticas en su experiencia turística",
        "No explotación infantil",
        "Pago de tarifas justas por los servicios o productos recibidos",
        "Uso de productos locales",
        "Respeto por la cultura y/o costumbres locales",
      ],
      selectPractAmbiental: [],
      selectPractSocial: [],
      drawerStep: true,
      stepFormServiceOrder: 1,
      marketplace: "",
      stepTitles: [
        "Información del Alojamiento",
        "Nivel de percepción de Zonas Comunes",
        "Nivel de percepción de Servicio de recepción y servicio a la habitación",
        "Nivel de percepción de Habitación",
        "Nivel de percepción de servicios del alojamiento",
        "Consumo de alimentos",
        "Nivel de percepción de alimentos",
        "Nivel de percepción de condiciones de las instalaciones",
        "Nivel de percepción de servicios de la relación calidad precio",
        "Nivel de satisfacción general",
      ],

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },
  },
  methods: {
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    async nextStep() {
      this.stepFormServiceOrder++;
    },

    togglePractAmbiental(activity) {
      const index = this.selectPractAmbiental.indexOf(activity);
      if (index === -1) {
        this.selectPractAmbiental.push(activity);
      } else {
        this.selectPractAmbiental.splice(index, 1);
      }
    },

    togglePractSocial(activity) {
      const index = this.selectPractSocial.indexOf(activity);
      if (index === -1) {
        this.selectPractSocial.push(activity);
      } else {
        this.selectPractSocial.splice(index, 1);
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },
  },

  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    currentTitle() {
      return this.stepTitles[this.stepFormServiceOrder - 1];
    },
    randomAnimationClass() {
      // Generar una clase de animación aleatoria
      return `bounce-${Math.floor(Math.random() * 2) + 1}`;
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

.v-row {
  margin: 0;
}
</style>
