
// mixin.js
export default {
  data() {
    return {
      validations: {
        routeName: v => /^[a-zA-Z0-9-]+$/.test(v) || 'El nombre de la ruta solo permite letras, números y guiones',
        required: v => !!v || 'Este campo es requerido',
        minLength: minLength => v => (v && v.length >= minLength) || `Este campo debe tener al menos ${minLength} caracteres`,
        maxLength: maxLength => v => (v && v.length <= maxLength) || `Este campo no debe exceder los ${maxLength} caracteres`,
        emailFormat: v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Por favor, ingresa una dirección de correo electrónico válida',
        numeric: v => /^[0-9]+$/.test(v) || 'Este campo solo permite caracteres numéricos',
        alphaNumeric: v => /^[a-zA-Z0-9]+$/.test(v) || 'Este campo solo permite caracteres alfanuméricos',
        noSpecialCharacters: v => /^[a-zA-Z0-9\s]*$/.test(v) || 'Este campo no permite caracteres especiales',
        phoneNumber: v => /^[0-9]{7,10}$/.test(v) || 'Por favor, ingresa un número de teléfono válido',
        date: v => /^\d{4}-\d{2}-\d{2}$/.test(v) || 'Por favor, ingresa una fecha válida (YYYY-MM-DD)',
        timeFormat: v => this.validateTimeFormat(v) || 'El formato debe ser HH:MM (24 horas)',
        urlFormat: v => /^(ftp|http|https):\/\/[^ "]+$/.test(v) || 'Por favor, ingresa una URL válida',
        zipCode: v => /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || 'Por favor, ingresa un código postal válido',
        positiveNumber: v => parseFloat(v) > 0 || 'Por favor, ingresa un número positivo',
        negativeNumber: v => parseFloat(v) < 0 || 'Por favor, ingresa un número negativo',
        wholeNumber: v => /^\d+$/.test(v) || 'Por favor, ingresa un número entero',
        decimalNumber: v => /^\d*\.?\d*$/.test(v) || 'Por favor, ingresa un número decimal',
        alpha: v => /^[a-zA-Z]+$/.test(v) || 'Este campo solo permite letras',
        alphaWithSpaces: v => /^[a-zA-Z\s]*$/.test(v) || 'Este campo solo permite letras y espacios',
        customFormat: (regex, message) => v => regex.test(v) || message,
        confirmPassword: password => (confirmation) => {
          return confirmation === password || 'Las contraseñas no coinciden';
        },
        securePassword: v => {
          if (!v) return 'La contraseña es requerida';
          if (v.length < 8) return 'La contraseña debe tener al menos 8 caracteres';
          if (!/[a-z]/.test(v) || !/[A-Z]/.test(v) || !/\d/.test(v) || !/[^a-zA-Z\d]/.test(v)) {
            return 'La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial';
          }
          return true;
        },
        checkboxRequired: v => Array.isArray(v) && v.length > 0 || 'Debes seleccionar al menos una opción'



      }
    }
  },
  methods: {

    getAnimationClasses() {
      return {
        'animate__animated': true,
        'animate__fadeInDownBig': true,
      };
    },
    getAnimationStyle(index, time = 400) {
      const delay = index * time; // 200ms delay between each element
      return {
        'animation-delay': `${delay}ms`,
      };
    },

    validateTimeFormat(value) {
      if (!value) return true; // Permitir valores vacíos si la validación de required lo maneja
      const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
      return timePattern.test(value);
    },
    generatePassword() {
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const numbers = '0123456789';
      const symbols = '!@#$%^&*()_-+=<>?';

      const allCharacters = uppercaseLetters + lowercaseLetters + numbers + symbols;

      const getRandomChar = (str) => str.charAt(Math.floor(Math.random() * str.length));

      // Generar al menos 1 letra mayúscula, 1 letra minúscula, 1 número y 1 símbolo
      const uppercaseChar = getRandomChar(uppercaseLetters);
      const lowercaseChar = getRandomChar(lowercaseLetters);
      const numberChar = getRandomChar(numbers);
      const symbolChar = getRandomChar(symbols);

      // Generar el resto de la contraseña
      let restOfPassword = '';
      for (let i = 0; i < 8; i++) {
        restOfPassword += getRandomChar(allCharacters);
      }

      // Combinar los caracteres generados
      const password = uppercaseChar + lowercaseChar + numberChar + symbolChar + restOfPassword;


      return password;
    },
  },
  // etc.
}
