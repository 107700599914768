<template>

    <v-app-bar scroll-behavior="fully-hide" scroll-threshold="350" :color="color" elevation="1">

        <v-app-bar-title class="text-wrap text-h6 font-weight-bold">

            <div class="area" >
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div >

            {{ title }}

        </v-app-bar-title>

        <template v-slot:append>
            <v-btn icon size="small">
                <v-icon>mdi-information</v-icon>
            </v-btn>
        </template>
    </v-app-bar>

</template>

<script>

export default {
    name: 'NavBarAnimation2',
    props: {
        title: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'secondary'
        }
    },

}
</script>